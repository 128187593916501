import React, { useState } from "react";
import { IoNotifications } from "react-icons/io5";
import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosAuth from "../../../utils/axios/useAxiosAuth";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";


const LeaveRequest = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [title, setTitle] = useState("");
  const [reason, setReason] = useState("");
  const[startDate, setStartDate] = useState("");
  const[endDate, setEndDate] = useState("");

  const axios = useAxiosAuth()
  const teacher = useAuthUser()

  const sendLeaveRequest =  (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Create a FormData object
    const formData = new FormData();
    formData.append("title", title);
    formData.append("reason", reason);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("teacher", teacher.id)


    axios
        .post("api/classrooms/leaves/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            // Reset form fields
            setTitle("");
            setReason("");
            setStartDate("00/00/0000");
            setEndDate("00/00/0000");

            toast.success("Leave Requested successfully!");
        })
        .catch((error) => {
            console.log(error);
            toast.error("Leave Request Faield");
        });

  };

  return (
    <>
      <div className=" max-w-screen-2xl mx-auto px-10 py-5 font-poppins min-h-screen bg-[#FBFBFF]">
        <div className="flex justify-between items-center mb-12">
          <div>
            <h1 className="text-2xl font-semibold text-[#5964DB]">
              Teachers Content
            </h1>
          </div>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <input
                type="text"
                className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-[#928DD3] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4D45B5] focus:border-transparent"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-[#928DD3]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-4.35-4.35M17.65 10.65a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
            <div className="text-[#928DD3]">
              <IoNotifications className="w-7 h-7 mt-2 duration-200 hover:text-[#4D45B5] hover:scale-110" />
            </div>
          </div>
        </div>

        <div className=" w-[90%] grid grid-cols-3 gap-8 pb-8 ">
          <div className="w- h-[16vh] bg-[#EBEAFF] rounded-lg shadow-lg border-[0.8px]  duration-200 ease-in-out hover:-translate-y-1">
            <div className="p-4">
              <h1 className="text-gray-700 text-3xl">5</h1>
              <div className="flex">
                <div className="text-[12px]">
                  <br />
                  Days of Leave available
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[16vh] bg-[#EBEAFF] rounded-lg shadow-lg border-[0.8px]  duration-200 ease-in-out hover:-translate-y-1">
            <div className="p-4">
              <h1 className="text-gray-700 text-3xl">10</h1>
              <div className="flex">
                <div className="text-[12px]">
                  <br />
                  Days of Leave available for the month
                </div>
              </div>
            </div>
          </div>
          <div className="w- h-[16vh] bg-[#EBEAFF] rounded-lg shadow-lg border-[0.8px]  duration-200 ease-in-out hover:-translate-y-1">
            <div className="p-4">
              <h1 className="text-gray-700 text-3xl">100</h1>
              <div className="flex">
                <div className="text-[12px]">
                  <br />
                  Total allocated hoidays
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-[90%] px-4 py-10 bg-white rounded-lg shadow-lg">
          <form onSubmit={sendLeaveRequest} className="grid grid-cols-[20%_80%] gap-4 pl-5">
            <label className="first-letter:text-base text-left col-span-1 font-medium text-gray-700 mt-2 ">
              Title
            </label>
            <input
              type="text"
              className=" w-[95%] pl-5 border border-gray-300 rounded-md "
              placeholder="Leave title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <label className=" w-[95%] text-base text-left col-span-1 font-medium text-gray-700 mt-2 ">
              Leave Reason
            </label>
            <input
              type="text"
              className="w-[95%] pl-5 border border-gray-300 rounded-md "
              placeholder="Leave Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />


            <label className="text-base text-left col-span-1 font-medium text-gray-700 mt-2 ">
              Start Date
            </label>
            <input
              type="date"
              className="w-[30%] pl-5 border border-gray-300 rounded-md text-gray-700  "

              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />

            <label className="text-base text-left col-span-1 font-medium text-gray-700 mt-2 ">
              End Date 
            </label>
            <input
              type="date"
              className="w-[30%] pl-5 border border-gray-300 rounded-md text-gray-700  "
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />

            <div className="text-center col-span-2 flex justify-end space-x-5 mr-10">
              <button type="submit"
              className="px-4 py-2 text-sm bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide">
                Request
              </button>
              <button className="px-4 py-2 text-[#B54545] w-[10rem] border-2 border-[#D46067] font-medium tracking-wide rounded-lg hover:bg-[#D46067] hover:text-white hover:ease-in-out hover:transition duration-[550ms]">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LeaveRequest;

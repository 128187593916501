import BaseLayout from "../../components/BaseLayout";
import useAxios from "../../../utils/axios/useAxios";
import { format } from "crypto-js";

const NoticeHistory = () => {

  const { data, error } = useAxios(`classrooms/announcements/`);
  const { data : users, error : userError } = useAxios(`users/users/`);



  // const notices = [
  //   {
  //     title: "Annual Company Meeting",
  //     issuer: "HR Department",
  //     noticeDate: "2024-08-24",
  //     noticeBody:
  //       "Please be informed that the annual company meeting will take place on August 25, 2024. Attendance is mandatory for all employees. Details regarding the meeting agenda will be shared closer to the date. Please be informed that the annual company meeting will take place on August 25, 2024. Attendance is mandatory for all employees. Details regarding the meeting agenda will be shared closer to the date. Please be informed that the annual company meeting will take place on August 25, 2024. Attendance is mandatory for all employees. Details regarding the meeting agenda will be shared closer to the date.",
  //   },
  //   {
  //     title: "System Maintenance Notification",
  //     issuer: "IT Support",
  //     noticeDate: "2024-08-15",
  //     noticeBody:
  //       "Scheduled system maintenance will occur on August 20, 2024, from 2:00 AM to 4:00 AM. During this time, all internal systems will be offline. Please save your work accordingly.",
  //   },
  //   {
  //     title: "New Policy Update",
  //     issuer: "Compliance Team",
  //     noticeDate: "2024-08-14",
  //     noticeBody:
  //       "We are updating our company policies to reflect recent changes in regulations. All employees are required to review the new policies by August 30, 2024. A detailed document will be sent to your email.",
  //   },
  // ];

  console.log("These are the users: ",users);

  const isPastDate = (dateString) => {
    const currentDate = new Date();
    const noticeDate = new Date(dateString);
    return noticeDate < currentDate;
  };

  const isTodayOrFutureDate = (dateString) => {
    const currentDate = new Date();
    const noticeDate = new Date(dateString);
    return noticeDate >= currentDate;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const getIssuerName = (issuerId) => {
    const issuer = users?.find((user) => user.id === issuerId);
    return issuer ? `${issuer.username}` : "Vidhyalaya";
  };

  return (
    <BaseLayout title={"Notice History"}>
      <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold ml-4">Notices</h2>
        </div>
        <hr className="my-4" />
        <div className="space-y-6 m-4">
          {data.map((notice, index) => (
            <div
              key={index}
              // className={`mb-4 py-10 px-8 space-y-6 border rounded-lg shadow-sm ${
              //   isPastDate(notice.noticeDate)
              //     ? "border-l-4 border-l-[#B54545]"
              //     : "border-l-4 border-l-[#4D45B5]"
              // }`}

              className="mb-4 py-10 px-8 space-y-6 border rounded-lg shadow-sm border-l-4 border-l-[#B54545]"
            >
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-bold opacity-85 text-[#B54545]">{notice.title}</h3>
                <p className=""><span className="font-semibold opacity-75">Date: </span><span className="font-medium opacity-[57%]">{formatDate(notice.announcement_date)}</span></p>
              </div>
              <p className=""><span className="font-semibold opacity-75">Notice By: </span><span className="opacity-[57%] font-medium">{getIssuerName(notice.created_by)}</span></p>
              <p className="opacity-[57%] leading-[28px] text-justify font-medium tracking-wide">
                {notice.description}
              </p>
              {/* <div className="mt-4 flex space-x-4">
                {isTodayOrFutureDate(notice.noticeDate) && (
                  <>
                    <button className="px-4 py-2 bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide">
                      Edit Notice
                    </button>
                    <button className="px-4 py-2 text-[#B54545] w-[10rem] border-2 border-[#B54545] font-medium tracking-wide rounded-lg hover:bg-[#B54545] hover:text-white hover:ease-in-out hover:transition duration-500">
                      Delete Notice
                    </button>
                  </>
                )}
                {isPastDate(notice.noticeDate) && (
                  <button className="px-4 py-2 bg-[#B54545] text-white rounded-lg w-[10rem] cursor-auto">
                    Notice Ended
                  </button>
                )}
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </BaseLayout>
  );
};

export default NoticeHistory;

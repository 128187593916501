// import axios from "axios";

// const axiosInstance = axios.create({
//   baseURL: "http://192.168.31.92:8001/api/",
//   headers: {
//     "Content-Type": "application/json",
//     // Authorization: "Basic " + window.btoa("super@gmail.com:super"),
//   },
// });

// export default axiosInstance;

import baseAxios from "axios";

export const BASE_URL = "https://api.ayushp.com.np";

const axiosInstance = baseAxios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstance

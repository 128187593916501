import React, { useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";

const CredentialsStep = ({ formData, handleChange }) => {
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <>
      <div className="mb-3">
        <label className="block text-base font-medium text-[#464646]">
          First Name
        </label>
        <input
          type="text"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="First Name"
          required
        />
      </div>
      <div className="mb-3">
        <label className="block text-base font-medium text-[#464646]">
          Middle Name
        </label>
        <input
          type="text"
          name="middle_name"
          value={formData.middle_name}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="Middle Name"
        />
      </div>
      <div className="mb-3">
        <label className="block text-base font-medium text-[#464646]">
          Last Name
        </label>
        <input
          type="text"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="Last Name"
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="date_of_birth" className="font-medium w-[28vh]">
          Date of Birth
        </label>
        <input
          id="date_of_birth"
          name="date_of_birth"
          type="date"
          className="w-full border border-gray-300 rounded-[4px] p-2"
          required
          value={formData.date_of_birth}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="principal_contact" className="font-medium w-[28vh]">
          Contact
        </label>
        <input
          id="principal_contact"
          name="principal_contact"
          type="text"
          className="w-full border border-gray-300 rounded-[4px] p-2"
          placeholder="+977 984134234"
          required
          value={formData.principal_contact}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="principal_email" className="font-medium w-[28vh]">
          Email
        </label>
        <input
          id="principal_email"
          name="principal_email"
          type="email"
          className="w-full border border-gray-300 rounded-[4px] p-2"
          placeholder="example@gmail.com"
          required
          value={formData.principal_email}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="principal_address" className="font-medium w-[28vh]">
          Address
        </label>
        <input
          id="principal_address"
          name="principal_address"
          type="text"
          className="w-full border border-gray-300 rounded-[4px] p-2"
          placeholder="Enter your address"
          required
          value={formData.principal_address}
          onChange={handleChange}
        />
      </div>
      {["password", "confirmPassword"].map((field) => (
        <div className="mb-3" key={field}>
          <label className="text-base text-left font-medium text-[#464646]">
            {field === "password" ? "Password" : "Confirm Password"}
          </label>
          <div className="relative w-full">
            <input
              type={passwordVisibility[field] ? "text" : "password"}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Enter your password"
              required
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility(field)}
              className="absolute inset-y-0 right-0 pr-4 flex items-center"
            >
              {passwordVisibility[field] ? <LuEyeOff /> : <LuEye />}
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default CredentialsStep;

import { IoMdClose } from "react-icons/io";

const OTP = ({ onClose }) => {


    return (
        <div className="w-[25rem] space-y-6 py-2 px-2 relative bg-white rounded-lg">
            <button 
                onClick={onClose} 
                className="absolute top-0 right-2 text-gray-600 hover:text-gray-900"
            >
                <IoMdClose size={24} />
            </button>

            <div className="text-center">
                <h1 className="font-semibold tracking-wide text-lg">Verification</h1>
            </div>

            <div className="space-y-6">
                <img className="mx-auto w-[35%] h-[35%]" src={require("../../../../assets/images/Principal/OTP.png")} alt="" />
                <p className="text-center w-[85%] mx-auto">Enter the verification code we just sent you on your email address.</p>
            </div>

            <div className="">
                <input className="mx-[1.1rem] w-[90%] border-2 h-[3.2rem]" type="password" />
            </div>

            <div className="text-center text-sm space-y-2 opacity-75 tracking-wide">
                <p>Your OTP code will be valid till 30sec.</p>
                <p>If you didn’t receive a code! <span className="text-[#F91B1B] cursor-pointer">Resend</span></p>
            </div>

            <div className="flex">
                <button className="bg-[#4D45B5] font-medium tracking-wide text-white w-[90%] py-2 mx-auto rounded-lg">Verify</button>
            </div>
        </div>
    );
};

export default OTP;

import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../utils/axios/axios';

const axios = axiosInstance();

const AssignTasks = () => {
    const [tasks, setTasks] = useState({ todo: [], doing: [], done: [] });
    const [newTask, setNewTask] = useState({ title: '', description: '' });
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility

    useEffect(() => {
        const fetchTasks = () => {
            axios.get('/v1/tasks/')
                .then(response => {
                    const tasksData = response.data.results;
                    const taskState = { todo: [], doing: [], done: [] };

                    tasksData.forEach(task => {
                        taskState[task.state].push(task);
                    });

                    setTasks(taskState);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };

        fetchTasks();

        const intervalId = setInterval(fetchTasks, 60000);

        return () => clearInterval(intervalId);
    }, []);

    const handleInputChange = (e) => {
        setNewTask({
            ...newTask,
            [e.target.name]: e.target.value
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        axios.post('/v1/tasks/', newTask)
            .then(response => {
                const createdTask = response.data;
                setTasks(prevTasks => ({
                    ...prevTasks,
                    [createdTask.state]: [...prevTasks[createdTask.state], createdTask]
                }));

                setNewTask({ title: '', description: '' });
                setIsModalOpen(false);
            })
            .catch(error => {
                console.error('Error creating task:', error);
            });
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Handle drag start
    const handleDragStart = (e, taskId, state) => {
        e.dataTransfer.setData('taskId', taskId);
        e.dataTransfer.setData('currentState', state);
    };

    // Handle drag over
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    // Handle drop
    const handleDrop = (e, newState) => {
        e.preventDefault();
        const taskId = e.dataTransfer.getData('taskId');
        const currentState = e.dataTransfer.getData('currentState');

        if (currentState === newState) return;

        // Update the task state on the backend
        axios.put(`/v1/tasks/${taskId}/`, { state: newState })
            .then(() => {
                setTasks(prevTasks => {
                    const updatedTasks = { ...prevTasks };
                    const taskToMove = updatedTasks[currentState].find(task => task.id === parseInt(taskId));

                    // Remove the task from the current state
                    updatedTasks[currentState] = updatedTasks[currentState].filter(task => task.id !== parseInt(taskId));

                    // Add the task to the new state
                    updatedTasks[newState] = [...updatedTasks[newState], taskToMove];

                    return updatedTasks;
                });
            })
            .catch(error => {
                console.error('Error updating task state:', error);
            });
    };

    return (
        <div className="board p-4">
            <button 
                onClick={openModal} 
                className="mb-4 px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
            >
                Add Task
            </button>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                        <button 
                            onClick={closeModal} 
                            className="text-gray-500 hover:text-red-500 font-bold text-xl absolute top-2 right-4"
                        >
                            &times;
                        </button>
                        <h2 className="text-2xl font-bold mb-4">Create New Task</h2>
                        <form onSubmit={handleFormSubmit} className="space-y-4">
                            <input
                                type="text"
                                name="title"
                                placeholder="Task title"
                                value={newTask.title}
                                onChange={handleInputChange}
                                required
                                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <textarea
                                name="description"
                                placeholder="Task description"
                                value={newTask.description}
                                onChange={handleInputChange}
                                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <button 
                                type="submit" 
                                className="w-full bg-green-600 text-white font-semibold py-2 rounded-md hover:bg-green-700"
                            >
                                Create Task
                            </button>
                        </form>
                    </div>
                </div>
            )}

            <div className="grid grid-cols-3 gap-4">
                {['todo', 'doing', 'done'].map(state => (
                    <div
                        key={state}
                        className="swim-lane bg-gray-100 p-4 rounded-lg shadow-md"
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, state)}
                    >
                        <h3 className="heading text-lg font-bold mb-4">{state.toUpperCase()}</h3>
                        {tasks[state].map(task => (
                            <p 
                                key={task.id} 
                                className="task bg-white p-3 mb-3 rounded-lg shadow cursor-pointer" 
                                draggable="true" 
                                onDragStart={(e) => handleDragStart(e, task.id, state)}
                            >
                                {task.title}
                            </p>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AssignTasks;
import React, { useState } from "react";

const SalaryDetail = () => {
  return (
    <div className="small-container p-6 w-2xl font-poppins">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-[#827CCC]">Detailed View</h2>
      </div>
      <hr className="my-4"></hr>
      <div className="flex justify-between  ">
        <div>
          <div className="flex text-base mb-3">
            <h1 className="w-[12vw] text-left ">Name</h1>
            <p> John Doe</p>
          </div>
          <div className="flex justify-between">
            <div className="flex text-base">
              <h1 className="w-[12vw] text-left">Designation Level</h1>
              <p>Lorem</p>
            </div>
          </div>
        </div>
        <div>
          <br />
          <div className="flex text-base mt-2">
            <h1 className="w-[10vw] text-left">Working Days</h1>
            <p>230/365</p>
          </div>
        </div>
      </div>
      <hr className="my-4"></hr>

      <table class="w-full text-sm text-gray-700 border border-gray-300">
        <tbody>
          <tr>
            <td class="py-3 px-4 text-left border-r-2">Ssf 31% contribution</td>
            <td class="py-3 px-4 text-right">XXX.XX</td>
          </tr>
          <tr>
            <td class="py-3 px-4 text-left border-r-2">1% TDS Deduction</td>
            <td class="py-3 px-4 text-right">XXX</td>
          </tr>
          <tr>
            <td class="py-3 px-4 text-left border-r-2">10% TDS Deduction</td>
            <td class="py-3 px-4 text-right">XXX</td>
          </tr>
          <tr>
            <td class="py-3 px-4 text-left border-r-2">CIT (optional)</td>
            <td class="py-3 px-4 text-right">XXX</td>
          </tr>
          <tr>
            <td class="py-3 px-4 text-left border-r-2">
              20% TDS Deduction (optional)
            </td>
            <td class="py-3 px-4 text-right">XXX</td>
          </tr>
          <tr class="border-b-2">
            <td class="py-3 px-4 text-left border-r-2">Gross Payable</td>
            <td class="py-3 px-4 text-right">XXX.XX</td>
          </tr>
          <tr class="font-semibold">
            <td class="py-3 px-4 text-left border-r-2">Net Paid</td>
            <td class="py-3 px-4 text-right">XXX.XX</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default SalaryDetail;

import React from "react";
import { CountryDropdown } from "react-country-region-selector";

const CountryInfoStep = ({ formData, handleChange }) => {
  return (
    <>
      <div className="mb-2">
        <label className="block text-base font-medium text-[#464646]">
          Country
        </label>
        <CountryDropdown
          value={formData.country}
          onChange={(val) =>
            handleChange({ target: { name: "country", value: val } })
          }
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-2">
        <label className="block text-base font-medium text-[#464646]">
          Country Code
        </label>
        <input
          type="text"
          name="code"
          value={formData.code}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="Country Code"
        />
      </div>
      <div className="mb-2">
        <label className="block text-base font-medium text-[#464646]">
          State/Province
        </label>
        <input
          type="text"
          name="province"
          value={formData.province}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="State/Province"
        />
      </div>
      <div className="mb-2">
        <label className="block text-base font-medium text-[#464646]">
          City
        </label>
        <input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="City"
          required
        />
      </div>
      <div className="mb-2">
        <label className="block text-base font-medium text-[#464646]">
          Address
        </label>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="Address"
          required
        />
      </div>
      <div className="mb-2">
        <label className="block text-base font-medium text-[#464646]">
          Postal Code
        </label>
        <input
          type="text"
          name="postal_code"
          value={formData.postal_code}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="Postal Code"
          required
        />
      </div>
    </>
  );
};

export default CountryInfoStep;

import BaseLayout from "../../components/BaseLayout";
import { useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { Link } from "react-router-dom";


const SalaryBreakdown = () => {

    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${month}/${date}/${year}`;
    }

    const [currentDate] = useState(getDate());
    const [searchQuery, setSearchQuery] = useState("");


    const data = [
        {
            id: 1,
            name: "John Doe",
            designationlevel: "Senior Developer",
            workingDays: 22,
            basicSalary: 5000,
            ssfEmployer: 150,
            totalGrossPayable: 5150,
            cit: 200,
            ssfContribution: 31,
            itaxNext2Lakh: 1000,
            itaxNext3Lakh: 2000,
            netPayable: 2950,
        },
        {
            id: 2,
            name: "Jane Smith",
            designationlevel: "Project Manager",
            workingDays: 20,
            basicSalary: 6000,
            ssfEmployer: 180,
            totalGrossPayable: 6180,
            cit: 250,
            ssfContribution: 35,
            itaxNext2Lakh: 1200,
            itaxNext3Lakh: 2500,
            netPayable: 3480,
        },
        {
            id: 3,
            name: "Alice Johnson",
            designationlevel: "UX Designer",
            workingDays: 18,
            basicSalary: 4500,
            ssfEmployer: 130,
            totalGrossPayable: 4630,
            cit: 180,
            ssfContribution: 28,
            itaxNext2Lakh: 800,
            itaxNext3Lakh: 1500,
            netPayable: 2350,
        },
        {
            id: 4,
            name: "Bob Brown",
            designationlevel: "QA Engineer",
            workingDays: 24,
            basicSalary: 4700,
            ssfEmployer: 140,
            totalGrossPayable: 4840,
            cit: 210,
            ssfContribution: 30,
            itaxNext2Lakh: 900,
            itaxNext3Lakh: 1600,
            netPayable: 2230,
        },
        {
            id: 5,
            name: "Eve Davis",
            designationlevel: "Marketing Manager",
            workingDays: 21,
            basicSalary: 5500,
            ssfEmployer: 160,
            totalGrossPayable: 5660,
            cit: 230,
            ssfContribution: 33,
            itaxNext2Lakh: 1100,
            itaxNext3Lakh: 2200,
            netPayable: 3330,
        },
        {
            id: 6,
            name: "Charlie White",
            designationlevel: "Product Owner",
            workingDays: 19,
            basicSalary: 6200,
            ssfEmployer: 190,
            totalGrossPayable: 6390,
            cit: 270,
            ssfContribution: 37,
            itaxNext2Lakh: 1300,
            itaxNext3Lakh: 2500,
            netPayable: 3590,
        },
        {
            id: 7,
            name: "Diana Green",
            designationlevel: "Data Analyst",
            workingDays: 23,
            basicSalary: 4800,
            ssfEmployer: 170,
            totalGrossPayable: 4970,
            cit: 220,
            ssfContribution: 32,
            itaxNext2Lakh: 1000,
            itaxNext3Lakh: 1800,
            netPayable: 2950,
        },
        {
            id: 8,
            name: "Frank Harris",
            designationlevel: "Sales Executive",
            workingDays: 25,
            basicSalary: 5300,
            ssfEmployer: 200,
            totalGrossPayable: 5500,
            cit: 260,
            ssfContribution: 34,
            itaxNext2Lakh: 1200,
            itaxNext3Lakh: 2300,
            netPayable: 3000,
        }
    ];

    const filteredData = data.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <BaseLayout title={"Salary Breakdown"}>
            <div className="max-w-screen-2xl mx-auto px-10 py-5 font-poppins min-h-screen bg-[#FBFBFF] w-full text-neutral-900">
                <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
                    <div className="flex justify-between items-center">
                        <h2 className="text-xl font-semibold ml-4">John Doe</h2>
                        <div className="w-[50%] flex justify-center items-center space-x-8">
                            <div className="relative w-[50%]">
                                <input
                                className="py-[0.4rem] pl-4 pr-10 border rounded-full w-full border-[#827CCC] text-[#827CCC]"
                                type="text"
                                placeholder="Search by person"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <BiSearchAlt
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#827CCC]"
                                size={24}
                                />
                            </div>
                            <div className="">
                                <button className="px-8 py-2 bg-[#4D45B5] w-[12rem] text-white rounded-lg font-light hover:opacity-[88%] tracking-wide">Import CSV file</button>
                            </div>
                            </div>
                    </div>

                    <hr className="my-4" />

                    <div className="overflow-x-auto">
                    {filteredData.length === 0 ? (
                            <p className="text-center py-4 text-gray-500">No results found</p>
                        ) : (
                            <table className="min-w-full bg-white" style={{ tableLayout: 'fixed' }}>
                                <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px]">
                                    <tr className="">
                                        <th className="p-4 text-center rounded-tl-lg font-medium min-w-20">S.N</th>
                                        <th className="p-4 text-left font-medium min-w-72">Name</th>
                                        <th className="p-4 text-center font-medium min-w-52">Designation Level</th>
                                        <th className="p-4 text-center font-medium min-w-48">Working Days</th>
                                        <th className="p-4 text-center font-medium min-w-52">Basic Salary</th>
                                        <th className="p-4 text-center font-medium min-w-80">Employer's contribution on SSF</th>
                                        <th className="p-4 text-center font-medium min-w-64">Total Gross Payable</th>
                                        <th className="p-4 text-center font-medium min-w-32">CIT</th>
                                        <th className="p-4 text-center font-medium min-w-56">Ssf 31% contribution</th>
                                        <th className="p-4 text-center font-medium min-w-64">I tax deduction next 2 lakh</th>
                                        <th className="p-4 text-center font-medium min-w-64">I tax deduction next 3 lakh</th>
                                        <th className="p-4 text-center font-medium rounded-tr-lg min-w-56">Net Payable</th>
                                    </tr>
                                </thead>

                                <tbody className="text-[14px] border border-gray-300">
                                    {filteredData.map((item, index) => (
                                        <tr
                                            key={item.id}
                                            className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg"
                                        >
                                            <td className="p-4 border-b border-gray-300 text-center">
                                                {index + 1}
                                            </td>
                                            <td className="p-4 border-b border-gray-300 text-left">
                                                {item.name}
                                            </td>
                                            <td className="p-4 border-b border-gray-300 text-center ">
                                                {item.designationlevel}
                                            </td>
                                            <td className="p-4 border-b border-gray-300 text-center ">
                                                {item.workingDays} days
                                            </td>
                                            <td className="p-4 border-b border-gray-300 text-center">
                                                {item.basicSalary}
                                            </td>
                                            <td className="p-4 border-b border-gray-300 text-center">
                                                {item.ssfEmployer}
                                            </td>
                                            <td className="p-4 border-b border-gray-300 text-center">
                                                {item.totalGrossPayable}
                                            </td>
                                            <td className="p-4 border-b border-gray-300 text-center ">
                                                {item.cit}
                                            </td>
                                            <td className="p-4 border-b border-gray-300 text-center ">
                                                {item.ssfContribution}%
                                            </td>
                                            <td className="p-4 border-b border-gray-300 text-center">
                                                {item.itaxNext2Lakh}
                                            </td>
                                            <td className="p-4 border-b border-gray-300 text-center ">
                                                {item.itaxNext3Lakh}
                                            </td>
                                            <td className="p-4 border-b border-gray-300 text-center">
                                                {item.netPayable}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default SalaryBreakdown;

import React from "react";
import BaseLayout from "../../components/BaseLayout";
import AddNotice from "../../components/AddNotice";

const TeacherAddNotice = () => {
  return (
    <BaseLayout title={"Add Notice"}>
      <AddNotice />
    </BaseLayout>
  );
};

export default TeacherAddNotice;

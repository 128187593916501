import React from "react";
import featuresLanding from "../../../assets/images/features/featureslanding.svg";

const FeaturesLanding = () => {
  return (
    <div className="min-h-screen w-screen flex flex-col-reverse md:flex-row justify-between items-center  relative ">
      {/* Text Section */}
      <div className="md:w-[40%] text-center md:text-left px-10 py-8 md:py-0">
        <h1 className="text-[28px] md:text-[42px] font-bold font-poppins text-gray-800 leading-tight">
          Explore Our Features
        </h1>
        <p className="mt-4 md:mt-6 text-[16px] md:text-[18px] text-gray-600 leading-relaxed">
          Discover the powerful features that enhance your experience. From
          seamless integrations to robust performance, our tools are designed to
          help you achieve your goals effortlessly.
        </p>
        <div className="mt-8">
          <button className="w-[50vw] md:w-auto px-6 py-3 border-2 border-[#4D45B5] bg-[#4D45B5] text-white rounded-lg font-medium text-[14px] md:text-[16px] tracking-[1px] transition-all duration-300 hover:bg-transparent hover:text-[#4D45B5]">
            Learn More
          </button>
        </div>
      </div>

      {/* Image Section */}
      <div className="md:w-[60%]  h-[90%] px-10 md:px-0 flex justify-center items-center absolute right-0 top-0">
        <img
          src={featuresLanding}
          alt="features landing"
          className="max-w-full h-auto"
        />
      </div>
    </div>
  );
};

export default FeaturesLanding;

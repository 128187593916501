import React, { useState, useEffect } from "react";
import { IoNotifications } from "react-icons/io5";
import useAxios from "../../../utils/axios/useAxios";

const TimeTable = () => {
  const [selectedDay, setSelectedDay] = useState("Sunday");
  const [searchTerm, setSearchTerm] = useState("");
  const [subjectData, setSubjectData] = useState([]);

  const dayMap = {
    sun: "Sunday",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
  };

  // Reverse dayMap to map full names back to abbreviations
  const dayMapReverse = Object.fromEntries(
    Object.entries(dayMap).map(([key, value]) => [value, key])
  );

  const { data: routine = [], error: routineerror } =
    useAxios(`classrooms/routines`);
  const { data: subject = [], error: subjecterror } =
    useAxios(`classrooms/subjects`);
  const { data: teachers = [], error: teachererror } =
    useAxios(`classrooms/teachers`);
  const { data: classrooms = [], error: classroomerror } = useAxios(
    `classrooms/classrooms`
  );

  useEffect(() => {
    if (routine.length && subject.length) {
      // Create a map for quick lookup
      const subjectMap = new Map(
        subject.map((subject) => [subject.id, subject])
      );

      // Merge subject data with routine data
      const mergedData = routine.map((routine) => ({
        ...routine,
        subject: subjectMap.get(routine.subject) || {},
      }));

      setSubjectData(mergedData);
    }
  }, [routine, subject]);

  // Map unique days to full names
  const uniqueDays = [...new Set(routine.map((item) => dayMap[item.day]))];

  // Filter data based on selectedDay using dayMapReverse
  const filteredData = subjectData.filter(
    (item) => dayMap[item.day] === selectedDay
  );

  return (
    <div className="max-w-screen-2xl mx-auto px-10 py-5 font-poppins min-h-screen bg-[#FBFBFF]">
      <div className="flex justify-between items-center mb-12">
        <div>
          <h1 className="text-2xl font-semibold text-[#5964DB]">Time Table</h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-[#928DD3] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4D45B5] focus:border-transparent"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-[#928DD3]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35M17.65 10.65a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
          <div className="text-[#928DD3]">
            <IoNotifications className="w-7 h-7 mt-2 duration-200 hover:text-[#4D45B5] hover:scale-110" />
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold">{selectedDay}</h2>
          <div className="text-[14px]">
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none focus:ring-[#4D45B5] focus:border-transparent"
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value)}
            >
              {Object.values(dayMap).map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
          </div>
        </div>

        <hr className="my-4" />

        <div className="container mx-auto px-4">
          <table className="min-w-full bg-white ">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px] ">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">
                  S.N
                </th>
                <th className="p-4  text-left  font-light">Subject</th>
                <th className="p-4  text-center  font-light">Teacher</th>
                <th className="p-4  text-center  font-light">Time</th>
                <th className="p-4 text-center  font-light rounded-tr-lg">
                  Room
                </th>
              </tr>
            </thead>

            <tbody className="text-[14px] border  border-gray-300">
              {filteredData.map((item, index) => {
                const teacher = teachers.find(
                  (teacher) => teacher.id === item.teacher
                );
                const classroom = classrooms.find(
                  (classroom) => classroom.id === item.classroom
                );

                return (
                  <tr
                    key={item.id}
                    className="hover:bg-[#F5F7FF] divide-x divide-gray-300  rounded-b-lg"
                  >
                    <td className="p-4 border-b border-gray-300 text-center">
                      {index + 1}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-left">
                      {item.subject.name || "N/A"}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {teacher
                        ? `${teacher.first_name} ${teacher.last_name}`
                        : "N/A"}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {item.start_time.slice(0, 5)} -{" "}
                      {item.end_time.slice(0, 5)}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {classroom ? classroom.name : "N/A"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TimeTable;

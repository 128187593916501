import React from "react";
import AttendanceChart from "../../components/Attendance/AttendanceChart";
import AttendanceList from "../../components/Attendance/AttendanceList";
import BaseLayout from "../../components/BaseLayout";

const Attendance = () => {
  return (
    <BaseLayout title={"Attendance"}>
      <div className="container  space-y-4 mx-auto px-4 py-10 bg-white rounded-lg shadow-lg ">
        <AttendanceChart />
      </div>
      <div className="my-10">
        <AttendanceList />
      </div>
    </BaseLayout>
  );
};

export default Attendance;

import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Select from "react-select";

import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

// temp roles
const roleOptions = [
  { value: "ALL", label: "All" },
  { value: "PRINCIPAL", label: "Principal" },
  { value: "TEACHER", label: "Teacher" },
  { value: "HR", label: "HR" },
  { value: "ACCOUNTANT", label: "Accountant" },
  { value: "STUDENT", label: "Student" },
];

// The page is not responsive to screen size changes

// Type of notice (organization specific, role specific,..) has not been specified

const AddNotice = () => {
  const user = useAuthUser();
  const axios = useAxiosAuth();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [updated_at, setUpdated_At] = useState("");
  const [announcement_date, setAnnouncement_Date] = useState("");
  const [expiration_date, setExpiration_Date] = useState("");
  const [role, setRole] = useState([]);

  const submitNotice = (event) => {
    event.preventDefault();

    // Create a FormData object
    const formData = new FormData();

    // Append each field to the FormData object
    formData.append("title", title);
    formData.append("description", description);
    formData.append("announcement_date", announcement_date);
    formData.append("expiration_date", expiration_date);
    formData.append("role", role.join(",")); // Join roles into a comma-separated string

    // Log FormData content (for debugging)
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    axios
      .post("api/classrooms/announcements/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Reset form fields
        setTitle("");
        setDescription("");
        setAnnouncement_Date("00/00/0000");
        setExpiration_Date("00/00/0000");
        setRole([]);

        toast.success("Notice uploaded successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Notice upload request has failed!");
      });
  };

  const resetForm = (event) => {
    event.preventDefault();
    setTitle("");
    setDescription("");
    setAnnouncement_Date("00/00/0000");
    setExpiration_Date("00/00/0000");
    setRole([]);
  };

  return (
    <form
      className="w-[80%] px-4 py-10 bg-white rounded-lg shadow-lg space-y-10"
      action=""
      onSubmit={submitNotice}
    >
      <div className="flex px-4 items-center">
        <label className="w-[25%]" htmlFor="noticeTitle">
          Notice Title
        </label>
        <input
          className="w-[75%] rounded-md border-opacity-[57%] px-4 py-2"
          type="text"
          id="title"
          placeholder="Notice Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>

      <div className="flex px-4">
        <label className="w-[25%]" htmlFor="description">
          Notice Description
        </label>
        <textarea
          className="w-[75%] min-h-[8rem] rounded-md border-opacity-[57%] px-4 py-2"
          type="text"
          id="description"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </div>

      <div className="grid grid-cols-4 mx-4 gap-x-0 items-center">
        <label className="text-nowrap" htmlFor="announcementDate">
          Announcement Date
        </label>
        <input
          className="w-full rounded-md border-opacity-[57%] px-4 py-2"
          type="date"
          id="announcementDate"
          placeholder="announcementDate"
          value={announcement_date}
          onChange={(e) => setAnnouncement_Date(e.target.value)}
          required
        />
        <label className="text-nowrap w-[70%] ml-12" htmlFor="expirationDate">
          Expiration Date
        </label>
        <input
          className="w-full rounded-md border-opacity-[57%] px-4 py-2"
          type="date"
          id="expirationDate"
          placeholder="expirationDate"
          value={expiration_date}
          onChange={(e) => setExpiration_Date(e.target.value)}
          required
        />
      </div>

      <div className="flex px-4 items-center">
        <label className="w-[25%]" htmlFor="NoticeTo">
          Notice To
        </label>

        <Select
          isMulti
          inputId="NoticeTo"
          options={roleOptions}
          className="rounded-md py-2 w-[75%]"
          styles={{
            control: (base, state) => ({
              ...base,
              boxShadow: "none",
              border: "1px solid",
            }),
          }}
          value={roleOptions.filter((option) => role.includes(option.value))}
          onChange={(selectedOptions) =>
            setRole(selectedOptions.map((option) => option.value))
          }
          required
        />
      </div>

      <div className="text-center col-span-4 flex justify-end text-sm space-x-4 mr-3">
        <button
          type="Submit"
          className="px-4 py-2 bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
          onClick={submitNotice}
        >
          Post
        </button>
        <button
          className="px-4 py-2 text-[#B54545] w-[10rem] border-2 border-[#D46067] font-medium tracking-wide rounded-lg hover:bg-[#D46067] hover:text-white hover:ease-in-out hover:transition duration-[550ms]"
          onClick={resetForm}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddNotice;

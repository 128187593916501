import React from "react";
import { IoNotifications } from "react-icons/io5";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";


  

const Layout = ({ children }) => {

  const user = useAuthUser();

  const currentDate = new Date();
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(currentDate);
  return (
    <div className="max-w-screen-2xl mx-auto px-10 py-5 font-poppins bg-[#FBFBFF] min-h-screen">
      <div className="flex justify-between items-center pb-8">
        <div>
          <h1 className="text-2xl font-semibold">Welcome back, {user.profile.first_name} {user.profile.middle_name} {user.profile.last_name}</h1>
          <span className="text-gray-600">{formattedDate}</span>
        </div>
        <div className=" flex justify-between w-[18vw]">
          <div className="flex items-center justify-center w-[85%]">
            <div className="relative w-full max-w-md">
              <input
                type="text"
                className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-[#928DD3] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4D45B5] focus:border-transparent"
                placeholder="Search..."
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-[#928DD3]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-4.35-4.35M17.65 10.65a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="text-[#928DD3] ">
            <IoNotifications className="w-7 h-7 mt-2 duration-200 hover:text-[#4D45B5] hover:scale-110" />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Layout;

import React, { useState } from "react";
import { IoNotifications } from "react-icons/io5";
import ResultChart from "../../components/StudentResult/ResultChart";
import ResultTable from "../../components/StudentResult/ResultTable";
import StudentDetailCard from "../../components/StudentResult/StudentDetailCard";
import BaseLayout from "../../components/BaseLayout";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const Result = () => {
  const user = useAuthUser();
  console.log(user);
  const [searchTerm, setSearchTerm] = useState("");
  const [year, setYear] = useState("2027");
  const [examType, setExamType] = useState("Term 1");

  // const results = [
  //   {
  //     year: "2027",
  //     examType: "Term 1",
  //     subject: "Math",
  //     creditHour: 4,
  //     practical: "A+",
  //     theory: "A+",
  //     percentage: 95,
  //     finalGrade: "A+",
  //   },
  //   {
  //     year: "2027",
  //     examType: "Term 1",
  //     subject: "Science",
  //     creditHour: 4,
  //     practical: "B",
  //     theory: "A",
  //     percentage: 88,
  //     finalGrade: "A",
  //   },
  //   {
  //     year: "2027",
  //     examType: "Term 1",
  //     subject: "English",
  //     creditHour: 3,
  //     practical: "B+",
  //     theory: "B+",
  //     percentage: 82,
  //     finalGrade: "B+",
  //   },
  //   {
  //     year: "2027",
  //     examType: "Term 2",
  //     subject: "Math",
  //     creditHour: 4,
  //     practical: "A",
  //     theory: "A",
  //     percentage: 90,
  //     finalGrade: "A",
  //   },
  //   {
  //     year: "2026",
  //     examType: "Term 1",
  //     subject: "Math",
  //     creditHour: 4,
  //     practical: "A",
  //     theory: "A-",
  //     percentage: 87,
  //     finalGrade: "A-",
  //   },
  //   {
  //     year: "2026",
  //     examType: "Term 2",
  //     subject: "Science",
  //     creditHour: 4,
  //     practical: "B+",
  //     theory: "B",
  //     percentage: 78,
  //     finalGrade: "B",
  //   },
  //   {
  //     year: "2025",
  //     examType: "Unit Test",
  //     subject: "English",
  //     creditHour: 3,
  //     practical: "C",
  //     theory: "B",
  //     percentage: 70,
  //     finalGrade: "B",
  //   },
  //   {
  //     year: "2024",
  //     examType: "Unit Test",
  //     subject: "Math",
  //     creditHour: 4,
  //     practical: "A",
  //     theory: "B+",
  //     percentage: 85,
  //     finalGrade: "A",
  //   },
  //   {
  //     year: "2024",
  //     examType: "Term 1",
  //     subject: "Science",
  //     creditHour: 4,
  //     practical: "B+",
  //     theory: "A-",
  //     percentage: 80,
  //     finalGrade: "A-",
  //   },
  //   {
  //     year: "2023",
  //     examType: "Term 2",
  //     subject: "Math",
  //     creditHour: 4,
  //     practical: "A-",
  //     theory: "B",
  //     percentage: 83,
  //     finalGrade: "A-",
  //   },
  //   {
  //     year: "2022",
  //     examType: "Term 1",
  //     subject: "English",
  //     creditHour: 3,
  //     practical: "B",
  //     theory: "A",
  //     percentage: 75,
  //     finalGrade: "A",
  //   },
  //   {
  //     year: "2021",
  //     examType: "Term 2",
  //     subject: "Science",
  //     creditHour: 4,
  //     practical: "A+",
  //     theory: "A+",
  //     percentage: 95,
  //     finalGrade: "A+",
  //   },
  // ];

  // Filter results based on year and examType
  // const filteredResults = results.filter(
  //   (result) => result.year === year && result.examType === examType
  // );

  // // Separate into practical and theoretical results
  // const practicalResults = filteredResults.map((result) => ({
  //   subject: result.subject,
  //   creditHour: result.creditHour,
  //   score: result.practical,
  //   percentage: result.percentage,
  //   finalGrade: result.finalGrade,
  // }));

  // const theoryResults = filteredResults.map((result) => ({
  //   subject: result.subject,
  //   creditHour: result.creditHour,
  //   score: result.theory,
  //   percentage: result.percentage,
  //   finalGrade: result.finalGrade,
  // }));

  // const downloadResults = () => {
  //   const csvContent = [
  //     [
  //       "S.N",
  //       "Subjects",
  //       "Credit Hour",
  //       "Practical/Theory",
  //       "Score",
  //       "Percentage",
  //       "Final Grade",
  //     ],
  //     ...filteredResults.map((row, index) => [
  //       index + 1,
  //       row.subject,
  //       row.creditHour,
  //       "Practical",
  //       row.practical,
  //       `${row.percentage}%`,
  //       row.finalGrade,
  //     ]),
  //     ...filteredResults.map((row, index) => [
  //       index + 1,
  //       row.subject,
  //       row.creditHour,
  //       "Theory",
  //       row.theory,
  //       `${row.percentage}%`,
  //       row.finalGrade,
  //     ]),
  //   ]
  //     .map((e) => e.join(","))
  //     .join("\n");

  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.setAttribute("href", url);
  //   link.setAttribute("download", "results.csv");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  return (
    <BaseLayout title={"Exam Result"}>
      <div className="grid grid-cols-[55%_43%] gap-4 py-10">
        <div className="container mx-auto px-2 py-4 bg-white rounded-lg shadow-lg">
          <ResultChart />
        </div>
        <div className="container mx-auto px-2 py-4 bg-white rounded-lg shadow-lg">
          <StudentDetailCard student={user} />
        </div>
      </div>

      {/* <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold">Result</h2>
          <div className="space-x-4 text-[14px]">
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="2027">2027</option>
              <option value="2026">2026</option>
              <option value="2025">2025</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
            </select>
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={examType}
              onChange={(e) => setExamType(e.target.value)}
            >
              <option value="Term 1">Term 1</option>
              <option value="Term 2">Term 2</option>
              <option value="Unit Test">Unit Test</option>
            </select>
            <button
              className="px-4 py-2 bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
              // onClick={downloadResults}
            >
              Download
            </button>
          </div>
        </div>
        <ResultTable user={user} />
      </div> */}
      <ResultTable user={user} />
    </BaseLayout>
  );
};

export default Result;

import React, { useState } from "react";
import BaseLayout from "../../components/BaseLayout";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddNewPLEntry from "../../components/Accounting/PLRevenueentry";

const ProfitLossRevenue = () => {
  const [selectedDate, setSelectedDate] = useState("");

  const AddNewEntry = withReactContent(Swal);

  const showAddNewEntryForm = () => {
    const updatePopupWidth = () => {
      const isSmallScreen = window.innerWidth <= 768;
      const width = isSmallScreen ? "96%" : "60%";
      const popupContent = document.querySelector(".swal2-popup");
      if (popupContent) {
        popupContent.style.width = width;
      }
    };

    AddNewEntry.fire({
      html: <AddNewPLEntry />,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        container: "overflow-hidden",
        popup: "rounded-lg",
      },
      padding: 0,
      width: "auto",
      didOpen: () => {
        window.addEventListener("resize", updatePopupWidth);
      },
      willClose: () => {
        window.removeEventListener("resize", updatePopupWidth);
      },
    });
  };

  const balanceSheetData = [
    {
      date: "",
      revenues: [
        { description: "Product Sales", amount: 5000000 },
        { description: "Service Income", amount: 3000000 },
      ],
    },
  ];

  const filteredData = balanceSheetData.find(
    (item) => item.date === selectedDate
  );

  return (
    <BaseLayout title={"PL Revenue"}>
      <div className="container mx-auto px-6 py-10 bg-white rounded-lg shadow-md">
        <div className="flex justify-between align-middle mb-6">
          <div className="flex">
            <label className="text-gray-700 mr-3">Select Date</label>
            <div className="relative">
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                className="block w-[180px] border-gray-300 rounded-lg shadow-sm pl-3 pr-10 py-2 text-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>
          <div className="flex ">
            <button
              className="px-4 py-2 border-2 w-[12rem] border-[#5964DB] text-[#5964DB] rounded-lg font-medium hover:opacity-[88%] tracking-wide"
              onClick={showAddNewEntryForm}
            >
              + Add New
            </button>
            <button className="px-4 py-2 ml-6 bg-[#4D45B5] w-[12rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide">
              Save Transaction
            </button>
          </div>
        </div>

        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-[#EAECFF]">
              <th className="w-12 px-6 py-3 text-left text-lg font-medium text-gray-900 border-b border-r border-gray-300">
                S.N
              </th>
              <th className="px-6 py-3 text-left text-lg font-medium text-gray-900 border-b border-r border-gray-300">
                Revenue Entries
              </th>
              <th className="w-40 px-6 py-3 text-left text-lg font-medium text-gray-900 border-b border-gray-300">
                Revenue
              </th>
              {/* Include the date field but keep it hidden */}
              <th className="hidden">Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredData?.revenues.map((item, index) => (
              <tr key={index}>
                <td className="px-6 py-4 border-b border-r border-gray-300 text-gray-700">
                  {index + 1}
                </td>
                <td className="px-6 py-4 border-b border-r border-gray-300 text-gray-700">
                  {item.description}
                </td>
                <td className="w-36 px-6 py-4 border-b text-left border-gray-300 text-gray-700">
                  {item.amount.toLocaleString()}
                </td>
                {/* Hidden date cell */}
                <td className="hidden">{filteredData.date}</td>
              </tr>
            ))}
            <tr>
              <td className="w-12 px-6 py-4 font-semibold border-t border-r border-gray-300"></td>
              <td className="px-6 py-4 font-semibold border-t border-r border-gray-300 text-gray-700">
                Total Revenue
              </td>
              <td className="w-36 px-6 py-4 font-semibold border-t text-left border-gray-300 text-gray-700">
                0.00
              </td>
              <td className="hidden">{/* Hidden total revenue date cell */}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </BaseLayout>
  );
};

export default ProfitLossRevenue;

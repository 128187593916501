import React, { useState } from "react";
import useAxios from "../../../utils/axios/useAxios";

const ResultTable = ({ user }) => {
  const [filterYear, setYear] = useState("");
  const [filterTerm, setTerm] = useState("");

  const { data: testPaperResults, error: resultError } = useAxios(
    `classrooms/testpaper-results`
  );

  const { data: testPapers, error: testPapersError } = useAxios(
    `classrooms/testpapers`
  );

  const { data: subjects, error: subjectError } =
    useAxios(`classrooms/subjects`);

  const resultOfStudent = testPaperResults.filter(
    (result) => result.student === user.id
  );

  const theoryPapers = testPapers.filter(
    (paper) => paper.category === "theory"
  );

  const practicalPapers = testPapers.filter(
    (paper) => paper.category === "practical"
  );

  const filteredResults = resultOfStudent.filter((result) => {
    const paper = testPapers.find((p) => p.id === result.testpaper);
    return (
      paper &&
      (filterYear
        ? new Date(paper.created_at).getFullYear().toString() === filterYear
        : true) &&
      (filterTerm ? paper.type.toString() === filterTerm : true)
    );
  });

  // Extract unique years from test papers for the dropdown
  const years = Array.from(
    new Set(
      filteredResults.map((paper) =>
        new Date(paper.created_at).getFullYear().toString()
      )
    )
  );
  const uniqueTerms = Array.from(
    new Set(testPapers.map((paper) => paper.type))
  );

  return (
    <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between">
        <h2 className="text-xl font-semibold">Result</h2>
        <div className="space-x-4 text-[14px]">
          <select
            className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none focus:ring-[#4D45B5] focus:border-transparent"
            value={filterYear}
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="">Select Year</option>
            {years.map((yr) => (
              <option key={yr} value={yr}>
                {yr}
              </option>
            ))}
          </select>
          <select
            className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none focus:ring-[#4D45B5] focus:border-transparent"
            value={filterTerm}
            onChange={(e) => setTerm(e.target.value)}
          >
            <option value="">Select Term</option>
            {uniqueTerms.map((term, index) => (
              <option key={index} value={term}>
                {term}
              </option>
            ))}
          </select>
          <button
            className="px-4 py-2 bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
            // onClick={downloadResults}
          >
            Download
          </button>
        </div>
      </div>

      <div className="mt-6">
        {/* Practical Examination Table */}
        <h3 className="text-lg font-medium mb-4">Practical Examination</h3>
        <table className="min-w-full bg-white">
          <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px]">
            <tr>
              <th className="p-4 text-center rounded-tl-lg font-light">S.N</th>
              <th className="p-4 text-left font-light">Subject</th>
              <th className="p-4 text-center font-light">Test Paper</th>
              <th className="p-4 text-center font-light">Marks</th>
              <th className="p-4 text-center font-light">Percentage</th>
              <th className="p-4 text-center font-light rounded-tr-lg">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="text-[14px] border border-gray-300">
            {filteredResults.filter((result) =>
              practicalPapers.map((p) => p.id).includes(result.testpaper)
            ).length > 0 ? (
              filteredResults
                .filter((result) =>
                  practicalPapers.map((p) => p.id).includes(result.testpaper)
                )
                .map((result, index) => {
                  const currentTestPaper = testPapers.find(
                    (paper) => paper.id === result.testpaper
                  );

                  const currentSubject = subjects.find(
                    (subject) => subject.id === currentTestPaper.subject
                  );

                  return (
                    <tr
                      key={index}
                      className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg"
                    >
                      <td className="p-4 border-b border-gray-300 text-center">
                        {index + 1}
                      </td>
                      <td className="p-4 border-b border-gray-300 text-left">
                        {currentSubject.name}
                      </td>
                      <td className="p-4 border-b border-gray-300 text-center">
                        {currentTestPaper.title}
                      </td>
                      <td className="p-4 border-b border-gray-300 text-center">
                        {result.marks}
                      </td>
                      <td className="p-4 border-b border-gray-300 text-center">
                        {result.percentage} %
                      </td>
                      <td className="p-4 border-b border-gray-300 text-center">
                        {result.failed ? <>Fail</> : <>Pass</>}
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan="6" className="p-4 text-center">
                  No practical results available
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Theoretical Examination Table */}
        <h3 className="text-lg font-medium mt-10 mb-4">
          Theoretical Examination
        </h3>
        <table className="min-w-full bg-white">
          <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px]">
            <tr>
              <th className="p-4 text-center rounded-tl-lg font-light">S.N</th>
              <th className="p-4 text-left font-light">Subject</th>
              <th className="p-4 text-center font-light">Test Paper</th>
              <th className="p-4 text-center font-light">Marks</th>
              <th className="p-4 text-center font-light">Percentage</th>
              <th className="p-4 text-center font-light rounded-tr-lg">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="text-[14px] border border-gray-300">
            {filteredResults.filter((result) =>
              theoryPapers.map((p) => p.id).includes(result.testpaper)
            ).length > 0 ? (
              filteredResults
                .filter((result) =>
                  theoryPapers.map((p) => p.id).includes(result.testpaper)
                )
                .map((result, index) => {
                  const currentTestPaper = testPapers.find(
                    (paper) => paper.id === result.testpaper
                  );

                  const currentSubject = subjects.find(
                    (subject) => subject.id === currentTestPaper.subject
                  );

                  return (
                    <tr
                      key={index}
                      className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg"
                    >
                      <td className="p-4 border-b border-gray-300 text-center">
                        {index + 1}
                      </td>
                      <td className="p-4 border-b border-gray-300 text-left">
                        {currentSubject.name}
                      </td>
                      <td className="p-4 border-b border-gray-300 text-center">
                        {currentTestPaper.title}
                      </td>
                      <td className="p-4 border-b border-gray-300 text-center">
                        {result.marks}
                      </td>
                      <td className="p-4 border-b border-gray-300 text-center">
                        {result.percentage} %
                      </td>
                      <td className="p-4 border-b border-gray-300 text-center">
                        {result.failed ? <>Fail</> : <>Pass</>}
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan="6" className="p-4 text-center">
                  No theoretical results available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResultTable;

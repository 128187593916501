import React, { useState } from "react";
import BaseLayout from "../components/BaseLayout";
import { useParams } from "react-router-dom";

const colors = [
  { id: "black", color: "black", hex: "#030101" },
  { id: "purple", color: "purple", hex: "#4D45B5" },
  { id: "red", color: "red", hex: "#ED1313" },
  { id: "blue", color: "blue", hex: "#243970" },
  { id: "yellow", color: "yellow", hex: "#DB8E1A" },
  { id: "darkGrey", color: "darkGrey", hex: "#7F7171" },
  { id: "green", color: "green", hex: "#94C47D" },

];



const ColorTheme = () => {
  const { role } = useParams();
  const [selectedColor, setSelectedColor] = useState(null);

  const handleColorSelect = (colorId) => {
    setSelectedColor(colorId);
  };

  return (
    <BaseLayout title={"Settings"}>
      <div className="w-[70%] p-8 bg-white rounded-lg shadow-lg">
        {role === "principal" && (
          <>
            <h1 className="font-semibold text-xl mb-2">Color Theme</h1>
            <hr className="my-4" />
            <div className="flex justify-between mb-8">
              {colors.map((color) => (
                <div
                  key={color.id}
                  className={`rounded-md m-6 ${
                    selectedColor === color.id ? "border-2 border-black" : ""
                  }`}
                >
                  <div
                    className="w-12 h-12 m-1.5 rounded-md cursor-pointer"
                    style={{ backgroundColor: color.hex }}
                    onClick={() => handleColorSelect(color.id)}
                  ></div>
                </div>
              ))}
            </div>
          </>
        )}

        {/* Language and Region Settings */}
        <h1 className="font-semibold text-xl mb-2">Language and Region</h1>
        <hr className="my-4" />
        <div className="grid grid-cols-2 gap-8 mb-8">
          <div className="p-4">
            <h1 className="text-gray-700 mb-1">Language</h1>
            <select className="w-full border border-gray-500 px-4 py-3 rounded-md text-gray-500">
              <option value="english" selected>
                English
              </option>
              <option value="nepali">Nepali</option>
            </select>
          </div>
          <div className="p-4">
            <h1 className="text-gray-700">Region</h1>
            <select className="w-full border border-gray-500 px-4 py-3 rounded-md text-gray-500">
              <option value="Asia/kathmandu" selected>
                Asia/Kathmandu
              </option>
              <option value="Asia/Kolkata">Asia/Kolkata</option>
              <option value="Asia/Tokyo">Asia/Tokyo</option>
              <option value="Europe/London">Europe/London</option>
              <option value="Europe/Paris">Europe/Paris</option>
              <option value="Europe/Moscow">Europe/Moscow</option>
              <option value="America/Los_Angeles">America/Los Angeles</option>
              <option value="America/New_York">America/New York</option>
              <option value="America/Chicago">America/Chicago</option>
            </select>
          </div>
        </div>

        {/* Time and Date Region Settings */}
        <h1 className="font-semibold text-xl mb-2">Time and Date Region</h1>
        <hr className="my-4" />
        <div className="grid grid-cols-2 gap-8 mb-2">
          <div className="p-4">
            <h1 className="text-gray-700 mb-1">Time Zone</h1>
            <select className="w-full border border-gray-500 px-4 py-3 rounded-md text-gray-500">
              <option value="Nepal">(GMT+5:45) Nepal</option>
              <option value="India">(GMT+5:30) Indian</option>
              <option value="Pakistan">(GMT+5:00) Pakistan</option>
              <option value="China">(GMT+8:00) China</option>
              <option value="Japan">(GMT+9:00) Japan</option>
              <option value="Korea">(GMT+9:00) Korea</option>
            </select>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ColorTheme;

import React, { useState } from "react";
import Select from "react-select";
import addImage from "../../../assets/images/Principal/AddPhoto.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useAxiosAuth from "../../../utils/axios/useAxiosAuth";
import useAxios from "../../../utils/axios/useAxios";

// temporary data
const facultyOptions = [
  { value: "School", label: "School" },
  { value: "Physics", label: "Physics" },
  { value: "Biology", label: "Biology" },
  { value: "A-levels", label: "A-levels" },
  { value: "Management", label: "Management" },
];

const HRAddStudent = () => {
  const user = useAuthUser();
  const axios = useAxiosAuth();

  // getting data from api
  const { data: curriculums, error: curriculumError } = useAxios(
    `classrooms/curriculums`
  );
  const { data: classrooms, error: classroomError } = useAxios(
    `classrooms/classrooms`
  );
  // Function to convert data to options
  const toOptions = (data) =>
    Array.from(data.entries()).map(([id, name]) => ({
      value: id,
      label: name,
    }));

  // Create Maps
  const curriculumMap = new Map(curriculums.map(({ id, name }) => [id, name]));
  const classroomMap = new Map(classrooms.map(({ id, name }) => [id, name]));

  // Convert Maps to select options
  const gradeOptions = toOptions(curriculumMap);
  const sectionOptions = toOptions(classroomMap);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("00/00/0000");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [enrollmentDate, setEnrollmentDate] = useState("00/00/0000");
  const [faculty, setFaculty] = useState(null);
  const [grade, setGrade] = useState(null);
  const [section, setSection] = useState(null);
  const [transportation, setTransportation] = useState("");
  const [meal, setMeal] = useState("");
  const [guardianFields, setGuardianFields] = useState([
    { fullName: "", contactNumber: "", relation: "", occupation: "" },
  ]);
  const [permanentAddress, setPermanentAddress] = useState({
    country: "",
    province: "",
    city: "",
  });
  const [temporaryAddress, setTemporaryAddress] = useState({
    country: "",
    province: "",
    city: "",
  });
  const [isSameAsPermanent, setIsSameAsPermanent] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(addImage);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSelectChange = (setter) => (selectedOption) => {
    setter(selectedOption.value);
  };

  const handlePermanentAddressChange = (e) => {
    const { name, value } = e.target;
    setPermanentAddress((prev) => ({
      ...prev,
      [name]: value,
    }));

    // If checkbox is checked, update temporary address as well
    if (isSameAsPermanent) {
      setTemporaryAddress((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Handle changes in temporary address (if not same as permanent)
  const handleTemporaryAddressChange = (e) => {
    const { name, value } = e.target;
    setTemporaryAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const combineAddress = (address) => {
    const { city, province, country } = address;
    return `${city}, ${province}, ${country}`;
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsSameAsPermanent(checked);

    if (checked) {
      setTemporaryAddress(permanentAddress);
    }
  };

  const addGuardianFields = () => {
    setGuardianFields([
      ...guardianFields,
      { fullName: "", contactNumber: "", relation: "", occupation: "" },
    ]);
  };

  const submitAddStudent = (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append("photo", imageFile);
    formData.append("first_name", firstName);
    formData.append("middle_name", middleName);
    formData.append("last_name", lastName);
    formData.append("date_of_birth", formatDate(dob));
    formData.append(
      "address",
      isSameAsPermanent
        ? combineAddress(permanentAddress)
        : combineAddress(temporaryAddress)
    );
    formData.append("contact", contact);
    formData.append("email", email);
    formData.append("temp_address", combineAddress(temporaryAddress));
    formData.append("meal", meal);
    formData.append("enrollment_date", enrollmentDate);
    formData.append("transportation", transportation);
    formData.append("classroom", section);

    axios
      .post("api/classrooms/students/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setFirstName("");
        setMiddleName("");
        setLastName("");
        setDob("00/00/0000");
        setContact("");
        setEmail("");
        setGender("none");
        setImageFile("");
        setPermanentAddress({ country: "", province: "", city: "" });
        setTemporaryAddress({ country: "", province: "", city: "" });
        setIsSameAsPermanent(false);
        setGuardianFields([
          { fullName: "", contactNumber: "", relation: "", occupation: "" },
        ]);
        setEnrollmentDate("00/00/0000");
        setFaculty(null);
        setGrade(null);
        setSection(null);
        setTransportation(null);
        setMeal(null);
        toast.success("Student added successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to add student!");
      });
  };

  const handleResetForm = (event) => {
    event.preventDefault();
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setDob("00/00/0000");
    setContact("");
    setEmail("");
    setGender("none");
    setImageFile("");
    setPermanentAddress({ country: "", province: "", city: "" });
    setTemporaryAddress({ country: "", province: "", city: "" });
    setIsSameAsPermanent(false);
    setGuardianFields([
      { fullName: "", contactNumber: "", relation: "", occupation: "" },
    ]);
    setEnrollmentDate("00/00/0000");
    setFaculty(null);
    setGrade(null);
    setSection(null);
    setTransportation(null);
    setMeal(null);
  };

  return (
    <div className="container p-8 w-[85%] bg-white rounded-lg shadow-lg">
      <p className="font-semibold text-2xl mb-6">Personal Information</p>
      <form className="grid grid-cols-2 grid-rows-7 gap-y-5 gap-x-10 text-base">
        <div className="flex justify-between items-center">
          <label htmlFor="firstName" className="font-medium w-[28vh]">
            First Name
          </label>
          <input
            id="firstName"
            type="text"
            className="w-full border border-gray-300 rounded-[4px] p-2"
            placeholder="Lorem"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="block justify-center items-center row-span-7 w-[80%] mx-auto ">
          {imagePreview && (
            <img
              src={imagePreview}
              alt="profile"
              className="mx-auto rounded-lg w-[80%] border"
            />
          )}
          <p className="font-medium mt-6">Add image</p>
          <p className="text-sm my-2 opacity-50">
            Image data can be uploaded up to 20MB only.
          </p>
          <label
            htmlFor="imageupload"
            className="text-[#4d45b5] cursor-pointer px-3 py-1 rounded-md border-[#4d45b5] border text-sm"
          >
            Upload image
          </label>
          <input
            type="file"
            name="imageupload"
            id="imageupload"
            className="invisible"
            onChange={handleImageUpload}
            accept="image/*"
            required
          />
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="middleName" className="font-medium w-[28vh]">
            Middle Name
          </label>
          <input
            id="middleName"
            type="text"
            className="w-full border border-gray-300 rounded-[4px] p-2"
            placeholder="Lorem"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="lastName" className="font-medium w-[28vh]">
            Last Name
          </label>
          <input
            id="lastName"
            type="text"
            className="w-full border border-gray-300 rounded-[4px] p-2"
            placeholder="Lorem"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="dob" className="font-medium w-[28vh]">
            DOB
          </label>
          <input
            id="dob"
            type="date"
            className="w-full border border-gray-300 rounded-[4px] p-2"
            required
            value={dob}
            onChange={(e) => setDob(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="contact" className="font-medium w-[28vh]">
            Contact
          </label>
          <input
            id="contact"
            type="text"
            className="w-full border border-gray-300 rounded-[4px] p-2"
            placeholder="+977 984134234"
            required
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="email" className="font-medium w-[28vh]">
            Email
          </label>
          <input
            id="email"
            type="email"
            className="w-full border border-gray-300 rounded-[4px] p-2"
            placeholder="example@gmail.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="gender" className="font-medium w-[28vh]">
            Gender
          </label>
          <select
            id="gender"
            className="w-full ml-1 border border-gray-300 rounded-[4px] p-2"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option>Prefer not to say</option>
            <option>Male</option>
            <option>Female</option>
            <option>Other</option>
          </select>
        </div>

        {/* Permanent Address */}
        <p className="font-semibold text-lg mt-2 col-start-1 col-span-2 underline">
          Permanent Address
        </p>

        <div className="flex justify-between items-center">
          <label htmlFor="permanentCountry" className="font-medium w-[28vh]">
            Country
          </label>
          <input
            id="permanentCountry"
            type="text"
            name="country"
            value={permanentAddress.country}
            onChange={handlePermanentAddressChange}
            className="w-full border border-gray-300 rounded-[4px] p-2"
            placeholder="Country"
            required
          />
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="permanentProvince" className="font-medium w-[28vh]">
            Province/State
          </label>
          <input
            id="permanentProvince"
            type="text"
            name="province"
            value={permanentAddress.province}
            onChange={handlePermanentAddressChange}
            className="w-full border border-gray-300 rounded-[4px] p-2"
            placeholder="Province"
            required
          />
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="permanentCity" className="font-medium w-[28vh]">
            City
          </label>
          <input
            id="permanentCity"
            type="text"
            name="city"
            value={permanentAddress.city}
            onChange={handlePermanentAddressChange}
            className="w-full border border-gray-300 rounded-[4px] p-2"
            placeholder="City"
            required
          />
        </div>

        {/* Temporary Address */}
        <p className="font-semibold text-lg mt-2 col-start-1 col-span-2 underline">
          Temporary Address
        </p>

        <div className="flex items-center col-span-2 col-start-1">
          <input
            type="checkbox"
            id="sameAsPermanent"
            checked={isSameAsPermanent}
            onChange={handleCheckboxChange}
            className="mr-2 focus:ring-transparent rounded-sm"
          />
          <label htmlFor="sameAsPermanent">Same as Permanent Address</label>
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="temporaryCountry" className="font-medium w-[28vh]">
            Country
          </label>
          <input
            id="temporaryCountry"
            type="text"
            name="country"
            value={temporaryAddress.country}
            onChange={handleTemporaryAddressChange}
            className="w-full border border-gray-300 rounded-[4px] p-2"
            placeholder="Country"
            disabled={isSameAsPermanent}
          />
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="temporaryProvince" className="font-medium w-[28vh]">
            Province/State
          </label>
          <input
            id="temporaryProvince"
            type="text"
            name="province"
            value={temporaryAddress.province}
            onChange={handleTemporaryAddressChange}
            className="w-full border border-gray-300 rounded-[4px] p-2"
            placeholder="Province"
            disabled={isSameAsPermanent}
          />
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="temporaryCity" className="font-medium w-[28vh]">
            City
          </label>
          <input
            id="temporaryCity"
            type="text"
            name="city"
            value={temporaryAddress.city}
            onChange={handleTemporaryAddressChange}
            className="w-full border border-gray-300 rounded-[4px] p-2"
            placeholder="City"
            disabled={isSameAsPermanent}
          />
        </div>

        {/* Guardian information */}
        <p className="font-semibold text-2xl col-start-1 col-span-2 my-4">
          Guardian Information
        </p>
        {guardianFields.map((field, index) => (
          <React.Fragment key={index}>
            <div className="flex justify-between items-center">
              <label
                htmlFor={`guardianFullName_${index}`}
                className="font-medium w-[28vh]"
              >
                Full Name
              </label>
              <input
                id={`guardianFullName_${index}`}
                type="text"
                value={guardianFields[index].fullName}
                onChange={(e) => {
                  const newFields = [...guardianFields];
                  newFields[index].fullName = e.target.value;
                  setGuardianFields(newFields);
                }}
                className="w-full border border-gray-300 rounded-[4px] p-2"
                placeholder="Full Name"
              />
            </div>
            <div className="flex justify-between items-center">
              <label
                htmlFor={`guardianContactNumber_${index}`}
                className="font-medium w-[28vh]"
              >
                Contact Number
              </label>
              <input
                id={`guardianContactNumber_${index}`}
                type="text"
                value={guardianFields[index].contactNumber}
                onChange={(e) => {
                  const newFields = [...guardianFields];
                  newFields[index].contactNumber = e.target.value;
                  setGuardianFields(newFields);
                }}
                className="w-full border border-gray-300 rounded-[4px] p-2"
                placeholder="Contact Number"
              />
            </div>
            <div className="flex justify-between items-center">
              <label
                htmlFor={`guardianRelation_${index}`}
                className="font-medium w-[28vh]"
              >
                Relation
              </label>
              <input
                id={`guardianRelation_${index}`}
                type="text"
                value={guardianFields[index].relation}
                onChange={(e) => {
                  const newFields = [...guardianFields];
                  newFields[index].relation = e.target.value;
                  setGuardianFields(newFields);
                }}
                className="w-full border border-gray-300 rounded-[4px] p-2"
                placeholder="Relation"
              />
            </div>
            <div className="flex justify-between items-center">
              <label
                htmlFor={`guardianOccupation_${index}`}
                className="font-medium w-[28vh]"
              >
                Occupation
              </label>
              <input
                id={`guardianOccupation_${index}`}
                type="text"
                value={guardianFields[index].occupation}
                onChange={(e) => {
                  const newFields = [...guardianFields];
                  newFields[index].occupation = e.target.value;
                  setGuardianFields(newFields);
                }}
                className="w-full border border-gray-300 rounded-[4px] p-2"
                placeholder="Occupation"
              />
            </div>
          </React.Fragment>
        ))}

        <div className="col-start-1 col-span-2 flex justify-end space-x-4 mt-2">
          <button
            type="button"
            onClick={addGuardianFields}
            className="px-4 py-2 bg-[#4D45B5] text-white text-sm  rounded-lg font-medium hover:bg-[#3e36b1] tracking-wide"
          >
            Add More
          </button>
        </div>

        {/* Additional information */}
        <p className="font-semibold text-2xl col-start-1 col-span-2 my-4">
          Additional Information
        </p>

        <div className="flex justify-between items-center">
          <label htmlFor="enrollmentDate" className="font-medium w-[28vh]">
            Enrollment Date
          </label>
          <input
            className="w-full rounded-[4px] border-gray-300 text-gray-500 p-2 ml-1"
            type="date"
            id="enrollmentDate"
            placeholder="enrollmentDate"
            required
            value={enrollmentDate}
            onChange={(e) => setEnrollmentDate(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="faculty" className="font-medium w-[28vh]">
            Faculty
          </label>
          <div id="typeAssignedWrapper" className="w-full">
            <Select
              inputId="faculty"
              name="faculty"
              options={facultyOptions}
              className="w-full border-1"
              value={facultyOptions.find((option) => option.value === faculty)}
              onChange={handleSelectChange(setFaculty)}
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="grade" className="font-medium w-[28vh]">
            Grade
          </label>
          <div id="typeAssignedWrapper" className="w-full">
            <Select
              inputId="grade"
              name="grade"
              options={gradeOptions}
              className="w-full border-1"
              value={gradeOptions.find((option) => option.value === grade)}
              onChange={handleSelectChange(setGrade)}
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="section" className="font-medium w-[28vh]">
            Section
          </label>
          <div id="typeAssignedWrapper" className="w-full">
            <Select
              inputId="section"
              name="section"
              options={sectionOptions}
              className="w-full border-1"
              value={sectionOptions.find((option) => option.value === section)}
              onChange={handleSelectChange(setSection)}
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="transportation" className="font-medium w-[28vh]">
            Tansportation
          </label>
          <select
            id="transportation"
            className="w-full ml-2 border border-gray-300 text-gray-500 rounded-[4px] p-2"
            value={transportation}
            onChange={(e) => setTransportation(e.target.value)}
          >
            <option selected value="none">
              None
            </option>
            <option value="one_way">One Way</option>
            <option value="two_way">Two Way</option>
          </select>
        </div>
        <div className="flex justify-between items-center">
          <label htmlFor="meal" className="font-medium w-[28vh]">
            Meal
          </label>
          <select
            id="meal"
            className="w-full ml-2 border border-gray-300 text-gray-500 rounded-[4px] p-2"
            value={meal}
            onChange={(e) => setMeal(e.target.value)}
          >
            <option selected value="none">
              None
            </option>
            <option value="breakfast">Breakfast</option>
            <option value="lunch">Lunch</option>
            <option value="dinner">Dinner</option>
          </select>
        </div>

        <div className="col-start-1 col-span-2 flex justify-end space-x-4 mt-4">
          <button
            onClick={submitAddStudent}
            className="px-4 py-2 bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:bg-[#3e36b1] tracking-wide"
          >
            Save
          </button>
          <button
            type="button"
            onClick={handleResetForm}
            className="px-4 py-2 text-[#B54545] w-[10rem] border-2 border-[#B54545] font-medium tracking-wide rounded-lg hover:bg-[#B54545] hover:text-white hover:ease-in-out hover:transition duration-[500ms]"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default HRAddStudent;

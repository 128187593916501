("use client");

import React from "react";
import { Button, Navbar } from "flowbite-react";

const nav = () => {
  const navItems = [
    {
      name: "Home",
      link: "/view",
    },
    {
      name: "Plans",
      link: "/view/plans",
    },
    {
      name: "Features",
      link: "/view/features",
    },
  ];

  return (
    <Navbar
      className="max-w-screen-2xl mx-auto md:px-10 px-5 py-5 bg-transparent z-50 relative"
      fluid
      rounded
    >
      <Navbar.Brand href="/view">
        <img
          src={require("../../assets/images/logo.png")}
          className="mr-3 h-6 sm:h-14"
          alt="Flowbite React Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        {navItems.map((item, index) => (
          <Navbar.Link
            className="md:hover:text-primary text-primary text-lg h-full place-content-center"
            href={item.link}
            key={index}
          >
            {item.name}
          </Navbar.Link>
        ))}
        <Navbar.Link href="/login">
          <Button className="border-primary bg-transparent text-primary hover:bg-primary hover:text-white">
            Sign In
          </Button>
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default nav;

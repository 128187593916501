import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import useMediaQuery from "@mui/material/useMediaQuery";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Circle from "../../assets/images/plans/circle.svg";
import CircleBlue from "../../assets/images/plans/circleBlue.svg";
import Triangle from "../../assets/images/plans/triangle.svg";
import TriangleSmall from "../../assets/images/plans/triangleSmall.svg";
import Arc from "../../assets/images/plans/arc.svg";
import CircleQuartile from "../../assets/images/plans/circleQuartile.svg";
import Tick from "../../assets/images/plans/tick.svg";
import Untick from "../../assets/images/plans/untick.svg";
import ArcOrange from "../../assets/images/plans/arcOrange.svg";
import CircleQuartileOrange from "../../assets/images/plans/circleQuartileOrange.svg";
import TickOrange from "../../assets/images/plans/tickOrange.svg";

const planData = [
  {
    title: "Basic",
    price: "Free",
    description:
      "Lorem ipsum dolor sit amet consectetur. Ultrices sed neque vulputate elit sagittis. At.",
    backgroundColor: "#D5D3F3",
    quartileSrc: CircleQuartile,
    arcSrc: Arc,
    tickSrc: Tick,
    untickSrc: Untick,
    buttonColor: "#4D45B5",
    features: [
      "Lorem ipsum dolor sit amet consectetur. Mi at leo.",
      "Lorem ipsum dolor sit amet consectetur. Ornare.",
      "Lorem ipsum dolor sit amet consectetur. Ridiculus.",
      "Lorem ipsum dolor sit amet consectetur. Ut leo mi at.",
      "Lorem ipsum dolor sit amet consectetur. Eget morbi.",
      "Lorem ipsum dolor sit amet consectetur. Habitant.",
      "Lorem ipsum dolor sit amet consectetur. Aenean.",
      "Lorem ipsum dolor sit amet consectetur. Faucibus.",
      "Lorem ipsum dolor sit amet consectetur. Sem ut.",
      "Lorem ipsum dolor sit amet consectetur. A ac non.",
    ],
    unavailableFeatures: [
      "Lorem ipsum dolor sit amet consectetur. Integer.",
      "Lorem ipsum dolor sit amet consectetur. Tristique.",
      "Lorem ipsum dolor sit amet consectetur. Quis.",
    ],
  },
  {
    title: "Premium",
    price: "NPR 999",
    description:
      "Lorem ipsum dolor sit amet consectetur. Ultrices sed neque vulputate elit sagittis. At.",
    backgroundColor: "#F2E1D2",
    quartileSrc: CircleQuartileOrange,
    arcSrc: ArcOrange,
    tickSrc: TickOrange,
    buttonColor: "#E6A23A",
    features: [
      "Lorem ipsum dolor sit amet consectetur. Neque id.",
      "Lorem ipsum dolor sit amet consectetur. Nunc luctus.",
      "Lorem ipsum dolor sit amet consectetur. Eu integer.",
      "Lorem ipsum dolor sit amet consectetur. Faucibus.",
      "Lorem ipsum dolor sit amet consectetur. Lorem morbi.",
      "Lorem ipsum dolor sit amet consectetur. Massa.",
      "Lorem ipsum dolor sit amet consectetur. Mattis.",
      "Lorem ipsum dolor sit amet consectetur. Eget felis.",
      "Lorem ipsum dolor sit amet consectetur. Amet eget.",
      "Lorem ipsum dolor sit amet consectetur. Porttitor.",
      "Lorem ipsum dolor sit amet consectetur. Placerat.",
      "Lorem ipsum dolor sit amet consectetur. Mattis.",
      "Lorem ipsum dolor sit amet consectetur. Eget felis.",
      "Lorem ipsum dolor sit amet consectetur. Amet eget.",
      "Lorem ipsum dolor sit amet consectetur. Porttitor.",
      "Lorem ipsum dolor sit amet consectetur. Placerat.",
    ],
    unavailableFeatures: [],
  },
  {
    title: "Custom",
    price: "NPR 9999",
    description:
      "Lorem ipsum dolor sit amet consectetur. Ultrices sed neque vulputate elit sagittis. At.",
    backgroundColor: "#D5D3F3",
    quartileSrc: CircleQuartile,
    arcSrc: Arc,
    tickSrc: Tick,
    buttonColor: "#4D45B5",
    features: [
      "Lorem ipsum dolor sit amet consectetur. Mi at leo.",
      "Lorem ipsum dolor sit amet consectetur. Ornare.",
      "Lorem ipsum dolor sit amet consectetur. Ridiculus.",
      "Lorem ipsum dolor sit amet consectetur. Ut leo mi at.",
      "Lorem ipsum dolor sit amet consectetur. Eget morbi.",
      "Lorem ipsum dolor sit amet consectetur. Habitant.",
      "Lorem ipsum dolor sit amet consectetur. Aenean.",
      "Lorem ipsum dolor sit amet consectetur. Faucibus.",
      "Lorem ipsum dolor sit amet consectetur. Sem ut.",
      "Lorem ipsum dolor sit amet consectetur. A ac non.",
      "Lorem ipsum dolor sit amet consectetur. Integer.",
      "Lorem ipsum dolor sit amet consectetur. Tristique.",
      "Lorem ipsum dolor sit amet consectetur. Quis.",
    ],
    unavailableFeatures: [],
  },
];

function DesktopView() {
  return (
    <div className="overflow-hidden h-max">
      <div className="-z-10 h-5">
        <img src={Circle} alt="circle top" className="absolute top-[5vh]" />
        <img
          src={TriangleSmall}
          alt="triangle small"
          className="absolute left-[30vw] top-[10vh]"
        />
        <img
          src={Triangle}
          alt="triangle big"
          className="absolute left-[28vw] top-[12vh]"
        />
        <img
          src={TriangleSmall}
          alt="triangle small"
          className="absolute left-[82vw] top-[25vh]"
        />
        <img
          src={Triangle}
          alt="triangle big"
          className="absolute left-[85vw] top-[22vh]"
        />
        <img
          src={CircleBlue}
          alt="circle blue top"
          className="absolute left-[33.5vw] top-[74vh]"
        />
      </div>

      {/* plans */}
      <div className="plans relative grid gap-8 grid-cols-3 grid-rows-1 w-full mt-36 mb-20 px-16">
        {planData.map((plan, index) => (
          <Link
            key={index}
            to={plan.title === "Custom" ? "../contactUs" : "../billing"}
            className={`rounded-3xl transition ease-in-out  hover:-translate-y-1 hover:scale-[1.02] duration-300 z-10 h-max ${
              plan.title === "Premium"
                ? "min-[1950px]:-mt-32 -mt-24"
                : plan.title === "Custom"
                ? "min-[1950px]:-mt-16 -mt-8"
                : "min-[1950px]:-mt-6 -mt-2"
            }`}
            style={{ backgroundColor: plan.backgroundColor }}
          >
            <div className="relative -z-10">
              <img
                src={plan.quartileSrc}
                alt="circle quartile"
                className="absolute top-0 right-0 rounded-tr-3xl md:w-32 md:h-32 lg:w-36 lg:h-36"
              />
              <img
                src={plan.arcSrc}
                alt="arc"
                className="absolute top-0 right-0 md:w-36 md:h-36 lg:w-40 lg:h-40"
              />
            </div>
            <div className="px-[2rem] py-[2.5rem] justify-center mt-5 z-20">
              <span className="min-[1950px]:text-xl font-semibold bg-[#423152] text-[#FFFFFF] px-5 py-2  rounded-3xl">
                {plan.price}
              </span>
              <span
                className="text-2xl md:text-3xl lg:text-3xl min-[1950px]:text-4xl font-semibold block my-7"
                style={{ color: plan.buttonColor }}
              >
                {plan.title}
              </span>
              <p className="text-[#4A494B]">
                {plan.title === "Custom" ? (
                  <div>
                    {plan.description}
                    <p className="text-2xl md:text-3xl lg:text-3xl min-[1950px]:text-4xl font-bold mt-6">
                      Let's talk
                    </p>
                    <p className="text-xl min-[1950px]:text-2xl">
                      About your custom needs
                    </p>
                  </div>
                ) : (
                  <p>{plan.description}</p>
                )}
              </p>

              <div
                className={`${
                  plan.title === "Premium" ? "space-y-8" : "space-y-6"
                }`}
              >
                {plan.features.map((feature, i) => (
                  <div
                    key={i}
                    className="flex items-start text-[#4A494B] text-lg min-[1950px]:text-xl tracking-tight"
                  >
                    <img
                      src={plan.tickSrc}
                      alt="tick mark"
                      className="w-4 h-4 min-[1950px]:w-6 min-[1950px]:h-6 m-2 mt-1.5"
                    />
                    <span>{feature}</span>
                  </div>
                ))}
                {plan.unavailableFeatures.map((feature, i) => (
                  <div
                    key={i}
                    className="flex items-start text-[#9B99A5] text-xl min-[1950px]:text-2xl tracking-tight"
                  >
                    <img
                      src={plan.untickSrc}
                      alt="untick mark"
                      className="w-5 h-5 min-[1950px]:w-8 min-[1950px]:h-8 m-2 mt-1.5"
                    />
                    <span>{feature}</span>
                  </div>
                ))}
              </div>
            </div>
          </Link>
        ))}
      </div>

      <div className="-z-10 h-10">
        <img
          src={CircleBlue}
          alt="circle blue bottom"
          className="relative left-[59vw] bottom-[15vh]"
        />
        <img
          src={Circle}
          alt="circle bottom"
          className="relative left-[88vw] bottom-[64vh] rotate-[180deg]"
        />
      </div>
    </div>
  );
}

function MobileView() {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "60px",
    slidesToScroll: 1,
    initialSlide: 1,
  };

  const renderPlans = (plans) => {
    return plans.map((plan, index) => (
      <Link
        key={index}
        to={plan.title === "Custom" ? "../contactUs" : "../billing"}
        className="md:mt-32 mt-24"
      >
        <div
          className={`rounded-3xl mb-8 lg:mx-6 md:mx-4 mx-2 text-sm h-max flex items-center ${
            plan.title === "Premium"
              ? "md:-mt-24 -mt-20"
              : plan.title === "Custom"
              ? "md:-mt-12 -mt-16"
              : "md:-mt-2 -mt-8"
          }`}
          style={{ backgroundColor: plan.backgroundColor }}
        >
          <div className="relative">
            <img
              src={plan.quartileSrc}
              alt="circle quartile"
              className="absolute top-0 right-0 rounded-tr-3xl md:w-40 md:h-40 lg:w-44 lg:h-44 w-28 h-28 z-10"
            />
            <img
              src={plan.arcSrc}
              alt="arc"
              className="absolute top-0 right-0 md:w-44 md:h-44 lg:w-48 lg:h-48 w-32 h-32 z-20"
            />
          </div>
          <div className="p-8 md:p-10 justify-center mt-5">
            <span className="text-sm md:text-base font-semibold bg-[#423152] text-[#FFFFFF] py-1.5 px-3.5 rounded-3xl">
              {plan.price}
            </span>
            <span
              className="text-3xl md:text-4xl font-semibold block md:my-6 my-4"
              style={{ color: plan.buttonColor }}
            >
              {plan.title}
            </span>
            <p className="text-base md:text-xl text-[#4A494B]">
              {plan.title === "Custom" ? (
                <div>
                  {plan.description}
                  <p className="text-2xl font-bold mt-6">Let's talk</p>
                  <p>About your custom needs</p>
                </div>
              ) : (
                <p>{plan.description}</p>
              )}
            </p>
  
            <div className="space-y-2 md:space-y-5">
              {plan.features.map((feature, i) => (
                <div
                  key={i}
                  className="flex items-start text-[#4A494B] tracking-tight"
                >
                  <img
                    src={plan.tickSrc}
                    alt="tick mark"
                    className="w-4 h-4 md:w-6 md:h-6 m-1 mt-1.5 md:mt-1 md:m-2"
                  />
                  <span className="text-base md:text-xl">{feature}</span>
                </div>
              ))}
              {plan.unavailableFeatures.map((feature, i) => (
                <div
                  key={i}
                  className="flex items-start text-[#9B99A5] tracking-tight"
                >
                  <img
                    src={plan.untickSrc}
                    alt="untick mark"
                    className="w-4 h-4 md:w-6 md:h-6 m-1 mt-1.5 md:mt-1 md:m-2"
                  />
                  <span className="text-base md:text-xl">{feature}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Link>
    ));
  };

  return (
    <div className="plans relative my-8">
      <Slider {...sliderSettings}>{renderPlans(planData)}</Slider>
    </div>
  );
}

function Plans() {
  const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <div className="overflow-hidden h-max bg-white">
      <div className="relative z-10">
        <h1 className="font-poppins text-3xl md:text-4xl lg:text-4xl min-[1950px]:text-5xl font-semibold text-center mt-8 mb-4">
          Lorem ipsum dolor sit
        </h1>
        <p className="text-center text-lg md:text-xl lg:text-xl min-[1950px]:text-2xl px-8">
          Lorem ipsum dolor sit amet consectetur. Egestas eu volutpat potenti
          tellus viverra ultrices facilisis arcu. <br /> Ipsum elementum purus
          tellus cras morbi malesuada. Lorem
        </p>
      </div>

      {isMobile ? <MobileView /> : <DesktopView />}
    </div>
  );
}

export default Plans;

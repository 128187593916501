import React, { useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import BaseLayout from "../components/BaseLayout";

const ChangePassword = () => {
  const [passwordVisibility, setPasswordVisibility] = useState({
    Current: false,
    New: false,
    Confirm: false,
  });

  const togglePasswordVisibility = (item) => {
    setPasswordVisibility((prevVisibility) => ({
      ...prevVisibility,
      [item]: !prevVisibility[item],
    }));
  };

  return (
    <BaseLayout title={"Settings"}>
      <div className=" w-[40%] p-8 bg-white rounded-lg shadow-lg">
        <h1 className="font-semibold text-2xl mb-4">Change Password</h1>
        <form className="p-4">
          {["Current", "New", "Confirm"].map((item, index) => (
            <div className="mb-8" key={index}>
              <label className="text-base text-left font-medium text-gray-700">
                {item} Password
              </label>
              <div className="relative w-full mt-1">
                <input
                  type={passwordVisibility[item] ? "text" : "password"}
                  className="w-full pl-4 pr-10 py-2 border border-gray-300 rounded-md"
                  placeholder="Lorem"
                  autoComplete="password"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility(item)}
                  className="absolute inset-y-0 right-0 pr-4 flex items-center"
                >
                  {passwordVisibility[item] ? <LuEyeOff /> : <LuEye />}
                </button>
              </div>
            </div>
          ))}

          <div className="flex justify-end text-sm">
            <button className="py-1 px-12 bg-[#4D45B5] text-white rounded-md">
              Save
            </button>
          </div>
        </form>
      </div>
    </BaseLayout>
  );
};

export default ChangePassword;

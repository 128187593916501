import React, { useEffect, useState } from "react";
import { SlOptions } from "react-icons/sl";

import useAxios from "../../../utils/axios/useAxios.js";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

// The attendance can currently be edited but the check and unchecked data is not being set to backend currently
// The change is being handled by the function (handleCheckboxChange) this value has to be send to the endpoint once the user saves

// The role change here in the attendance is not being filtered and is returining null values for all except student
// the role needs to be filtered based on the student enpoint response i.e function (handleRoleSelection)

// temporary data
// const teacherData = [
//   {
//     id: 1,
//     name: "Lorem Teacher",
//     subject: "Math",
//     employee_id: 10235,
//   },
//   {
//     id: 2,
//     name: "Lorem Teacher 2",
//     subject: "Science",
//     employee_id: 10236,
//   },
//   {
//     id: 3,
//     name: "Lorem Teacher 3",
//     subject: "English",
//     employee_id: 10237,
//   },
//   {
//     id: 4,
//     name: "Lorem Teacher 4",
//     subject: "History",
//     employee_id: 10238,
//   },
// ];

// const facultyData = [
//   {
//     id: 1,
//     name: "Faculty A",
//     employee_id: 20235,
//   },
//   {
//     id: 2,
//     name: "Faculty B",
//     employee_id: 20236,
//   },
//   {
//     id: 3,
//     name: "Faculty C",
//     employee_id: 20237,
//   },
//   {
//     id: 4,
//     name: "Faculty D",
//     employee_id: 20238,
//   },
// ];

const AttendanceList = () => {
  const authUser = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();

  const [currentPage, setCurrentPage] = useState(1);
  const [filterDate, setFilterDate] = useState("");
  const [filterGrade, setFilterGrade] = useState("");
  const [filterClassroom, setFilterClassroom] = useState("");
  const [selectedRole, setSelectedRole] = useState("Student");

  // getting data from api
  const { data: students, error: studentError } =
    useAxios(`classrooms/students`);
  const { data: classrooms, error: classroomsError } = useAxios(
    `classrooms/classrooms`
  );
  const { data: studentAttendance, error: studentAttendanceError } = useAxios(
    "classrooms/student-attendances"
  );
  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(() => {
    if (students && classrooms && studentAttendance) {
      // Create maps for quick lookup
      const studentMap = new Map(
        students.map((student) => [student.id, student])
      );
      const classroomMap = new Map(
        classrooms.map((classroom) => [classroom.id, classroom.name])
      );

      // Map attendance data with necessary details from student and classrooms
      const mappedAttendanceData = studentAttendance.map((attendance) => {
        const studentDetails = studentMap.get(attendance.student) || {};
        const classroomName = classroomMap.get(studentDetails.classroom);

        return {
          ...attendance,
          studentName: `${studentDetails.first_name} ${
            studentDetails.middle_name || ""
          } ${studentDetails.last_name}`,
          studentId: studentDetails.id,
          classroom: classroomName,
        };
      });

      setAttendanceData(mappedAttendanceData);
    }
  }, [students, classrooms, studentAttendance]);

  const itemsPerPage = 6;

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
    setIsOptionsOpen(false);
  };

  const handleCheckboxChange = (id, newValue) => {
    const updatedData = attendanceData.map((item) => {
      if (item.id === id) {
        return { ...item, present: newValue };
      }
      return item;
    });
    setAttendanceData(updatedData);
  };

  const getCurrentData = () => {
    let currentData = [...attendanceData];

    switch (selectedRole) {
      case "Teacher":
        break;
      case "Faculty":
        break;
      case "Student":
      default:
        currentData = currentData.filter((item) => {
          const studentDetails = students.find(
            (student) => student.id === item.studentId
          );
          return (
            (filterDate ? item.date === filterDate : true) &&
            (filterClassroom
              ? studentDetails.classroom.toString() === filterClassroom
              : true)
          );
        });

        break;
    }

    return currentData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  };

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  return (
    <div className="container mt-10 mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold ml-4">{`${selectedRole} List`}</h2>
        <div className="flex items-center space-x-4 text-[12px] mr-8">
          <input
            id="dob"
            type="date"
            className="border px-6 py-1 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none focus:ring-[#4D45B5] focus:border-transparent"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
          />
          {selectedRole === "Student" && (
            <>
              {/* <select
                className="border px-6 py-1 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none focus:ring-[#4D45B5] focus:border-transparent"
                value={filterGrade}
                onChange={(e) => setFilterGrade(e.target.value)}
              >
                <option value="">Class</option>
                {[...new Set(attendanceData.map((item) => item.grade))].map(
                  (grade) => (
                    <option key={grade} value={grade}>
                      {grade}
                    </option>
                  )
                )}
              </select> */}
              <select
                className="w-[11rem] border px-3 py-1 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
                value={filterClassroom}
                onChange={(e) => setFilterClassroom(e.target.value)}
              >
                <option value="">All Classrooms</option>
                {classrooms.map((classroom) => (
                  <option key={classroom.id} value={classroom.id.toString()}>
                    {classroom.name}
                  </option>
                ))}
              </select>
            </>
          )}
          <div className="relative">
            <button onClick={toggleOptions} className="focus:outline-none">
              <SlOptions />
            </button>
            {isOptionsOpen && (
              <div className="absolute right-0 mt-5 w-24 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                <ul>
                  <li
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleRoleSelection("Teacher")}
                  >
                    Teacher
                  </li>
                  <li
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleRoleSelection("Student")}
                  >
                    Student
                  </li>
                  <li
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleRoleSelection("Faculty")}
                  >
                    Faculty
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr className="my-4" />

      <div className="container mx-auto px-4">
        <table className="min-w-full bg-white">
          <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px]">
            <tr>
              <th className="p-4 text-center rounded-tl-lg font-light">S.N</th>
              <th className="p-4 text-left font-light">Name</th>
              {selectedRole === "Student" && (
                <>
                  <th className="p-4 text-center font-light">Student ID</th>
                  <th className="p-4 text-center font-light">Section</th>
                </>
              )}
              {selectedRole === "Teacher" && (
                <>
                  <th className="p-4 text-center font-light">Subject</th>
                  <th className="p-4 text-center font-light">Employee ID</th>
                </>
              )}
              {selectedRole === "Faculty" && (
                <th className="p-4 text-center font-light">Employee ID</th>
              )}
              <th className="p-4 text-center font-light">Date</th>
              <th className="p-4 text-center font-light rounded-tr-lg">
                Attendance
              </th>
            </tr>
          </thead>

          <tbody className="text-[14px] border border-gray-300">
            {getCurrentData().map((item, index) => (
              <tr
                key={item.id}
                className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg"
              >
                <td className="p-4 border-b border-gray-300 text-center">
                  {(currentPage - 1) * itemsPerPage + index + 1}
                </td>

                {selectedRole === "Student" && (
                  <>
                    <td className="p-4 border-b border-gray-300 text-left ">
                      {item.studentName}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {item.studentId}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {item.classroom}
                    </td>
                  </>
                )}
                {selectedRole === "Teacher" && (
                  <>
                    <td className="p-4 border-b border-gray-300 text-left ">
                      {item.teacherName}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {item.subject}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {item.employee_id}
                    </td>
                  </>
                )}
                {selectedRole === "Faculty" && (
                  <>
                    <td className="p-4 border-b border-gray-300 text-left ">
                      {item.facultyName}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {item.employee_id}
                    </td>
                  </>
                )}
                <td className="p-4 border-b border-gray-300 text-center">
                  {item.date}
                </td>
                <td className="p-4 border-b border-gray-300 text-center">
                  <div className="flex justify-center">
                    <input
                      type="checkbox"
                      id={`attendance-checkbox-${item.id}`}
                      className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded"
                      checked={item.present}
                      onChange={(e) =>
                        handleCheckboxChange(item.id, e.target.checked)
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="text-center col-span-2 flex justify-end">
          <button className="mt-4 px-6 py-2 bg-[#5964DB] text-white rounded-md">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AttendanceList;

import React, { useState } from "react";
import { IoNotifications } from "react-icons/io5";
import { useParams } from "react-router-dom";


const BaseLayout = ({ title, children }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [showAlert, setShowAlert] = useState(!isFormCompleted);
  const { role } = useParams();

  

  return (
    <div className="max-w-screen-2xl mx-auto px-10 py-5 font-poppins min-h-screen bg-[#FBFBFF]">
      {/* Alert bar for parents */}
      {role === "principal" && showAlert && (
        <div className="w-full absolute top-0 z-40 bg-red-500 text-white left-0 p-4 flex justify-between">
          <span>Please complete the entire registration process</span>
          <button onClick={() => setShowAlert(false)} className="text-white font-bold">
            X
          </button>
        </div>
      )}

      <div className="flex justify-between items-center mb-12">
        <div>
          <h1 className="text-2xl font-semibold text-[#5964DB]">{title}</h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-[#928DD3] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4D45B5] focus:border-transparent"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-[#928DD3]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35M17.65 10.65a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
          <div className="text-[#928DD3]">
            <IoNotifications className="w-7 h-7 duration-200 hover:text-[#4D45B5] hover:scale-110" />
          </div>
        </div>
      </div>

      {children}
    </div>
  );
};

export default BaseLayout;

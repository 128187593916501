import BaseLayout from "../../components/BaseLayout";
import useAxios from "../../../utils/axios/useAxios";

const NoticeHistory = () => {
  const { data: notices = [], error } = useAxios(`classrooms/announcements/`);
  const { data: issuedBy, error: issuedByError } = useAxios(`users/users/`);

  if (!notices || !issuedBy) {
    return (
      <div className="w-[98%] bg-white rounded-lg shadow-md px-4 py-6 h-[62vh]">
        <p>Loading...</p>
      </div>
    );
  }

  const isPastDate = (dateString) => {
    const currentDate = new Date();
    const noticeDate = new Date(dateString);
    return noticeDate < currentDate.setHours(0, 0, 0, 0);
  };

  const pastNotices = notices.filter((notice) =>
    isPastDate(notice.expiration_date)
  );

  return (
    <BaseLayout title={"Notice History"}>
      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold">Notices</h2>
        </div>
        <hr className="my-4" />
        <div>
          {pastNotices.length > 0 ? (
            pastNotices.map((notice) => {
              const issuedUser = issuedBy.find(
                (user) => user.id === notice.created_by
              );
              return (
                <div
                  key={notice.id}
                  className={`mb-4 py-10 px-8 space-y-6 border rounded-lg shadow-sm ${
                    isPastDate(notice.expiration_date)
                      ? "border-l-4 border-l-red-500"
                      : "border-l-4 border-l-[#4D45B5]"
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <h3 className="text-xl text-[#4D45B5]">{notice.title}</h3>
                    <p className="opacity-[57%]">
                      Date:{" "}
                      {new Date(
                        notice.announcement_date
                      ).toLocaleDateString()}
                    </p>
                  </div>
                  <p className="opacity-[57%]">
                    Issuer: {issuedUser?.username || "Unknown"}
                  </p>
                  <p className="opacity-[57%] leading-[28px] text-justify">
                    {notice.description}
                  </p>
                </div>
              );
            })
          ) : (
            <div className="mb-10 py-10 px-8 space-y-6 border rounded-2xl shadow-sm h-[24rem] border-gray-400 flex items-center justify-center">
              <p className="text-xl text-gray-400 font-bold opacity-85">
                No notices found
              </p>
            </div>
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

export default NoticeHistory;

import Herosection from "../components/home/herosection";
import HomeAboutUs from "../components/home/homeaboutus";
import HomeFaq from "../components/home/homefaq";
import Homepackages from "../components/home/homepackages";
import HomeServices from "../components/home/homeServices";
import Homesolutions from "../components/home/homesolutions";
import HomeTestimonial from "../components/home/hometestimonial";

function Home() {
    return (
      <div className="max-w-screen-2xl mx-auto font-poppins">
        <Herosection />
        <Homesolutions />
        <Homepackages />
        <HomeFaq />
        <HomeServices />
        <HomeAboutUs />
        <HomeTestimonial />
      </div>
    );
  }
  
  export default Home;
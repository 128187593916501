import Slider from "react-slick";


const data = [
    {
        img : require("../../../assets/images/home/testimonialProfile.png"), 
        name : 'Devanshu Aryal',
        title : 'lorem ipsum dolor sit amet consectetur',
        description : 'Lorem ipsum dolor sit amet consectetur. Justo etiam placerat quis scelerisque tristique at interdum porttitor. Turpis eget ac pellentesque facilisis nunc ultrices facilisis vitae urna. Volutpat sed egestas rhoncus aliquet elementum hendrerit. Aliquet at lacus vivamus interdum non. Sit magna facilisis nec nulla in. Sed suspendisse convallis fermentum sagittis mauris porttitor mi. Duis fringilla elementum neque nibh nullam pellentesque metus eget eu. Purus porta odio vel hac leo. '
    },
    {
        img : require("../../../assets/images/home/testimonialProfile.png"), 
        name : 'Cheez-itz Shrestha',
        title : 'lorem ipsum dolor sit amet consectetur',
        description : 'Lorem ipsum dolor sit amet consectetur. Justo etiam placerat quis scelerisque tristique at interdum porttitor. Turpis eget ac pellentesque facilisis nunc ultrices facilisis vitae urna. Volutpat sed egestas rhoncus aliquet elementum hendrerit. Aliquet at lacus vivamus interdum non. Sit magna facilisis nec nulla in. Sed suspendisse convallis fermentum sagittis mauris porttitor mi. Duis fringilla elementum neque nibh nullam pellentesque metus eget eu. Purus porta odio vel hac leo. '
    },
    {
        img : require("../../../assets/images/home/testimonialProfile.png"), 
        name : 'Ojaswi Baidya',
        title : 'lorem ipsum dolor sit amet consectetur',
        description : 'Lorem ipsum dolor sit amet consectetur. Justo etiam placerat quis scelerisque tristique at interdum porttitor. Turpis eget ac pellentesque facilisis nunc ultrices facilisis vitae urna. Volutpat sed egestas rhoncus aliquet elementum hendrerit. Aliquet at lacus vivamus interdum non. Sit magna facilisis nec nulla in. Sed suspendisse convallis fermentum sagittis mauris porttitor mi. Duis fringilla elementum neque nibh nullam pellentesque metus eget eu. Purus porta odio vel hac leo. '
    
    }
]


const HomeTestimonial = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

      
    return(
        <div className="min-h-screen flex flex-col">
            <div className="mx-auto max-w-[50%] text-center">
                <h1 className="p-1 text-[#2E14A6] font-medium text-3xl">Lorem ipsum</h1>
                <p className="p-2 leading-8 tracking-[1px]">Lorem ipsum dolor sit amet consectetur. Commodo mi eleifend fringilla massa penatibus dolor nisl vulputate. Lorem i</p>
            </div>
            {/* <div className="testimonial slider">
                <Slider {...settings}>
                    {data.map((d) => (
                        <div className="flex bg-[#EBF1FF] drop-shadow-lg mt-[6%] max-w-[70%] min-w-[70%] mx-auto p-6">
                            <div className="text-center mx-auto">
                                <img className='mx-auto max-w-[35%] mt-[-100px] py-3' src={d.img} alt="" />
                                <div className="py-1 space-y-2 ">
                                    <h1 className='text-[#2E14A6] text-lg font-medium'>{d.name}</h1>
                                    <p>{d.title}</p>
                                    <p className='leading-7 max-w-[85%] mx-auto py-4'>{d.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div> */}
            
            <div className="w-[70%] bg-[#EBF1FF] rounded-3xl drop-shadow-lg mx-auto mt-12">
                <Slider {...settings}>
                    {data.map((d) => (
                        <div className="px-28">
                            <div className="">
                                <img src={d.img} alt="" className="mx-auto" />
                            </div>
                            <div className="text-center p-4 space-y-6">
                                <div className="space-y-2">
                                    <p>{d.name}</p>
                                    <p>{d.title}</p>
                                </div>
                                <div className="">
                                    <p>{d.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="mx-auto space-x-4 mt-4">
                <button className='border-[#4D45B5] border-2 rounded-full py-[3px] px-[10px] hover:bg-[#4D45B5] hover:text-white hover: transition duration-650 hover:scale-110'>&lt;</button>
                <button className='border-[#4D45B5] border-2 rounded-full py-[3px] px-[10px] hover:bg-[#4D45B5] hover:text-white hover: transition duration-650 hover:scale-110'>&gt;</button>
            </div>
        </div>
    )

}


export default HomeTestimonial;
import BaseLayout from "../../components/BaseLayout"
import SalaryInformation from "../../components/SalaryInformation";

const AccountingSalaryInformation = () => {

    return(
        <BaseLayout title={"Salary Information"}>
            <SalaryInformation />
        </BaseLayout>
    )
}

export default AccountingSalaryInformation;
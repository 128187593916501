import React, { useState } from "react";
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthOutlet from "@auth-kit/react-router/AuthOutlet";
import { MotionConfig } from "framer-motion";
import ProtectedRoute from "./ProtectedRoute";

// Backend imports
import Sidebar from "../backend/components/sidebar";
import Settings from "../backend/pages/Settings";
import UserProfile from "../backend/pages/UserProfile";
import ChangePassword from "../backend/pages/ChangePassword";
import Authentication from "../backend/pages/Authentication";
import ColorTheme from "../backend/pages/ColorTheme";
import HomePage from "../backend/pages/Home";
import AssignTasks from "../backend/pages/Kanban/AssignTasks";
import Error from "../backend/pages/Error/404";

// Principal imports
import DashboardPrincipal from "../backend/pages/Principal/Dashboard1";
import PrincipalAddPeople from "../backend/pages/Principal/AddPeople";
import PrincipalTimeTable from "../backend/pages/Principal/TimeTable";
import NoticeHistory from "../backend/pages/Principal/NoticeHistory";
import Billing from "../backend/pages/Principal/Billing/Billing";
import AddNotice from "../backend/pages/Principal/AddNotice";
import PrincipalTodoList from "../backend/pages/Principal/ToDoList";
import PrincipalStudentProgress from "../backend/pages/Principal/StudentProgress";
import PrincipalExamResult from "../backend/pages/Principal/ExamResult";
import PrincipalAddEvent from "../backend/pages/Principal/AddEvent";
import PrincipalLeaveRequest from "../backend/pages/Principal/LeaveRequest";
import PrincipalLeaveData from "../backend/pages/Principal/LeaveData";
import PrincipalAttendance from "../backend/pages/Principal/Attendance";
import PrincipalSalaryInformation from "../backend/pages/Principal/SalaryInformation";

// Student imports
import Dashboard from "../backend/pages/Student/Dashboard";
import ReadingMaterial from "../backend/pages/Student/ReadingMaterial";
import PastPaper from "../backend/pages/Student/PastPaper";
import Result from "../backend/pages/Student/Result";
import TimeTable from "../backend/pages/Student/TimeTable";
import ToDoList from "../backend/components/ToDoList";
import Assignment from "../backend/pages/Student/Assignment";
import AssignmentDetail from "../backend/pages/Student/AssignmentDetail";

//parent imports
import ResultStudent from "../backend/pages/Parent/ResultStudent";

// Teacher imports
import TeacherDashboard from "../backend/pages/Teacher/TeacherDashboard";
import TeacherReadingMaterial from "../backend/pages/Teacher/ReadingMaterial";
import TeacherPastPaper from "../backend/pages/Teacher/PastPaper";
import TeacherAssignment from "../backend/pages/Teacher/Assignment";
import TeacherAssignmentDetail from "../backend/pages/Teacher/AssignmentDetail";
import TeacherViewSubmission from "../backend/pages/Teacher/ViewSubmission";
import TeacherTodoList from "../backend/pages/Teacher/TodoList";
import TeacherLeaveRequest from "../backend/pages/Teacher/LeaveRequest";
import TeacherTimeTable from "../backend/pages/Teacher/TimeTable";
import TeacherAddNotice from "../backend/pages/Teacher/AddNotice";
import TeacherStudentProgress from "../backend/pages/Teacher/StudentProgress";
import TeacherExamResult from "../backend/pages/Teacher/ExamResult";
import TeacherNoticeHistory from "../backend/pages/Teacher/NoticeHistory";
import TeacherStudentAttendance from "../backend/pages/Teacher/StudentAttendance";
import TeacherSalaryInformation from "../backend/pages/Teacher/SalaryInformation";
import TeacherAddResult from "../backend/pages/Teacher/AddResult";
import AddReadingMaterial from "../backend/components/Teacher/AddReadingMaterial";

// HR imports
import HRDashboard from "../backend/pages/HR/Dashboard";
import HRLeaveRequest from "../backend/pages/HR/LeaveRequest";
import HRAddEvent from "../backend/pages/HR/AddEvent";
import HRTodoList from "../backend/pages/HR/ToDoList";
import HRAddNotice from "../backend/pages/HR/AddNotice";
import HRAttendance from "../backend/pages/HR/Attendance";
import HRNoticeHistory from "../backend/pages/HR/NoticeHistory";
import HRTimeTable from "../backend/pages/HR/TimeTable";
import HRRoutine from "../backend/pages/HR/Routine";
import HRSalaryInformation from "../backend/pages/HR/SalaryInformation";
import HRAddPeople from "../backend/pages/HR/AddPeople";

// Accounting imports
import AccountingDashboard from "../backend/pages/Accounting/AccountingDashboard";
import LeaveRequest from "../backend/pages/Accounting/LeaveRequest";
import ManualJournal from "../backend/pages/Accounting/ManualJournal";
import AccountingToDoList from "../backend/pages/Accounting/ToDoList";
import AccountingAddNotice from "../backend/pages/Accounting/AccountingAddNotice";
import BalanceSheet from "../backend/pages/Accounting/BalanceSheet";
import ProfitLoss from "../backend/pages/Accounting/ProfitLoss";
import AccountingTimeTable from "../backend/pages/Teacher/TimeTable";
import AccountEntry from "../backend/pages/Accounting/AccountEntry";
import SalaryBreakdown from "../backend/pages/Accounting/SalaryBreakdown";
import AccountingSalaryInformation from "../backend/pages/Accounting/SalaryInformation";
import ProfitLossRevenue from "../backend/pages/Accounting/plRevenue";
import ProfitlossExpense from "../backend/pages/Accounting/plExpense";

// Frontend UI imports
import Home from "../frontend/pages/home";
import LoginPage from "../frontend/pages/login";
import RegisterPage from "../frontend/pages/registration";
import Plans from "../frontend/pages/plans";
import Contact from "../frontend/pages/contact";
import Nav from "../frontend/components/nav";
import Footer from "../frontend/components/footer";
import Playground from "../frontend/pages/playground/playground";
import Features from "../frontend/pages/features";
import RegisterOrganization from "../frontend/components/account/register";

// Payment and Misc imports
import PaymentPage from "../backend/components/PaymentPage";
import { Main } from "../backend/components/Esewa/Esewa-Pages"; // Test file
import EsewaForm from "../backend/components/Esewa-API/EsewaForm";
import Success from "../backend/components/Esewa-API/Success";
import Failure from "../backend/components/Esewa-API/Failure";

const BELayout = ({ isOpen, setIsOpen }) => {
  return (
    <MotionConfig transition={{ type: "spring", bounce: 0, duration: 0.4 }}>
      <div className="relative lg:flex h-[100vh]">
        <div className="absolute left-0 top-0 z-20 lg:relative ">
          <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>

        <main
          className={`transition-all duration-300 ease-in-out flex-1 ml-[80px] lg:ml-0 h-screen overflow-y-auto`}
        >
          <Outlet />
        </main>
      </div>
    </MotionConfig>
  );
};

const FELayout = () => {
  return (
    <>
      <Nav />
      <main className={`min-h-[70vh]`}>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

const Routing = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Router>
      <ToastContainer />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Navigate to="/login" replace />} />

        <Route path="/" element={<FELayout />}>
          <Route path="/plans" element={<Plans />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/features" element={<Features />} />
        </Route>
        <Route index path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />

        <Route
          path="/register-organization"
          element={<RegisterOrganization />}
        />

        {/* Authenticated Routes */}
        <Route element={<AuthOutlet fallbackPath="/login" />}>
          <Route
            path="/"
            element={<BELayout isOpen={isOpen} setIsOpen={setIsOpen} />}
          >
            {/* <Route index element={<HomePage />} /> */}

            {/* Principal Routes */}
            <Route element={<ProtectedRoute allowedRoles={["PRINCIPAL"]} />}>
              <Route
                index
                path="/principal/dashboard"
                element={<DashboardPrincipal />}
              />
              <Route
                path="/principal/addPeople"
                element={<PrincipalAddPeople />}
              />
              <Route
                path="/principal/timeTable"
                element={<PrincipalTimeTable />}
              />
              <Route
                path="/principal/noticeHistory"
                element={<NoticeHistory />}
              />
              <Route path="/principal/billing" element={<Billing />} />
              <Route path="/principal/addNotice" element={<AddNotice />} />
              <Route
                path="/principal/toDoList"
                element={<PrincipalTodoList />}
              />
              <Route
                path="/principal/studentProgress"
                element={<PrincipalStudentProgress />}
              />
              <Route
                path="/principal/examResult/:examResultId"
                element={<PrincipalExamResult />}
              />
              <Route
                path="/principal/addEvent"
                element={<PrincipalAddEvent />}
              />
              <Route
                path="/principal/leaveRequest"
                element={<PrincipalLeaveRequest />}
              />
              <Route
                path="/principal/leaveData"
                element={<PrincipalLeaveData />}
              />
              <Route
                path="/principal/attendance"
                element={<PrincipalAttendance />}
              />
              <Route
                path="/principal/salaryInformation"
                element={<PrincipalSalaryInformation />}
              />
            </Route>

            {/* Student Routes */}
            <Route element={<ProtectedRoute allowedRoles={["STUDENT"]} />}>
              <Route index path="/student/dashboard" element={<Dashboard />} />
              <Route
                path="/student/readingMaterial"
                element={<ReadingMaterial />}
              />
              <Route path="/student/pastPaper" element={<PastPaper />} />
              <Route path="/student/result" element={<Result />} />
              <Route path="/student/timeTable" element={<TimeTable />} />
              <Route path="/student/toDoList" element={<ToDoList />} />
              <Route path="/student/assignment" element={<Assignment />} />
              <Route
                path="/student/assignment/:assignmentId"
                element={<AssignmentDetail />}
              />
            </Route>

            {/* Teacher Routes */}
            <Route element={<ProtectedRoute allowedRoles={["TEACHER"]} />}>
              <Route
                index
                path="/teacher/dashboard"
                element={<TeacherDashboard />}
              />
              <Route
                path="/teacher/readingMaterial"
                element={<TeacherReadingMaterial />}
              />
              <Route path="/teacher/pastPaper" element={<TeacherPastPaper />} />
              <Route
                path="/teacher/assignments"
                element={<TeacherAssignment />}
              />
              <Route
                path="/teacher/assignments/:assignmentId"
                element={<TeacherAssignmentDetail />}
              />
              <Route
                path="teacher/viewSubmission/:assignmentId"
                element={<TeacherViewSubmission />}
              />
              <Route path="/teacher/toDoList" element={<TeacherTodoList />} />
              <Route
                path="/teacher/leaveRequest"
                element={<TeacherLeaveRequest />}
              />
              <Route path="/teacher/timeTable" element={<TeacherTimeTable />} />
              <Route path="/teacher/addNotice" element={<TeacherAddNotice />} />
              <Route
                path="/teacher/studentProgress"
                element={<TeacherStudentProgress />}
              />
              <Route
                path="/teacher/examResult/:studentId"
                element={<TeacherExamResult />}
              />
              <Route
                path="/teacher/noticeHistory"
                element={<TeacherNoticeHistory />}
              />
              <Route
                path="/teacher/studentAttendance"
                element={<TeacherStudentAttendance />}
              />
              <Route
                path="/teacher/salaryInformation"
                element={<TeacherSalaryInformation />}
              />
              <Route path="/teacher/addResult" element={<TeacherAddResult />} />
            </Route>

            {/* HR Routes */}
            <Route element={<ProtectedRoute allowedRoles={["HR"]} />}>
              <Route index path="/hr/dashboard" element={<HRDashboard />} />
              <Route path="/hr/leaveRequest" element={<HRLeaveRequest />} />
              <Route path="/hr/addEvent" element={<HRAddEvent />} />
              <Route path="/hr/toDoList" element={<HRTodoList />} />
              <Route path="/hr/addNotice" element={<HRAddNotice />} />
              <Route path="/hr/attendance" element={<HRAttendance />} />
              <Route path="/hr/noticeHistory" element={<HRNoticeHistory />} />
              <Route path="/hr/timeTable" element={<HRTimeTable />} />
              <Route path="/hr/routine" element={<HRRoutine />} />
              <Route
                path="/hr/salaryInformation"
                element={<HRSalaryInformation />}
              />
              <Route path="/hr/addPeople" element={<HRAddPeople />} />
            </Route>

            {/* Accounting Routes */}
            <Route element={<ProtectedRoute allowedRoles={["ACCOUNTANT"]} />}>
              <Route
                index
                path="/accounting/dashboard"
                element={<AccountingDashboard />}
              />
              <Route
                path="/accounting/leaveRequest"
                element={<LeaveRequest />}
              />
              <Route
                path="/accounting/manualJournal"
                element={<ManualJournal />}
              />
              <Route
                path="/accounting/toDoList"
                element={<AccountingToDoList />}
              />
              <Route
                path="/accounting/addNotice"
                element={<AccountingAddNotice />}
              />
              <Route
                path="/accounting/balanceSheet"
                element={<BalanceSheet />}
              />
              <Route path="/accounting/profitLoss" element={<ProfitLoss />} />
              <Route
                path="/accounting/timeTable"
                element={<AccountingTimeTable />}
              />
              <Route
                path="/accounting/accountEntry"
                element={<AccountEntry />}
              />
            </Route>

            <Route
              path="/accounting/SalaryBreakdown"
              element={<SalaryBreakdown />}
            />

            <Route
              path="/accounting/SalaryInformation"
              element={<AccountingSalaryInformation />}
            />
            <Route
              path="/accounting/plRevenue"
              element={<ProfitLossRevenue />}
            />
            <Route
              path="/accounting/plExpense"
              element={<ProfitlossExpense />}
            />

            {/* Parents Routes */}
            <Route element={<ProtectedRoute allowedRoles={["PARENT"]} />}>
              <Route index path="/parents/dashboard" element={<Dashboard />} />
              <Route
                path="/parents/parentStudentResult"
                element={<ResultStudent />}
              />
              <Route path="/parents/timeTable" element={<TimeTable />} />
            </Route>

            {/* General Settings */}
            <Route path=":role/settings" element={<Settings />} />
            <Route
              path=":role/settings/userProfile"
              element={<UserProfile />}
            />
            <Route
              path=":role/settings/changePassword"
              element={<ChangePassword />}
            />
            <Route
              path=":role/settings/authentication"
              element={<Authentication />}
            />
            <Route path=":role/settings/colorTheme" element={<ColorTheme />} />
          </Route>
        </Route>

        {/* Public Routes */}
        <Route path="/view" element={<FELayout />}>
          <Route index element={<Home />} />

          <Route path="/view/plans" element={<Plans />} />
          <Route path="/view/contactUs" element={<Contact />} />
          <Route path="/view/features" element={<Features />} />
        </Route>

        {/* Fallback route for 404 */}
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
};

export default Routing;

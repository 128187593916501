import React, { useState } from "react";
import Layout from "../../components/Dashboard/Layout";
import CalendarDashboard from "../../components/Dashboard/CalendarDashboard";
import NoticeDashboard from "../../components/Dashboard/NoticeDashboard";
import DashboardChart from "../../components/Principal/DashboardChart";
import DashboardImage from "../../../assets/images/students.png";
import { PiStudent, PiUsersThree } from "react-icons/pi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { LuUsers2 } from "react-icons/lu";
import useAxios from "../../../utils/axios/useAxios";

const Dashboard = () => {
  const { data: students, error } = useAxios(`classrooms/students/`);
  const { data: teachers } = useAxios(`classrooms/teachers/`);
  const { data: users } = useAxios(`users/users/`);

  const [activeStat, setActiveStat] = useState({
    name: "Students",
    icon: <PiStudent />,
    total: students.length,
    present: 300,
  });

  const stats = [
    {
      name: "Students",
      icon: <PiStudent className="text-[#4D45B5] w-8 h-8" />,
      total: students.length,
      present: 300,
      backgroundColor: "#EBEAFF",
    },
    {
      name: "Teaching Staff",
      icon: <FaChalkboardTeacher className="text-[#EFAA77] w-8 h-8" />,
      total: teachers.length,
      present: 300,
      backgroundColor: "#FFF7F1",
    },
    {
      name: "Management",
      icon: <LuUsers2 className="text-[#EFAA77] w-8 h-8" />,
      total: 1400,
      present: 300,
      backgroundColor: "#FFF7F1",
    },
    {
      name: "Faculty",
      icon: <PiUsersThree className="text-[#4D45B5] w-8 h-8" />,
      total: users.length,
      present: 300,
      backgroundColor: "#EBEAFF",
    },
  ];
  const handleStat = (stat) => {
    setActiveStat(stat);
  };

  return (
    <Layout>
      <div className=" grid xl:grid-cols-[25%_37.5%_37.5%] sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 pb-4 mr-2">
        <div className="grid grid-cols-2 gap-3">
          {stats.map((stat, index) => (
            <div
              className="shadow-sm p-4 grid justify-items-center text-gray-600 text-sm font-medium rounded-lg"
              key={index}
              onClick={() => handleStat(stat)}
              style={{ backgroundColor: stat.backgroundColor }}
            >
              <div>{stat.icon}</div>
              <p className="text-nowrap mt-2">{stat.name}</p>
              <p>{stat.total}</p>
            </div>
          ))}
        </div>
        <div className="shadow-lg p-8 bg-white rounded-lg relative">
          <div className="z-10">
            <h1 className="font-medium text-xl mb-2">{activeStat.name}</h1>
            <select className="text-gray-500 text-[12px] bg-transparent border-none mb-3 -ml-2.5">
              <option selected>12-10-2024</option>
            </select>
            <div className="grid grid-cols-2 gap-4 text-gray-700 font-medium text-base">
              <div className="col-span-2">
                <p>Total {activeStat.name}</p>
                <p>{activeStat.total}</p>
              </div>
              <div>
                <p>Present {activeStat.name}</p>
                <p>{activeStat.present}</p>
              </div>
              <div>
                <p>Absent {activeStat.name}</p>
                <p>{activeStat.total - activeStat.present}</p>
              </div>
            </div>
          </div>
          <img
            src={DashboardImage}
            alt="student"
            className="absolute top-0 right-0 h-full object-cover rounded-tr-lg"
          />
        </div>

        <div className="shadow-md p-4 rounded-lg bg-white">
          <select className="font-medium w-max text-base mb-1 bg-transparent border-none">
            <option value="Faculty Progress" selected>
              Faculty Progress
            </option>
            <option value="Student Progress">Student Progress</option>
            <option value="Teacher Progress">Teacher Progress</option>
            <option value="Management Progress">Management Progress</option>
          </select>
          <div className="container mx-auto">
            <DashboardChart />
          </div>
        </div>
      </div>

      <div className=" grid xl:grid-cols-[24%_78%] md:grid-cols-2 gap-5">
        <CalendarDashboard />
        <NoticeDashboard />
      </div>
    </Layout>
  );
};

export default Dashboard;

import React from "react";

const Playground = () => {
  return (
    <div className="text-center pt-20 font-bold uppercase text-2xl text-primary">
      Playground
    </div>
  );
};

export default Playground;

import TermsAndConditions from "./TermsAndConditions";
import { act, useState } from "react";
import OTP from "./OTP";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // for CircularProgressbar styling
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import CompanyInformation from "./CompanyInformation";

const SubscriptionPlan = ({setIndex}) => {

  const BASIC_PLAN = 0;
  const PREMIUM_PLAN = 1;
  const CUSTOM_PLAN = 2;

  const[activeComponent, setActiveComponent] = useState("");

  const [selectedPackage, setSelectedPackage] = useState(BASIC_PLAN);
  

  const [showTerms, setShowTerms] = useState(false);

  const [showOTP, setShowOTP] = useState(false);

  const handleCloseTerms = () => {
    setShowOTP(false);
    setShowTerms(false);
  };
  const handleNext = () => {
    setShowTerms(false);
    setShowOTP(true);
  };

  

  const packages = [
    {
      title: "Basic Plan",
      description:
        "Lorem ipsum dolor sit amet consectetur. Libero eros id semper tristique purus nulla scelerisque aliquam cras. Et libero enim mauris tellus. Id sollicitudin pretium sit iaculis sit. Velit porta magna elementum tellus scelerisque placerat. Consectetur amet duis semper mi tortor dui volutpat non volutpat. Risus nascetur ultrices vel amet. Tempor odio vitae blandit pellentesque elit ligula quam. Lectus tempor nisi cras sed fames tristique. Mollis aliquam amet ullamcorper semper ullamcorper in egestas. Mattis est.",
      features: [
        "Access to basic features",
        "Up to 5 GB storage",
        "Email support",
        "Basic analytics",
        "Limited customization options",
        "Regular updates",
        "Access to community forum",
      ],
    },
    {
      title: "Premium Plan",
      description:
        "Lorem ipsum dolor sit amet consectetur. Libero eros id semper tristique purus nulla scelerisque aliquam cras. Et libero enim mauris tellus. Id sollicitudin pretium sit iaculis sit. Velit porta magna elementum tellus scelerisque placerat. Consectetur amet duis semper mi tortor dui volutpat non volutpat. Risus nascetur ultrices vel amet. Tempor odio vitae blandit pellentesque elit ligula quam. Lectus tempor nisi cras sed fames tristique. Mollis aliquam amet ullamcorper semper ullamcorper in egestas. Mattis est.",
      features: [
        "Access to all basic features",
        "Up to 50 GB storage",
        "Priority email and phone support",
        "Advanced analytics and reporting",
        "Full customization options",
        "Regular updates and new features",
        "Access to premium resources",
      ],
    },
    {
      title: "Custom Plan",
      description:
        "Lorem ipsum dolor sit amet consectetur. Libero eros id semper tristique purus nulla scelerisque aliquam cras. Et libero enim mauris tellus. Id sollicitudin pretium sit iaculis sit. Velit porta magna elementum tellus scelerisque placerat. Consectetur amet duis semper mi tortor dui volutpat non volutpat. Risus nascetur ultrices vel amet. Tempor odio vitae blandit pellentesque elit ligula quam. Lectus tempor nisi cras sed fames tristique. Mollis aliquam amet ullamcorper semper ullamcorper in egestas. Mattis est.",
      features: [
        "Fully customizable features",
        "Unlimited storage",
        "Dedicated account manager",
        "Custom analytics and reporting",
        "24/7 support",
        "Custom integrations",
        "Regular updates with personalized options",
      ],
    },
  ];

  const userCount = 427;
  const allowedUsers = 500;
  const percentage = (userCount / allowedUsers) * 100;

  const chartColor = () => {
    if (userCount < 300) return "#4CAF50"; // Green
    if (userCount <= 400) return "#FFC107"; // Yellow
    return "#F44336"; // Red
  };

  const data = {
    datasets: [
      {
        data: [userCount, allowedUsers - userCount],
        backgroundColor: [chartColor(), "#E5E7EB"], // #E5E7EB for light gray
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const usedCapacity = 3; // 5 GB used
  const maxCapacity = 10; // 10 GB total

  const barChartColor = () => {
    if (usedCapacity <= 5) return "#4CAF50"; // Green
    if (usedCapacity <= 8) return "#FFC107"; // Yellow
    return "#F44336"; // Red
  };

  const barData = {
    labels: ["Storage"],
    datasets: [
      {
        label: "Used Capacity",
        data: [usedCapacity],
        backgroundColor: barChartColor(),
        borderRadius: 4, // Rounded corners for the bar
        barThickness: 20, // Thicker bar
      },
      {
        label: "Remaining Capacity",
        data: [maxCapacity - usedCapacity],
        backgroundColor: "#E5E7EB", // Light gray for remaining capacity
        borderRadius: 4,
        barThickness: 20,
      },
    ],
  };

  const barOptions = {
    indexAxis: "y", // Horizontal bar chart
    scales: {
      x: {
        max: maxCapacity,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false, // Hide x-axis labels
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  if(activeComponent === "CompanyInformation")
    return <CompanyInformation />


  const handleGoBack = () =>{
    //setActiveComponent("OwnerInformation")
    setIndex(2);
    console.log(setIndex)
}

  return (

    <div className="mt-16 px-8">
      <div className="flex flex-row justify-between space-y-4">
        <h1 className="font-semibold text-[#4D45B5] text-lg tracking-wider text-center mt-auto">
          SUBSCRIPTION PLANS
        </h1>

        <div className="flex justify-center items-center space-x-2">
          {/* Storage Capacity */}
          <div className="flex flex-col items-center p-1 bg-white rounded-lg shadow-md w-40 h-20">
            <h2 className="text-xs font-medium text-gray-700 mb-1">
              Storage Capacity
            </h2>
            <div className="flex flex-row items-center justify-between w-full px-2">
              <div className="relative w-10 h-10">
                <CircularProgressbar
                  value={(usedCapacity / maxCapacity) * 100}
                  text={`${usedCapacity}GB`}
                  styles={buildStyles({
                    textSize: "24px",
                    pathColor: "#4D45B5",
                    textColor: "#4D45B5",
                    trailColor: "#e0e0e0",
                  })}
                />
              </div>

              <p className="text-[16px] text-gray-600 text-center ml-2">

                <span className="font-bold text-gray-800">{usedCapacity} GB</span>/
                <span className="font-bold text-gray-800">{maxCapacity} GB</span>{" "}
              </p>
            </div>
          </div>

          {/* User Capacity */}
          <div className="flex flex-col items-center p-1 bg-white rounded-lg shadow-md w-40 h-20">
            <h2 className="text-xs font-medium text-gray-700 mb-1">
              User Capacity
            </h2>
            <div className="flex flex-row items-center justify-around w-full px-2">
              <div className="relative w-10 h-10">
                <Doughnut data={data} options={options} />
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                  
                </div>
              </div>
              <p className="text-[16px] text-gray-600 text-center ml-2">

                <span className="font-bold text-gray-800">{userCount}</span>/
                <span className="font-bold text-gray-800">{allowedUsers}</span>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-6 space-x-8">
        {packages.map((pkg, index) => (
          <div
            key={index}
            onClick={() => setSelectedPackage(index)}
            className={`w-[33%] border-2 py-8 px-6 rounded-3xl cursor-pointer ${
              selectedPackage === index ? "border-[#4D45B5]" : ""
            }`}
          >
            <div className="flex justify-between items-center">
              <h1 className="text-xl font-semibold opacity-75">
                {index === 0
                  ? "NRS 900.00"
                  : index === 1
                  ? "NRS 1000.00"
                  : "NRS XXXX"}
              </h1>
              <button
                className={`border-2 py-1 px-6 rounded-3xl font-semibold opacity-75 text-sm ${
                  selectedPackage === index
                    ? "bg-[#4D45B5] text-white border-none"
                    : ""
                }`}
              >
                {pkg.title.split(" ")[0]}
              </button>
            </div>
            <p className="mt-4 text-sm text-justify opacity-70 leading-[23px]">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Assumenda odio natus iure doloremque
            </p>
          </div>
        ))}
      </div>

      <div className="mt-12 border-2 py-6 rounded-3xl px-10">
        <h2 className="font-semibold text-[#4D45B5] text-xl">
          {packages[selectedPackage].title}
        </h2>
        <p className="mt-4 text-sm leading-[23px] opacity-75 text-justify">
          {packages[selectedPackage].description}
        </p>
        <ul className="mt-4 list-disc list-inside text-sm">
          {packages[selectedPackage].features.map((feature, featureIndex) => (
            <li key={featureIndex} className="opacity-75">
              {feature}
            </li>
          ))}
        </ul>
        {selectedPackage === CUSTOM_PLAN && (
            <div className="mt-16">
            <div className="space-y-4">
              <h1 className="font-semibold text-[#4D45B5] text-xl">For Further Detail Please Contact us</h1>
              <p className="text-sm leading-[23px] opacity-75 text-justify">Lorem ipsum dolor sit amet consectetur. Sed id tempor ullamcorper at erat ornare egestas ut ornare. Commodo aliquam vestibulum nec mattis quis amet in diam ut. Amet interdum eget feugiat vestibulum ac pellentesque volutpat eu. </p>
            </div>

            <div className="mt-8">
              <div className="flex">
                  <div className="w-[33%] flex flex-col mt-6 space-y-8">
                      <div>
                          <label className="w-[95%] pl-3 opacity-70" htmlFor="firstName">First Name</label>
                          <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="firstName" placeholder="Cheeze-itz" />
                      </div>

                      <div>
                          <label className="w-[95%] pl-3 opacity-70" htmlFor="organizationName">Organization Name</label>
                          <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="organizationName" placeholder="Octacore Problems Pub.ultd" />
                      </div>
                  </div>

                  <div className="w-[33%] mt-6 space-y-8">
                      <div>
                          <label className="w-[95%] pl-3 opacity-70" htmlFor="middleName">Middle Name</label>
                          <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="middleName" placeholder="William" />
                      </div>

                      <div>
                          <label className="w-[95%] pl-3 opacity-70" htmlFor="location">Location</label>
                          <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="location" placeholder="Koteshwor" />
                      </div>
                  </div>

                  <div className="w-[33%] mt-6 space-y-8">
                      <div>
                          <label className="w-[95%] pl-3 opacity-70" htmlFor="lastName">Last Name</label>
                          <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="lastName" placeholder="Aryal" />
                      </div>

                      <div>
                          <label className="w-[95%] pl-3 opacity-70" htmlFor="email">Contact Email</label>
                          <input className="w-[95%] mt-1 rounded-md opacity-80" type="email" id="email" placeholder="devandsiu1969@gmail.com" />
                      </div>
                  </div>

              </div>

              <div className="w-[33%] mt-7">
                <label className="pl-3 opacity-70" htmlFor="contactNo">Contact Number</label>
                <div className="flex">
                    <select className="w-[29%] mt-1 rounded-md opacity-80 border-r-0 rounded-r-none" id="Country">
                        <option value="Nepal">+977</option>
                        <option value="India">+91</option>
                        <option value="China">+92</option>
                        <option value="Bangladesh">+975</option>
                        <option value="Pakistan">+880</option>
                        <option value="Bhutan">+86</option>
                    </select>
                    <input className="mt-1 rounded-md opacity-80 border-l-0 rounded-l-none w-[66%]" type="tel" id="contactNo" placeholder="9801294712" />
                </div>
              </div>
              <div className="mt-7">
                <label className="opacity-70" htmlFor="description">Description</label>
              </div>
              <div className="mt-2">
                <textarea className="w-[99%] h-[10rem] p-4 opacity-80" placeholder="Description" name="" id="description"></textarea>
              </div>
            </div>
        </div>
        )}
      </div>

      <div className="space-x-4 mt-8 w-[35%] ml-[66%]">
        <button onClick={handleGoBack}
        className="px-4 py-2 text-[#4D45B5] w-[11rem] border-2 border-[#4D45B5] font-medium tracking-wide rounded-lg hover:bg-[#4D45B5] hover:text-white hover:ease-in-out hover:transition duration-[100ms]">
          Go Back
        </button>
        <button
          onClick={() => setShowTerms(true)}
          className="px-4 py-2 bg-[#4D45B5] w-[11rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
        >
          Next
        </button>
      </div>

      {showTerms && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex items-center justify-center">
          <div className="relative bg-white p-6 rounded-xl shadow-lg z-60">
            <TermsAndConditions
              onClose={(() => setShowTerms(false)) || (() => handleCloseTerms)}
              onNext={handleNext}
              onNex
            />
          </div>
        </div>
      )}

      {showOTP && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex items-center justify-center">
          <div className="relative bg-white p-6 rounded-xl shadow-lg z-60">
            <OTP onClose={handleCloseTerms} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPlan;

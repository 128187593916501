import './css/ArcMovement.css';

const Herosection = () => {

    return(
      <div className="min-h-screen flex relative -mt-[12vh]">
        {/* <div className="absolute right-0 top-0 max-w-[50%]">
          <svg width="1165" height="925" viewBox="0 0 1165 925" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M177.2 -32H1334V770.404C1334 770.404 1246.13 822.31 1184.3 844.995C1096.54 877.189 1032.92 900.65 939.327 913.406C796.139 932.921 720.656 925.612 577.219 907.669C457.753 892.723 385.724 882.423 281.215 827.781C162.106 765.506 95.3926 712.276 33.329 600.919C-92.1753 375.735 177.2 -32 177.2 -32Z" fill="#DCDCF8"/>
            </svg>
        </div> */}

        <div
            className ="absolute ml-[25rem] mt-[16rem]"
            style={{transformOrigin: 'center center',
              animation: `moveAlongArc 10s ease-in-out infinite`
            }}>
            <svg className="z-0 " width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17" cy="17" r="14" fill="#ffffff" stroke="#D5D3F3" stroke-width="6"/>
            </svg>
        </div>

        <div className="absolute mt-[42rem]"
        style={{transformOrigin: 'center center',
          animation: `moveAlongArc 8s ease-in-out infinite`
        }}>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17" cy="17" r="14" stroke="#F9EAD8" stroke-width="6"/>
            </svg>
        </div>

        <div className="relative mt-[27rem]">
            <svg className="absolute -left-12" width="200" height="350" viewBox="0 0 172 437" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-82.0136 0.958406L-269.661 229.175L-17.0627 436.87C-17.0627 436.87 13.5319 432.97 30.7024 426.643C55.0721 417.665 72.7777 411.186 91.9758 396.023C121.346 372.826 131.289 356.042 148.908 323.1C163.582 295.663 172.023 278.787 171.775 244.026C171.491 204.408 165.556 177.469 140.568 136.401C90.0383 53.3542 -82.0136 0.958406 -82.0136 0.958406Z" fill="#F2E1D2"/>
            </svg>
        </div>
        <div className="absolute flex space-x-1 mt-4">
            <svg className="ml-12 mt-32" width="37" height="25" viewBox="0 0 37 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.21058 28.75L18.5 4L32.7894 28.75H4.21058Z" stroke="#F2E1D2" stroke-width="4"/>
            </svg>
            <svg className="mt-28" width="54" height="50" viewBox="0 0 54 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.13119 57.9962L2.36935 3.44187L49.9957 30.0592L3.13119 57.9962Z" stroke="#F2E1D2" stroke-width="4"/>
            </svg>
        </div>
        <div className="absolute ml-[45%] mt-[34rem]">
            <svg className="mt-24" width="59" height="50" viewBox="0 0 59 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.80239 57.2651L13.6719 3.79918L54.54 39.9454L2.80239 57.2651Z" stroke="#F2E1D2" stroke-width="4"/>
            </svg>

        </div>
        <div className="flex gap-x-16 px-14 z-10">
          <div className="max-w-[40%] min-w-[40%] my-auto">
            <div className="text-4xl tracking-wide font-semibold leading-relaxed">
              <h1 className='z-10'>Unlock new horizons of
              Education</h1>
            </div>
            <p className="mt-4 text-[16px] tracking-wide text-justify">Lorem ipsum dolor sit amet consectetur. Egestas eu volutpat potenti tellus viverra ultrices facilisis arcu. Ipsum elementum purus tellus cras morbi malesuada. Lorem</p>
            <div className="mt-6">
              <button className="mr-[25px] min-w-[201px] min-h-[30px] justify-center bg-[#4D45B5] p-[12px] rounded-[8px] text-white font-medium text-[12px] tracking-[1px] hover: transition duration-675 hover:scale-105">Learn More</button>
              <button className="min-w-[201px] min-h-[30px] justify-center border border-[#4D45B5] bg-white p-[12px] rounded-[8px] text-[#4D45B5] font-medium text-[12px] tracking-[1px] hover: transition duration-675 hover:scale-105">Contact Us</button>
            </div>
          </div>
          <div className="w-[60%] px-6">
            <img className="h-[95%] mt-12" src={require("../../../assets/images/home/homeHeroSection.png")} alt="" />
          </div>
        </div>
        
      </div>
    )
  }

// const Herosection = () => {
//   return(
//     <div className="relative min-h-[90vh]">
      
//          <div
//              className ="absolute ml-[25rem] mt-[12rem] -z-10"
//              style={{transformOrigin: 'center center',
//                animation: `moveAlongArc 10s ease-in-out infinite`
//              }}>
//              <svg className="z-0 " width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
//              <circle cx="17" cy="17" r="14" fill="#ffffff" stroke="#D5D3F3" stroke-width="6"/>
//              </svg>
//          </div>

//          <div className="absolute mt-[32rem]"
//          style={{transformOrigin: 'center center',
//            animation: `moveAlongArc 8s ease-in-out infinite`
//          }}>
//              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
//              <circle cx="17" cy="17" r="14" stroke="#F9EAD8" stroke-width="6"/>
//              </svg>
//          </div>
      
//       <div className="flex space-x-8">

//         <div className="absolute -left-24 -z-10 mt-72">
//         <svg width="200" height="460" viewBox="0 0 172 437" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path d="M-82.0136 0.958406L-269.661 229.175L-17.0627 436.87C-17.0627 436.87 13.5319 432.97 30.7024 426.643C55.0721 417.665 72.7777 411.186 91.9758 396.023C121.346 372.826 131.289 356.042 148.908 323.1C163.582 295.663 172.023 278.787 171.775 244.026C171.491 204.408 165.556 177.469 140.568 136.401C90.0383 53.3542 -82.0136 0.958406 -82.0136 0.958406Z" fill="#F2E1D2"/>
//         </svg>

//         </div>
//         <div className="w-[45%] space-y-4 mt-44 tracking-wider pl-8">
//             <h1 className='text-5xl font-semibold leading-[65px]'>Unlock new horizons of Education.</h1>
//             <p className='w-[85%] text-justify leading-[30px] text-lg font-medium'>Lorem ipsum dolor sit amet consectetur. Egestas eu volutpat potenti tellus viverra ultrices facilisis arcu. Ipsum elementum purus tellus cras morbi malesuada. Lorem</p>
//         </div>

//         <div className="w-[55%] scale-110 -mt-16 -mr-12">
//           <img src={require("../../../assets/images/home/homeHeroSectionCombinedImage.png")} alt="" />
//         </div>
//       </div>
//     </div>
//   )
// }
   
  export default Herosection;
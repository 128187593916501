import React from "react";
import ToDoList from "../../components/ToDoList";
import BaseLayout from "../../components/BaseLayout";

const TeacherTodo = () => {
  return (
    <BaseLayout title={"To-Do List"}>
      <ToDoList></ToDoList>
    </BaseLayout>
  );
};

export default TeacherTodo;

// App.js
import React, { useRef } from "react";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame
} from "framer-motion";
import { wrap } from "@motionone/utils";
import "./error.css";

function ParallaxText({ children, baseVelocity = 100 }) {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });

  // This is a magic wrapping for the length of the text
  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

  const directionFactor = useRef(1);
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

    // This changes the direction of the scroll once we switch scrolling directions.
    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get();

    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax">
      <motion.div className="scroller" style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>

    </div>
  );
}

// also added the section1 name to section which was originally supposed to be here

export default function App() {
  return (
    <section className="section1">
      <ParallaxText baseVelocity={-5} className="heading">404. Not Found</ParallaxText>
      
      <ParallaxText baseVelocity={5} className="heading">404. Not Found</ParallaxText>

      <a href="/view/home" className="hover:underline"><h1 className="heading w-[30vw] mx-auto text-center py-20 duration-300 ease-in-out hover:-translate-y-2"> GO BACK </h1></a>
    </section>
  );
}

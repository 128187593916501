const Homepackages = () => {


    const packageData = [
        {
        price: "Free",
        title: "Basic",
        features: [
            "Lorem ipsum dolor sit amet consectetur. Neque id.",
            "Lorem ipsum dolor sit amet consectetur. Nunc luctus.",
            "Lorem ipsum dolor sit amet consectetur. Eu integer.",
            "Lorem ipsum dolor sit amet consectetur. Faucibus.",
            "Lorem ipsum dolor sit amet consectetur. Lorem morbi.",
        ]
        },

        {
        price: "NPR 999",
        title: "Premium",
        features: [
            "Lorem ipsum dolor sit amet consectetur. Neque id.",
            "Lorem ipsum dolor sit amet consectetur. Nunc luctus.",
            "Lorem ipsum dolor sit amet consectetur. Eu integer.",
            "Lorem ipsum dolor sit amet consectetur. Faucibus.",
            "Lorem ipsum dolor sit amet consectetur. Lorem morbi.",
        ]
        },

        {
        price: "NPR 500",
        title: "Custom",
        features: [
            "Lorem ipsum dolor sit amet consectetur. Neque id.",
            "Lorem ipsum dolor sit amet consectetur. Nunc luctus.",
            "Lorem ipsum dolor sit amet consectetur. Eu integer.",
            "Lorem ipsum dolor sit amet consectetur. Faucibus.",
            "Lorem ipsum dolor sit amet consectetur. Lorem morbi.",
        ]
        }

    ];

    return(
        <div className="">
            <div className="p-[4%] flex">
                <div className="min-w-[50%] max-w-[50%] space-y-[1%]">
                    <h1 className="text-[2rem] text-[#4D45B5] font-medium">Lorem Ipsum</h1>
                    <p className="max-w-[75%] min-w-[40%] text-justify">Lorem ipsum dolor sit amet consectetur. Elit lacinia ultrices pretium diam. Et nisi morbi viverra malesuada ultrices cras amet nulla.</p>
                </div>
                <div className="min-w-[50%] max-w-[50%] h-[100%] text-right self-start mt-[3%]">
                    <button className="font-medium text-[1rem] border-[1px] border-[#4D45B5] p-[1%] min-w-[30%] rounded-[8px] text-[#4D45B5] hover:bg-[#4D45B5] hover:text-white hover: transition duration-675 hover:scale-105">Learn More</button>
                </div>
            </div>
            <div className="min-w-[95%] mx-auto flex gap-6 px-16 justify-center">
                <div className="min-w-[33%] max-w-[33%] px-10 bg-slate-300">
                    
                    
                </div>
                <div className="min-w-[33%] max-w-[33%] px-12 bg-red-300">b</div>
                <div className="min-w-[33%] max-w-[33%] px-12 bg-blue-200">c</div>
            </div>
            
        </div>
        
    )
  }
   
  export default Homepackages;
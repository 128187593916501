import React from "react";
import BaseLayout from "../../components/BaseLayout";
import SalaryInformation from "../../components/SalaryInformation";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const PrincipalSalaryInformation = () => {
  const user = useAuthUser();
  console.log(user);

  return (
    <BaseLayout title={"Salary Information"}>
      <SalaryInformation />
    </BaseLayout>
  );
};

export default PrincipalSalaryInformation;

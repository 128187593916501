import React from "react";
import BaseLayout from "../../components/BaseLayout";
import AttendanceChart from "../../components/Attendance/AttendanceChart";
import AttendanceList from "../../components/Attendance/AttendanceList";

const Attendance = () => {
  return (
    <BaseLayout>
      <AttendanceChart />
      <AttendanceList />
    </BaseLayout>
  );
};

export default Attendance;

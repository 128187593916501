import React, { useState } from "react";
import BaseLayout from "../../components/BaseLayout";
import PrincipalAddAccountant from "../../components/Principal/AddAccountant";
import PrincipalAddHr from "../../components/Principal/AddHr";

const PrincipalAddPeople = () => {
  const [selectedForm, setSelectedForm] = useState("accountant");

  const renderForm = () => {
    if (selectedForm === "accountant") {
      return <PrincipalAddAccountant />;
    } else if (selectedForm === "hr") {
      return <PrincipalAddHr />;
    }
  };

  return (
    <BaseLayout title={"Add Staff"}>
      <div className="flex space-x-8 mb-4">
        <button
          className={` text-lg ${
            selectedForm === "accountant" ? "font-bold underline" : ""
          }`}
          onClick={() => setSelectedForm("accountant")}
        >
          Accountant
        </button>
        <button
          className={` text-lg ${
            selectedForm === "hr" ? "font-bold underline" : ""
          }`}
          onClick={() => setSelectedForm("hr")}
        >
          HR
        </button>
      </div>
      {renderForm()}
    </BaseLayout>
  );
};

export default PrincipalAddPeople;

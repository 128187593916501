import React, { useState } from "react";
import BaseLayout from "../../components/BaseLayout";
import HRAddTeacher from "../../components/HR/AddTeacher";
import HRAddStudent from "../../components/HR/AddStudent";

const HRAddPeople = () => {
  const [selectedForm, setSelectedForm] = useState("teacher");

  const renderForm = () => {
    if (selectedForm === "teacher") {
      return <HRAddTeacher />;
    } else if (selectedForm === "student") {
      return <HRAddStudent />;
    }
  };

  return (
    <BaseLayout title={"Add Staff"}>
      <div className="flex space-x-8 mb-4">
        <button
          className={` text-lg ${
            selectedForm === "teacher" ? "font-bold underline" : ""
          }`}
          onClick={() => setSelectedForm("teacher")}
        >
          Teacher
        </button>
        <button
          className={` text-lg ${
            selectedForm === "student" ? "font-bold underline" : ""
          }`}
          onClick={() => setSelectedForm("student")}
        >
          Student
        </button>
      </div>
      {renderForm()}
    </BaseLayout>
  );
};

export default HRAddPeople;

import { useState } from "react";

const Homesolutions = () => {
    const components = [
        {
            name: "Cloud-Based Solution",
            description : "Lorem ipsum dolor sit amet consectetur. Commodo mi eleifend fringilla massa penatibus dolor nisl vulputate. At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula",

        },
        {
            name: "Hello Cheez-itz",
            description : "ipsum dolor sit amet consectetur. Commodo mi eleifend fringilla massa penatibus dolor nisl vulputate. At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula",
        },
        {
            name: "Hello Devanshu",
            description : "amet consectetur. Commodo mi eleifend fringilla massa penatibus dolor nisl vulputate. At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula",
        },
        {
            name: "Hello Krity Didi",
            description : "dolor sit amet consectetur. Commodo mi eleifend fringilla massa penatibus dolor nisl vulputate. At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula",
        },
    ];

    const[selectedComponent, setSelectedComponent] = useState(components[0].name);

    const handleComponentClick = (componentName) => {
        setSelectedComponent(componentName)
    }

    const selectedDescription = components.find(
        (component) => component.name === selectedComponent
    )?.description;

    return(
        <div className="w-[90%] flex mx-auto relative">

            {/* <div className="absolute flex space-x-2">
                <svg className="mt-[34rem] ml-[56rem]" width="39" height="42" viewBox="0 0 39 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.75092 18.337L36.059 4.02287L29.4851 37.8145L4.75092 18.337Z" stroke="#F2E1D2" stroke-width="4"/>
                </svg>
                <svg className="mt-[33rem]" width="59" height="61" viewBox="0 0 59 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.06411 57.5288L13.9336 4.06285L54.8017 40.209L3.06411 57.5288Z" stroke="#F2E1D2" stroke-width="4"/>
                </svg>
            </div> */}

            <div className="absolute ml-[70rem] mt-12"
                style={{transformOrigin: 'center center',
                animation: `moveAlongArc 10s ease-in-out infinite`
            }}>
                <svg className="z-0" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17" cy="17" r="14" stroke="#F9EAD8" stroke-width="6"/>
                </svg>
            </div>

            <div className="min-w-[50%] max-w-[50%] flex flex-wrap tracking-[1px]">
                <div onClick={() => handleComponentClick("Cloud-Based Solution")} 
                className="min-w-[40%] max-w-[40%] px-[4%] py-[4%] bg-[#D5D3F3] ml-[4%] border-1 rounded-[8%] self-start drop-shadow-lg cursor-pointer hover: transition duration-675 hover:scale-105">
                    <h1 className="mt-[5%] text-[#584CF9] font-extrabold">Cloud-Based Solution</h1>
                    <p className="mt-[5%] text-justify">Lorem ipsum dolor sit amet consectetur. Enim dis non eleifend non dignissim placerat nunc duis bibendum. Cursus.</p>
                </div>
                

                <div onClick={() => handleComponentClick("Hello Cheez-itz")} 
                className="min-w-[40%] max-w-[40%] px-[4%] py-[4%] ml-[8%] mt-[8%] bg-[#F2E1D2] border-1 rounded-[8%] self-start drop-shadow-lg cursor-pointer hover: transition duration-675 hover:scale-105">
                    <h1 className="mt-[5%] text-[#E6A23A] font-extrabold">Hello Cheez-itz</h1>
                    <p className="mt-[5%] text-justify">Lorem ipsum dolor sit amet consectetur. Enim dis non eleifend non dignissim placerat nunc duis bibendum. Cursus.</p>
                </div>

                <div onClick={() => handleComponentClick("Hello Devanshu")}
                 className="min-w-[40%] max-w-[40%] px-[4%] py-[4%] ml-[4%] bg-[#F2E1D2] mt-[2%] border-1 rounded-[8%] self-start drop-shadow-lg cursor-pointer hover: transition duration-675 hover:scale-105">
                    <h1 className="mt-[5%] text-[#E6A23A] font-extrabold">Hello Devanshu</h1>
                    <p className="mt-[5%] text-justify">Lorem ipsum dolor sit amet consectetur. Enim dis non eleifend non dignissim placerat nunc duis bibendum. Cursus.</p>
                </div>

                <div onClick={() => handleComponentClick("Hello Krity Didi")}
                 className="min-w-[40%] max-w-[40%] px-[4%] py-[4%] ml-[12%] bg-[#D5D3F3] mt-[8%] border-1 rounded-[8%] self-start drop-shadow-lg cursor-pointer hover: transition duration-675 hover:scale-105">
                    <h1 className="mt-[5%] text-[#584CF9] font-extrabold">Hello Krity Didi</h1>
                    <p className="mt-[5%] text-justify">Lorem ipsum dolor sit amet consectetur. Enim dis non eleifend non dignissim placerat nunc duis bibendum. Cursus.</p>
                </div>

            </div>
            
            <div className="min-w-[50%] max-w-[50%] ml-[1.5rem] z-10">
                <div className="py-[2%] flex flex-col tracking-[1.5px] space-y-[4%] mt-[12%] leading-[1.9rem] text-justify">
                    <h1 className="text-[2rem] font-medium">{selectedComponent}</h1>
                    <p className="text-[1rem]">{selectedDescription}</p>
                    <button className="self-start font-medium text-[1rem] border-[1px] border-[#4D45B5] p-[0.5%] min-w-[25%] rounded-[8px] text-[#4D45B5] hover:bg-[#4D45B5] hover:text-white hover: transition duration-675 hover:scale-105">Learn More</button>
                </div>
            </div>
            <div className="w-[80px] absolute mt-[33rem] ml-[56rem] flex">
            <svg width="39" height="42" viewBox="0 0 39 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.75092 18.337L36.059 4.02287L29.4851 37.8145L4.75092 18.337Z" stroke="#F2E1D2" stroke-width="4"/>
            </svg>
            <svg width="59" height="61" viewBox="0 0 59 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.06411 57.5288L13.9336 4.06285L54.8017 40.209L3.06411 57.5288Z" stroke="#F2E1D2" stroke-width="4"/>
            </svg>

            </div>
        </div>
    )
  }
   
  export default Homesolutions;
// components/BarChart.js
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AttendanceChart = () => {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Attendance Report",
        data: [45, 60, 75, 80, 55, 60, 55, 44, 88, 40, 35, 40], // Example data
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Ensures the chart scales according to the container
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: "Attendance Report",
        font: {
          size: 16
          
      },
        align: "center", // Align the title at the start (left)
        padding: {
          top: 0,
          bottom: 10,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          stepSize: 10, // Set the step size for the x-axis ticks
          color: "#1f2937", // Tailwind color for x-axis labels
        },
      },
      y: {
        min: 0,
        max: 90,
        beginAtZero: true,
        ticks: {
          stepSize: 10,
        },
      },
    },
  };

  return (
    <div className="h-[50vh] w-full ">
      <Bar data={data} options={options} />
    </div>
  );
};

export default AttendanceChart;

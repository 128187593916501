import { useState } from "react";
import CompanyInformation from "./CompanyInformation";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
// import useAxios from "../../../utils/axios/useAxios";

const OwnerInformation = ({ setIndex }) => {
  const user = useAuthUser();

  // console.log(user);

  const [activeComponent, setActiveComponent] = useState("");
  const [additionalUsers, setAdditionalUsers] = useState([]);

  //this is for the owners personal information
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [gender, setGender] = useState("");
  // const [dob, setDob] = useState("");

  //country and Address information
  // const [country, setcountry] = useState("");
  // const [countryCode, setCountryCode] = useState("");
  // const [address, setAddress] = useState("");
  // const [streetName, setStreetName] = useState("");

  //Additional Information
  // const [phoneNumner, setPhoneNumber] = useState("");
  // const [contactEmail, setContactEmial] = useState("");
  // const [workplaceEmail, setWorkplaceEmail] = useState("");
  // const [position, setPosition] = useState("");

  if (activeComponent === "CompanyInformation") return <CompanyInformation />;

  const handleAddUser = () => {
    setAdditionalUsers([...additionalUsers, {}]);
  };

  const handleNext = () => {
    //setActiveComponent("CompanyInformation");
    setIndex(2);
  };

  return (
    <div className="">
      <div className="mt-16 px-8">
        <div className="space-y-4">
          <h1 className="font-semibold text-[#4D45B5] text-xl tracking-wider">
            OWNER REGISTRATION
          </h1>
          <p className="text-[#4D45B5] tracking-wide text-lg">
            Personal Information*
          </p>
        </div>

        <div className="flex space-x-4">
          <div className="w-[33%] flex flex-col mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="firstName">
                First Name
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="firstName"
                // placeholder="Cheeze-itz"
                value={user.profile.first_name}
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="lastName">
                Last Name
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="lastName"
                // placeholder="Cheeze-itz"
                value={user.profile.last_name}
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="dob">
                D.O.B
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="date"
                name=""
                id="dob"
                placeholder="YYYY-MM-DD"
                value={user.profile.date_of_birth}
              />
            </div>
          </div>

          <div className="w-[33%] mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="middleName">
                Middle Name
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="middleName"
                placeholder="Middle name"
                value={user.profile.middle_name}
              />
            </div>

            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="Gender">
                Gender
              </label>
              <select
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="Country"
                placeholder="country Name"
              >
                <option value="">Prefer not to say</option>
                <option value="">Male</option>
                <option value="">Female</option>
                <option value="">Others</option>
              </select>
            </div>
          </div>

          <div className="w-[33%]">
            <div className="">
              <img
                // src={require("../../../../assets/images/Principal/AddPhoto.png")}
                alt=""
                src={user.profile.photo}
              />
              <p className="text-[0.9rem] opacity-80 font-medium mt-4">
                Add Image
              </p>
              <p className="text-sm w-[75%] mt-1 opacity-75">
                Image data can be uploaded up to 20Mb only.
              </p>
              <div className="-mt-8">
                <input
                  type="file"
                  name=""
                  id="imageUpload"
                  className="invisible"
                />
                <label
                  htmlFor="imageUpload"
                  className="text-[#4D45B5] cursor-pointer px-3 py-[0.4rem] rounded-md border-[#4D45B5] border-[1px] text-[0.7rem]"
                >
                  Add Image
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16 px-8">
        <div className="space-y-4">
          <p className="text-[#4D45B5] tracking-wide text-lg">
            Country and Address Information*
          </p>
        </div>

        <div className="flex space-x-4">
          <div className="w-[33%] flex flex-col mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="country">
                Country
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="country"
                placeholder="Nepal"
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="city">
                City
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="city"
                placeholder="Catmandu"
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="streetName">
                Street Name
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="streetName"
                placeholder="Koteshwor"
              />
            </div>
          </div>

          <div className="w-[33%] mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="countryCode">
                Country Code
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="countryCode"
                placeholder="+977"
              />
            </div>

            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="address">
                Address
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="address"
                // placeholder="Lazimpat"
                value={user.profile.address}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16 px-8">
        <div className="space-y-4">
          <p className="text-[#4D45B5] tracking-wide text-lg">
            Additional Information
          </p>
        </div>

        <div className="flex space-x-4">
          <div className="w-[33%] flex flex-col mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="phoneNo">
                Phone Number
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="phoneNo"
                // placeholder="9801284715"
                value={user.profile.contact}
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="email">
                Contact Email
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="email"
                name=""
                id="email"
                placeholder="Cheezeitz@gmail.com"
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="position">
                Position In Company
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="position"
                placeholder="Point Guard"
              />
            </div>
          </div>

          <div className="w-[33%] mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="homePhoneNo">
                Home Phone Number
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="homePhoneNo"
                placeholder="01-4029456"
              />
            </div>

            <div className="">
              <label
                className="w-[90%] pl-3 opacity-70"
                htmlFor="workplaceEmail"
              >
                Workplace Email
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="workplaceEmail"
                // placeholder="cheezeitz.ocatcore@gmail.com"
                value={user.profile.email}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="">
        {additionalUsers.map((_, index) => (
          <AdditionalUser key={index} />
        ))}
      </div>

      <div className="space-x-3 mt-8 w-[33%] flex mx-[32%]">
        <button
          className="px-4 py-2 text-[#4D45B5] w-[11rem] border-2 border-[#4D45B5] font-medium tracking-wide rounded-lg hover:bg-[#4D45B5] hover:text-white hover:ease-in-out hover:transition duration-[100ms]"
          onClick={handleAddUser}
        >
          + Add New User
        </button>
        <button
          onClick={handleNext}
          className="px-4 py-2 bg-[#4D45B5] w-[11rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
        >
          Next
        </button>
      </div>
    </div>
  );
};

const AdditionalUser = () => {
  const [currentComponent, setCurrentComponent] = useState("");

  if (currentComponent === "CompanyInformation") return <CompanyInformation />;

  return (
    <div className="">
      <div className="mt-16 px-8">
        <div className="space-y-4">
          <h1 className="font-semibold text-[#4D45B5] text-xl tracking-wider">
            ADDITIONAL MEMBER
          </h1>
          <p className="text-[#4D45B5] tracking-wide text-lg">
            Personal Information*
          </p>
        </div>

        <div className="flex space-x-4">
          <div className="w-[33%] flex flex-col mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="firstName">
                First Name
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="firstName"
                placeholder="Cheeze-itz"
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="lastName">
                Last Name
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="lastName"
                placeholder="Cheeze-itz"
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="dob">
                D.O.B
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="date"
                name=""
                id="dob"
                // placeholder=""
              />
            </div>
          </div>

          <div className="w-[33%] mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="middleName">
                Middle Name
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="middleName"
                placeholder="Devandsiu"
              />
            </div>

            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="Gender">
                Gender
              </label>
              <select
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="Country"
                placeholder="Uganda"
              >
                <option value="">Prefer not to say</option>
                <option value="">Male</option>
                <option value="">Female</option>
                <option value="">Others</option>
              </select>
            </div>
          </div>

          <div className="w-[33%]">
            <div className="">
              <img
                src={require("../../../../assets/images/Principal/AddPhoto.png")}
                alt=""
              />
              <p className="text-[0.9rem] opacity-80 font-medium mt-4">
                Add Image
              </p>
              <p className="text-sm w-[75%] mt-1 opacity-75">
                Image data can be uploaded up to 20Mb only.
              </p>
              <div className="-mt-8">
                <input
                  type="file"
                  name=""
                  id="imageUpload"
                  className="invisible"
                />
                <label
                  htmlFor="imageUpload"
                  className="text-[#4D45B5] cursor-pointer px-3 py-[0.4rem] rounded-md border-[#4D45B5] border-[1px] text-[0.7rem]"
                >
                  Add Image
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16 px-8">
        <div className="space-y-4">
          <p className="text-[#4D45B5] tracking-wide text-lg">
            Country and Address Information*
          </p>
        </div>

        <div className="flex space-x-4">
          <div className="w-[33%] flex flex-col mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="country">
                Country
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="country"
                placeholder="Nepal"
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="city">
                City
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="city"
                placeholder="Catmandu"
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="streetName">
                Street Name
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="streetName"
                placeholder="Koteshwor"
              />
            </div>
          </div>

          <div className="w-[33%] mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="countryCode">
                Country Code
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="countryCode"
                placeholder="+977"
              />
            </div>

            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="address">
                Address
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="address"
                placeholder="Lazimpat"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16 px-8">
        <div className="space-y-4">
          <p className="text-[#4D45B5] tracking-wide text-lg">
            Additional Information
          </p>
        </div>

        <div className="flex space-x-4">
          <div className="w-[33%] flex flex-col mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="phoneNo">
                Phone Number
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="phoneNo"
                placeholder="9801284715"
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="email">
                Contact Email
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="email"
                name=""
                id="email"
                placeholder="Cheezeitz@gmail.com"
              />
            </div>
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="position">
                Position In Company
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="position"
                placeholder="Point Guard"
              />
            </div>
          </div>

          <div className="w-[33%] mt-6 space-y-8">
            <div className="">
              <label className="w-[90%] pl-3 opacity-70" htmlFor="homePhoneNo">
                Home Phone Number
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="homePhoneNo"
                placeholder="01-4029456"
              />
            </div>

            <div className="">
              <label
                className="w-[90%] pl-3 opacity-70"
                htmlFor="workplaceEmail"
              >
                Workplace Email
              </label>
              <input
                className="w-[90%] mt-1 rounded-md opacity-80"
                type="text"
                name=""
                id="workplaceEmail"
                placeholder="cheezeitz.ocatcore@gmail.com"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerInformation;

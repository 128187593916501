import React, { useState } from "react";
import BaseLayout from "../../components/BaseLayout";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import useAxios from "../../../utils/axios/useAxios";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const HRRoutine = () => {
  const { data: routines, error: routineError } =
    useAxios(`classrooms/routines`);

  const { data: subjects, error: subjectError } =
    useAxios(`classrooms/subjects`);

  const { data: teachers, error: teacherError } =
    useAxios(`classrooms/teachers`);

  const { data: curriculums, error: curriculumError } = useAxios(
    `classrooms/curriculum`
  );

  const { data: classrooms, error: classroomError } = useAxios(
    `classrooms/classrooms`
  );

  const authUser = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();

  const [currentPage, setCurrentPage] = useState(1);
  const [filterTeacher, setFilterTeacher] = useState("");
  const [filterSubject, setFilterSubject] = useState("");
  const [filterCurriculum, setFilterCurriculum] = useState("");
  const [filterClassroom, setFilterClassroom] = useState("");
  const [filterDay, setFilterDay] = useState("");

  const itemsPerPage = 6;

  // Mapping IDs
  const subjectMap = new Map(
    subjects.map((subject) => [subject.id, subject.name])
  );

  const teacherMap = new Map(
    teachers.map((teacher) => [
      teacher.id,
      `${teacher.first_name} ${teacher.last_name}`,
    ])
  );
  const curriculumMap = new Map(
    curriculums.map((curriculum) => [curriculum.id, curriculum.name])
  );
  const classroomMap = new Map(
    classrooms.map((classroom) => [classroom.id, classroom.name])
  );

  const filteredRoutine = routines.filter((item) => {
    return (
      (filterClassroom
        ? item.classroom.toString() === filterClassroom
        : true) &&
      (filterTeacher ? item.teacher.toString() === filterTeacher : true) &&
      (filterSubject ? item.subject.toString() === filterSubject : true) &&
      (filterDay ? item.day === filterDay : true)
    );
  });

  const totalPages = Math.ceil(filteredRoutine.length / itemsPerPage);
  const currentData = filteredRoutine.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return `${hours}:${minutes.toString().padStart(2, "0")}`;
  };

  const formatDay = (day) => {
    const days = {
      mon: "Monday",
      tue: "Tuesday",
      wed: "Wednesday",
      thu: "Thursday",
      fri: "Friday",
      sat: "Saturday",
      sun: "Sunday",
    };
    return days[day] || day;
  };

  return (
    <BaseLayout title={"Routine"}>
      <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold">Schedule</h2>
          <div className="space-x-2 text-[14px] mr-4">
            <select
              className="w-[11rem] border px-3 py-1 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={filterDay}
              onChange={(e) => setFilterDay(e.target.value)}
            >
              <option value="">All Days</option>
              <option value="mon">Monday</option>
              <option value="tue">Tuesday</option>
              <option value="wed">Wednesday</option>
              <option value="thu">Thursday</option>
              <option value="fri">Friday</option>
              <option value="sat">Saturday</option>
              <option value="sun">Sunday</option>
            </select>
            <select
              className="w-[11rem] border px-3 py-1 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={filterClassroom}
              onChange={(e) => setFilterClassroom(e.target.value)}
            >
              <option value="">All Classrooms</option>
              {classrooms.map((classroom) => (
                <option key={classroom.id} value={classroom.id.toString()}>
                  {classroom.name}
                </option>
              ))}
            </select>
            <select
              className="w-[11rem] border px-3 py-1 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={filterTeacher}
              onChange={(e) => setFilterTeacher(e.target.value)}
            >
              <option value="">All Teachers</option>
              {teachers.map((teacher) => (
                <option key={teacher.id} value={teacher.id.toString()}>
                  {`${teacher.first_name} ${teacher.last_name}`}
                </option>
              ))}
            </select>
            <select
              className="w-[11rem] border px-3 py-1 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={filterSubject}
              onChange={(e) => setFilterSubject(e.target.value)}
            >
              <option value="">All Subjects</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id.toString()}>
                  {subject.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <hr className="my-3" />

        <div className="container mx-auto px-4">
          <table className="min-w-full bg-white ">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px] ">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">
                  S.N
                </th>
                <th className="p-4 text-left font-light">Classroom</th>
                <th className="p-4 text-center font-light">Subject</th>
                <th className="p-4 text-center font-light">Teacher</th>
                <th className="p-4 text-center font-light">Time</th>
                <th className="p-4 text-center font-light rounded-tr-lg">
                  Day
                </th>
              </tr>
            </thead>

            <tbody className="text-[14px] border  border-gray-300">
              {currentData.map((item, index) => (
                <tr
                  key={item.id}
                  className="hover:bg-[#F5F7FF] divide-x divide-gray-300  rounded-b-lg"
                >
                  <td className="p-4 border-b border-gray-300 text-center">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-left ">
                    {classroomMap.get(item.classroom)}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {subjectMap.get(item.subject)}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {teacherMap.get(item.teacher)}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {formatTime(item.start_time)} - {formatTime(item.end_time)}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {formatDay(item.day)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex space-x-6 justify-center">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="size-5 text-gray-400 "
            >
              <FaChevronLeft />
            </button>
            <span className="">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="size-5 text-gray-400  "
            >
              <FaChevronRight className="text-sm " />
            </button>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default HRRoutine;

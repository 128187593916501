import React from "react";

const AddNewAccountEntry = () => {
  return (
    <div className="p-6 max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-[#827CCC]">Add New Entry</h2>
      </div>

      <div className="grid grid-cols-4 gap-y-6 gap-x-1 mb-4">
        <label
          className="text-left text-base font-medium text-gray-700 mt-2"
          htmlFor="name"
        >
          Name
        </label>
        <input
          type="text"
          className="col-span-3 p-2 border border-gray-300 rounded-md"
          placeholder="Service A"
          id="name"
        />

        <label
          className="text-base text-left font-medium text-gray-700 mt-2"
          htmlFor="code"
        >
          Code
        </label>
        <input
          className="p-2 border border-gray-300 rounded-md appearance-none bg-white"
          id="code"
          placeholder="101001"
        />
        <label
          className="text-base text-center font-medium text-gray-700 mt-2"
          htmlFor="type"
        >
          Type
        </label>
        <select
          className="p-2 border border-gray-300 rounded-md appearance-none bg-white"
          id="type"
        >
          <option>Revenue</option>
          <option>Capital</option>
          <option>Expense</option>
          <option>Asset</option>
        </select>

        <label
          className="text-left text-base font-medium text-gray-700 mt-2"
          htmlFor="tax"
        >
          Tax
        </label>
        <select
          id="tax"
          className="p-2 border border-gray-300 rounded-md appearance-none bg-white"
        >
          <option>Tax on Sales</option>
          <option>Tax on Exempt</option>
        </select>

        <label
          className="text-base text-center font-medium text-gray-700 mt-2"
          htmlFor="taxPercent"
        >
          Tax Percent
        </label>
        <input
          className="p-2 border border-gray-300 rounded-md appearance-none bg-white"
          id="taxPercent"
          placeholder="10%"
        />

        <div className="text-base text-center col-span-4 flex justify-end">
          <button className="px-4 py-2 bg-[#4D45B5] w-[12rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewAccountEntry;

import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { LuHome, LuChevronRight, LuChevronLeft } from "react-icons/lu";
import { FaBook } from "react-icons/fa";
import { PiExamFill } from "react-icons/pi";
import {
  RiGraduationCapLine,
  RiTodoLine,
  RiCalendarScheduleLine,
} from "react-icons/ri";
import { CgNotes } from "react-icons/cg";
import { FaNotesMedical } from "react-icons/fa6";
import { GrInProgress } from "react-icons/gr";
import { FaHistory } from "react-icons/fa";
import { SiWebmoney } from "react-icons/si";
import {
  IoDocumentTextOutline,
  IoTime,
  IoLogOutOutline,
  IoCloseOutline,
  IoMenuOutline,
} from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import clsx from "clsx";
import "../../styles/sidebar.css";
import { HiOutlineBriefcase } from "react-icons/hi2";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa6";
import { FaFileWaveform } from "react-icons/fa6";
import { TfiLayersAlt } from "react-icons/tfi";
import { CiMoneyCheck1 } from "react-icons/ci";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignOut from "react-auth-kit/hooks/useSignOut";

//Student Section
const STUDENT_MAIN_ITEMS = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: LuHome,
    link: "student/dashboard",
  },
  {
    id: "readingMaterial",
    title: "Reading Material",
    icon: FaBook,
    link: "student/readingMaterial",
  },
  {
    id: "pastPaper",
    title: "Past Paper",
    icon: IoDocumentTextOutline,
    link: "student/pastPaper",
  },
  {
    id: "assignments",
    title: "Assignments",
    icon: PiExamFill,
    link: "/student/assignment",
  },
  {
    id: "result",
    title: "Result",
    icon: RiGraduationCapLine,
    link: "/student/result",
  },
  {
    id: "timeTable",
    title: "Time Table",
    icon: IoTime,
    link: "/student/timeTable",
  },
  {
    id: "todoList",
    title: "To-Do List",
    icon: RiTodoLine,
    link: "/student/toDoList",
  },
];

const STUDENT_OTHER_ITEMS = [
  {
    id: "settings",
    title: "Settings",
    icon: IoMdSettings,
    link: "student/settings",
  },
  {
    id: "logOut",
    title: "Logout",
    icon: IoLogOutOutline,
    link: "",
  },
];
//Principal Section

const PRINCIPAL_MAIN_ITEMS = [
  {
    id: "Dashboard",
    title: "Dashboard",
    icon: LuHome,
    link: "principal/dashboard",
  },
  {
    id: "addEvents",
    title: "Add Events",
    icon: MdOutlineSpeakerNotes,
    link: "principal/addEvent",
  },
  {
    id: "addNotice",
    title: "Add Notice",
    icon: IoDocumentTextOutline,
    link: "principal/addNotice",
  },
  {
    id: "addPeople",
    title: "Add Staff",
    icon: PiExamFill,
    link: "principal/addPeople",
  },
  {
    id: "attendance",
    title: "Attendance",
    icon: RiGraduationCapLine,
    link: "principal/attendance",
  },
  {
    id: "leaveRequests",
    title: "Leave Requests",
    icon: FaCalendarCheck,
    link: "principal/leaveRequest",
  },

  {
    id: "leaveData",
    title: "Leave Data",
    icon: IoDocumentTextOutline,
    link: "principal/leaveData",
  },

  {
    id: "todoList",
    title: "To-Do List",
    icon: RiTodoLine,
    link: "principal/toDoList",
  },

  {
    id: "timeTable",
    title: "Time Table",
    icon: IoTime,
    link: "principal/timeTable",
  },
  {
    id: "billing",
    title: "Billing Information",
    icon: FaFileWaveform,
    link: "principal/billing",
  },
  {
    id: "noticeHistory",
    title: "Notice History",
    icon: RiTodoLine,
    link: "principal/noticeHistory",
  },
  {
    id: "salaryInformation",
    title: "Salary Information",
    icon: TfiLayersAlt,
    link: "principal/salaryInformation",
  },
];

const PRINCIPAL_OTHER_ITEMS = [
  {
    id: "settings",
    title: "Settings",
    icon: IoMdSettings,
    link: "principal/Settings",
  },
  {
    id: "logOut",
    title: "Logout",
    icon: IoLogOutOutline,
    link: "",
  },
];
//Teacher section
const TEACHER_MAIN_ITEMS = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: LuHome,
    link: "teacher/dashboard",
  },
  {
    id: "readingMaterial",
    title: "Reading Material",
    icon: FaBook,
    link: "teacher/readingMaterial",
  },
  {
    id: "pastPaper",
    title: "Past Paper",
    icon: IoDocumentTextOutline,
    link: "teacher/pastPaper",
  },
  {
    id: "assignments",
    title: "Assignments",
    icon: PiExamFill,
    link: "teacher/assignments",
  },
  {
    id: "addResult",
    title: "Add Result",
    icon: RiGraduationCapLine,
    link: "teacher/addResult",
  },
  {
    id: "addNotice",
    title: "Add Notice",
    icon: CgNotes,
    link: "teacher/addNotice",
  },
  {
    id: "leaveRequest",
    title: "Leave Request",
    icon: HiOutlineBriefcase,
    link: "teacher/leaveRequest",
  },
  {
    id: "attendance",
    title: "Attendance",
    icon: FaNotesMedical,
    link: "teacher/studentAttendance",
  },
  {
    id: "studentProgress",
    title: "Student Progress",
    icon: GrInProgress,
    link: "teacher/studentProgress",
  },
  {
    id: "timeTable",
    title: "Time Table",
    icon: IoTime,
    link: "teacher/timeTable",
  },
  {
    id: "toDoList",
    title: "To-Do List",
    icon: RiTodoLine,
    link: "teacher/toDoList",
  },
  {
    id: "noticeHistory",
    title: "Notice History",
    icon: FaHistory,
    link: "teacher/noticeHistory",
  },
  {
    id: "salaryInformation",
    title: "Salary Information",
    icon: SiWebmoney,
    link: "teacher/salaryInformation",
  },
];

const TEACHER_OTHER_ITEMS = [
  {
    id: "settings",
    title: "Settings",
    icon: IoMdSettings,
    link: "teacher/settings",
  },
  {
    id: "logOut",
    title: "Logout",
    icon: IoLogOutOutline,
    link: "",
  },
];

//HR Section

const HR_MAIN_ITEMS = [
  {
    id: "Dashboard",
    title: "Dashboard",
    icon: LuHome,
    link: "hr/dashboard",
  },
  {
    id: "addEvents",
    title: "Add Events",
    icon: MdOutlineSpeakerNotes,
    link: "hr/addEvent",
  },
  {
    id: "addNotice",
    title: "Add Notice",
    icon: CgNotes,
    link: "hr/addNotice",
  },
  {
    id: "addPeople",
    title: "Add People",
    icon: PiExamFill,
    link: "hr/addPeople",
  },
  {
    id: "attendance",
    title: "Attendance",
    icon: FaNotesMedical,
    link: "hr/attendance",
  },
  {
    id: "leaveRequests",
    title: "Leave Requests",
    icon: FaCalendarCheck,
    link: "hr/leaveRequest",
  },
  {
    id: "todoList",
    title: "To-Do List",
    icon: RiTodoLine,
    link: "hr/toDoList",
  },
  {
    id: "timeTable",
    title: "Time Table",
    icon: IoTime,
    link: "hr/timeTable",
  },
  {
    id: "routine",
    title: "Routine",
    icon: RiCalendarScheduleLine,
    link: "hr/routine",
  },
  {
    id: "noticeHistory",
    title: "Notice History",
    icon: RiTodoLine,
    link: "hr/noticeHistory",
  },
  {
    id: "salaryInformation",
    title: "Salary Information",
    icon: TfiLayersAlt,
    link: "hr/salaryInformation",
  },
];

const HR_OTHER_ITEMS = [
  {
    id: "settings",
    title: "Settings",
    icon: IoMdSettings,
    link: "hr/settings",
  },
  {
    id: "logOut",
    title: "Logout",
    icon: IoLogOutOutline,
    link: "",
  },
];

//accounting section
const ACCOUNTING_MAIN_ITEMS = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: LuHome,
    link: "accounting/dashboard",
  },
  {
    id: "accountEntry",
    title: "Account Entry",
    icon: FaBook,
    link: "accounting/accountEntry",
  },
  {
    id: "manualJournal",
    title: "Manual Journal",
    icon: IoDocumentTextOutline,
    link: "accounting/manualJournal",
  },
  {
    id: "balanceSheet",
    title: "Balance Sheet",
    icon: PiExamFill,
    link: "accounting/balanceSheet",
  },
  {
    id: "plRevenue",
    title: "PL Revenue",
    icon: RiGraduationCapLine,
    link: "accounting/pLRevenue",
  },
  {
    id: "plExpense",
    title: "PL Expense",
    icon: CgNotes,
    link: "accounting/pLExpense",
  },
  {
    id: "plOverall",
    title: "PL Overall",
    icon: HiOutlineBriefcase,
    link: "accounting/profitLoss",
  },
  {
    id: "leaveRequest",
    title: "Leave Request",
    icon: FaNotesMedical,
    link: "accounting/leaveRequest",
  },
  {
    id: "notice",
    title: "Add Notice",
    icon: CgNotes,
    link: "accounting/addNotice",
  },
  {
    id: "timeTable",
    title: "Time Table",
    icon: IoTime,
    link: "accounting/timeTable",
  },
  {
    id: "toDoList",
    title: "To-Do List",
    icon: RiTodoLine,
    link: "accounting/toDOList",
  },
  {
    id: "salaryBreakDown",
    title: "Salary Breakdown",
    icon: SiWebmoney,
    link: "accounting/salaryBreakdown",
  },

  //
  {
    id: "salaryInformation",
    title: "Salary Information",
    icon: CiMoneyCheck1,
    link: "accounting/SalaryInformation",
  },
];

const ACCOUNTING_OTHER_ITEMS = [
  {
    id: "settings",
    title: "Settings",
    icon: IoMdSettings,
    link: "accounting/settings",
  },
  {
    id: "logOut",
    title: "Logout",
    icon: IoLogOutOutline,
    link: "",
  },
];

// Parents section
const PARENT_MAIN_ITEMS = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: LuHome,
    link: "parents/dashboard",
  },
  {
    id: "result",
    title: "Result",
    icon: RiGraduationCapLine,
    link: "/parents/parentStudentResult",
  },
  {
    id: "timeTable",
    title: "Time Table",
    icon: IoTime,
    link: "/parents/timeTable",
  },
];

const PARENT_OTHER_ITEMS = [
  {
    id: "settings",
    title: "Settings",
    icon: IoMdSettings,
    link: "parents/settings",
  },
  {
    id: "logOut",
    title: "Logout",
    icon: IoLogOutOutline,
    link: "",
  },
];

function Sidebar() {
  let { activeId } = useParams();

  const user = useAuthUser();

  const [userSidebar, setUserSidebar] = useState([[], []]);

  useEffect(() => {
    if (user.user_type === null) {
      setUserSidebar([[], []]);
    } else if (user.user_type === "STUDENT") {
      setUserSidebar([STUDENT_MAIN_ITEMS, STUDENT_OTHER_ITEMS]);
    } else if (user.user_type === "TEACHER") {
      setUserSidebar([TEACHER_MAIN_ITEMS, TEACHER_OTHER_ITEMS]);
    } else if (user.user_type === "PRINCIPAL") {
      setUserSidebar([PRINCIPAL_MAIN_ITEMS, PRINCIPAL_OTHER_ITEMS]);
    } else if (user.user_type === "HR") {
      setUserSidebar([HR_MAIN_ITEMS, HR_OTHER_ITEMS]);
    } else if (user.user_type === "ACCOUNTANT") {
      setUserSidebar([ACCOUNTING_MAIN_ITEMS, ACCOUNTING_OTHER_ITEMS]);
    } else if (user.user_type === "PARENT") {
      setUserSidebar([PARENT_MAIN_ITEMS, PARENT_OTHER_ITEMS]);
    } else if (user.user_type === null) {
      setUserSidebar([[], []]);
    }
  }, [user.user_type]);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCollapsedMobile, setIsCollapsedMobile] = useState(true);
  const [activeTab, setActiveTab] = useState(activeId);
  const [hoverTab, setHoverTab] = useState(STUDENT_MAIN_ITEMS[0].id);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const handleToggleSidebar = () => {
    setIsCollapsedMobile(!isCollapsedMobile);
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 1024;
      setIsMobile(isMobileView);
      if (!isMobileView) {
        setIsCollapsedMobile(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setActiveTab(activeId);
  }, [activeId]);

  const signout = useSignOut();

  function logout() {
    signout();
    window.location.reload(false);
  }
  return (
    <>
      {isMobile ? (
        <motion.div
          className="sidebar max-h-screen overflow-y-scroll overflow-x-visible border-r font-poppins "
          animate={{ width: isCollapsedMobile ? 80 : 280 }}
          layout
        >
          <button
            onClick={handleToggleSidebar}
            className={`text-3xl ${!isCollapsedMobile ? "ml-auto" : "mx-auto"}`}
          >
            {!isCollapsedMobile ? <IoCloseOutline /> : <IoMenuOutline />}
          </button>

          {!isCollapsedMobile ? (
            <>
              <img
                className={`transition-all duration-300 ease-in-out rounded-2xl object-contain ${
                  isCollapsedMobile ? "h-16 " : "h-[16vh] px-4 "
                }`}
                src={require("../../assets/images/logo.jpg")}
                alt="logo"
              />
              <hr className={`${isCollapsed ? "my-2 " : "my-[24px] "}`} />

              {userSidebar[0].map((item) => (
                <Link to={`${item.link}`} key={item.id} className="pb-2">
                  <SidebarItem
                    isSidebarCollapsed={isCollapsed}
                    item={item}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    hoverTab={hoverTab}
                    setHoverTab={setHoverTab}
                  />
                </Link>
              ))}
              <hr className={`${isCollapsed ? "my-2 " : "my-[14px] "}`} />
              {userSidebar[1].map((item) =>
                item.id === "logOut" ? (
                  <button
                    key={item.id}
                    className="logoutbutton pb-2 mr-10"
                    onClick={logout}
                  >
                    <SidebarItem
                      isSidebarCollapsed={isCollapsed}
                      item={item}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      hoverTab={hoverTab}
                      setHoverTab={setHoverTab}
                    />
                  </button>
                ) : (
                  <Link to={`${item.link}`} key={item.id} className="pb-2">
                    <SidebarItem
                      isSidebarCollapsed={isCollapsed}
                      item={item}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      hoverTab={hoverTab}
                      setHoverTab={setHoverTab}
                    />
                  </Link>
                )
              )}
            </>
          ) : (
            <div className="min-h-screen"></div>
          )}
        </motion.div>
      ) : (
        <motion.div
          className="sidebar h-screen overflow-x-auto overflow-y-scroll border-r font-poppins"
          animate={{ width: isCollapsed ? 80 : 280 }}
          layout
        >
          <img
            className={`transition-all duration-300 ease-in-out rounded-2xl object-contain ${
              isCollapsed ? "h-16 " : "h-[16vh] px-4 "
            }`}
            src={require("../../assets/images/logo.jpg")}
            alt="logo"
          />
          <hr className={`${isCollapsed ? "my-2 " : "my-[24px] "}`} />
          <button
            className="sidebar__collapse-button"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <LuChevronRight /> : <LuChevronLeft />}
          </button>
          {userSidebar[0].map((item) => (
            <Link to={`${item.link}`} key={item.id} className="pb-2">
              <SidebarItem
                isSidebarCollapsed={isCollapsed}
                item={item}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                hoverTab={hoverTab}
                setHoverTab={setHoverTab}
              />
            </Link>
          ))}
          <hr className={`${isCollapsed ? "my-2 " : "my-[14px] "}`} />
          {userSidebar[1].map((item) =>
            item.id === "logOut" ? (
              <button
                key={item.id}
                className="logoutbutton pb-2 pr-10"
                onClick={logout}
              >
                <SidebarItem
                  isSidebarCollapsed={isCollapsed}
                  item={item}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  hoverTab={hoverTab}
                  setHoverTab={setHoverTab}
                />
              </button>
            ) : (
              <Link to={`${item.link}`} key={item.id} className="pb-2">
                <SidebarItem
                  isSidebarCollapsed={isCollapsed}
                  item={item}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  hoverTab={hoverTab}
                  setHoverTab={setHoverTab}
                />
              </Link>
            )
          )}
        </motion.div>
      )}
    </>
  );
}

function SidebarItem({
  item,
  hoverTab,
  setHoverTab,
  activeTab,
  setActiveTab,
  isSidebarCollapsed,
}) {
  const IconComponent = item.icon;

  return (
    <motion.div
      layout
      className={clsx(
        "sidebar-item",
        {
          "sidebar-item__active ": activeTab === item.id,
        },
        { "scale-1 text-[#4d45b5]": hoverTab === item.id }
      )}
      onFocus={() => setHoverTab(item.id)}
      onMouseOver={() => setHoverTab(item.id)}
      onMouseLeave={() => setHoverTab(item.id)}
      onClick={() => setActiveTab(item.id)}
    >
      {hoverTab === item.id ? (
        <motion.div
          layoutId="sidebar-item-indicator"
          className="sidebar-item__active-bg"
        />
      ) : null}
      <span
        className={`sidebar-item__icon ${
          isSidebarCollapsed ? "text-xl" : "text-lg"
        }`}
      >
        <IconComponent />
      </span>
      <motion.span
        className="sidebar-item__title"
        animate={{
          clipPath: isSidebarCollapsed
            ? "inset(0% 100% 0% 0%)"
            : "inset(0% 0% 0% 0%)",
          opacity: isSidebarCollapsed ? 0 : 1,
        }}
      >
        {item.title}
      </motion.span>
    </motion.div>
  );
}

export default Sidebar;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const PrincipalStudentProgress = () => {
  // temporary data
  const data = [
    {
      id: 1,
      name: "Lorem ipsum dolor sit amet consectetur. ",
      grade: "2",
      section: "A",
      student_id: 22067735,
      roll_no: 25,
    },
    {
      id: 2,
      name: "Lorem ipsum dolor sit amet consectetur. ",
      grade: "3",
      section: "A",
      student_id: 22067736,
      roll_no: 1,
    },
    {
      id: 3,
      name: "Lorem ipsum dolor sit amet consectetur. ",
      grade: "2",
      section: "B",
      student_id: 22067739,
      roll_no: 4,
    },
    {
      id: 4,
      name: "Lorem ipsum dolor sit amet consectetur. ",
      grade: "5",
      section: "A",
      student_id: 22067737,
      roll_no: 5,
    },
    {
      id: 5,
      name: "Lorem ipsum dolor sit amet consectetur. ",
      grade: "2",
      section: "A",
      student_id: 22067734,
      roll_no: 7,
    },
    {
      id: 6,
      name: "Lorem ipsum dolor sit amet consectetur. ",
      grade: "7",
      section: "C",
      student_id: 22067745,
      roll_no: 50,
    },
    {
      id: 7,
      name: "Lorem ipsum dolor sit amet consectetur. ",
      grade: "2",
      section: "A",
      student_id: 22067748,
      roll_no: 32,
    },
    {
      id: 8,
      name: "Lorem ipsum dolor sit amet consectetur. ",
      grade: "2",
      section: "A",
      student_id: 22067788,
      roll_no: 22,
    },
    {
      id: 9,
      name: "Lorem ipsum dolor sit amet consectetur. ",
      grade: "9",
      section: "B",
      student_id: 22067792,
      roll_no: 12,
    },

    // Add more entries as needed following the same format
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterGrade, setFilterGrade] = useState("");
  const [filterSection, setFilterSection] = useState("");

  const itemsPerPage = 6;

  const filteredData = data.filter((item) => {
    return (
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filterGrade ? item.grade === filterGrade : true) &&
      (filterSection ? item.section === filterSection : true)
    );
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const uniqueGrade = [...new Set(data.map((item) => item.grade))];
  const uniqueSection = [...new Set(data.map((item) => item.section))];

  return (
    <div className="max-w-screen-2xl mx-auto px-10 py-5 font-poppins min-h-screen bg-[#FBFBFF]">
      <div className="flex justify-between items-center mb-12">
        <div>
          <h1 className="text-2xl font-semibold text-[#5964DB]">Result</h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-[#928DD3] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4D45B5] focus:border-transparent"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-[#928DD3]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35M17.65 10.65a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
          <div className="text-[#928DD3]">
            <IoNotifications className="w-7 h-7 duration-200 hover:text-[#4D45B5] hover:scale-110" />
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold ml-4">Student Progress</h2>
          <div className="space-x-4 text-[14px] mr-4">
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={filterGrade}
              onChange={(e) => setFilterGrade(e.target.value)}
            >
              <option value="" selected>
                All Grade
              </option>
              {uniqueGrade.map((grade) => (
                <option key={grade} value={grade}>
                  {grade}
                </option>
              ))}
            </select>
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={filterSection}
              onChange={(e) => setFilterSection(e.target.value)}
            >
              <option value="" selected>
                All Section
              </option>
              {uniqueSection.map((section) => (
                <option key={section} value={section}>
                  {section}
                </option>
              ))}
            </select>
          </div>
        </div>

        <hr className="my-4" />

        <div className="container mx-auto px-4">
          <table className="min-w-full bg-white ">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px] ">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">
                  S.N
                </th>
                <th className="p-4 text-left font-light">Name</th>
                <th className="p-4 text-center font-light">Student ID</th>
                <th className="p-4 text-center font-light">Roll No.</th>
                <th className="p-4 text-center font-light rounded-tr-lg">
                  Result
                </th>
              </tr>
            </thead>

            <tbody className="text-[14px] border  border-gray-300">
              {currentData.map((item, index) => (
                <tr
                  key={item.id}
                  className="hover:bg-[#F5F7FF] divide-x divide-gray-300  rounded-b-lg"
                >
                  <td className="p-4 border-b border-gray-300 text-center">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-left ">
                    {item.name}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {item.student_id}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {item.roll_no}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center ">
                    <Link
                      to={`/principal/principalExamResult/${item.id}`}
                      className="hover:text-[#4D45B5] text-[#4D45B5] underline font-medium duration-200"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex space-x-6 justify-center">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="size-5 text-gray-400 "
            >
              <FaChevronLeft />
            </button>
            <span className="">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="size-5 text-gray-400"
            >
              <FaChevronRight className="text-sm" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrincipalStudentProgress;

import React, { useState } from "react";
import { IoNotifications } from "react-icons/io5";
import ResultChart from "../../components/StudentResult/ResultChart";
import ResultTable from "../../components/StudentResult/ResultTable";
import StudentDetailCard from "../../components/StudentResult/StudentDetailCard";

const PrincipalExamResult = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [examType, setExamType] = useState("Term 1");

  const student = {
    name: "Lorem Ipsum",
    address: "Lorem Ipsum",
    class: "VII",
    section: "F",
    rollNo: 18,
    attendance: 80,
    imageUrl: "https://via.placeholder.com/150", // Replace with the actual image URL
  };

  const results = [
    {
      year: "2027",
      examType: "Term 1",
      subject: "Math",
      creditHour: 4,
      practical: "A+",
      theory: "A+",
      percentage: 95,
      finalGrade: "A+",
    },
    {
      year: "2027",
      examType: "Term 1",
      subject: "Science",
      creditHour: 4,
      practical: "B",
      theory: "A",
      percentage: 88,
      finalGrade: "A",
    },
    {
      year: "2027",
      examType: "Term 1",
      subject: "English",
      creditHour: 3,
      practical: "B+",
      theory: "B+",
      percentage: 82,
      finalGrade: "B+",
    },
    {
      year: "2027",
      examType: "Term 2",
      subject: "Math",
      creditHour: 4,
      practical: "A",
      theory: "A",
      percentage: 90,
      finalGrade: "A",
    },
    {
      year: "2026",
      examType: "Term 1",
      subject: "Math",
      creditHour: 4,
      practical: "A",
      theory: "A-",
      percentage: 87,
      finalGrade: "A-",
    },
    {
      year: "2026",
      examType: "Term 2",
      subject: "Science",
      creditHour: 4,
      practical: "B+",
      theory: "B",
      percentage: 78,
      finalGrade: "B",
    },
    {
      year: "2025",
      examType: "Unit Test",
      subject: "English",
      creditHour: 3,
      practical: "C",
      theory: "B",
      percentage: 70,
      finalGrade: "B",
    },
    {
      year: "2024",
      examType: "Unit Test",
      subject: "Math",
      creditHour: 4,
      practical: "A",
      theory: "B+",
      percentage: 85,
      finalGrade: "A",
    },
    {
      year: "2024",
      examType: "Term 1",
      subject: "Science",
      creditHour: 4,
      practical: "B+",
      theory: "A-",
      percentage: 80,
      finalGrade: "A-",
    },
    {
      year: "2023",
      examType: "Term 2",
      subject: "Math",
      creditHour: 4,
      practical: "A-",
      theory: "B",
      percentage: 83,
      finalGrade: "A-",
    },
    {
      year: "2022",
      examType: "Term 1",
      subject: "English",
      creditHour: 3,
      practical: "B",
      theory: "A",
      percentage: 75,
      finalGrade: "A",
    },
    {
      year: "2021",
      examType: "Term 2",
      subject: "Science",
      creditHour: 4,
      practical: "A+",
      theory: "A+",
      percentage: 95,
      finalGrade: "A+",
    },
  ];

  // Filter results based on year and examType
  const filteredResults = results.filter(
    (result) => result.examType === examType
  );

  // Separate into practical and theoretical results
  const practicalResults = filteredResults.map((result) => ({
    subject: result.subject,
    creditHour: result.creditHour,
    score: result.practical,
    percentage: result.percentage,
    finalGrade: result.finalGrade,
  }));

  const theoryResults = filteredResults.map((result) => ({
    subject: result.subject,
    creditHour: result.creditHour,
    score: result.theory,
    percentage: result.percentage,
    finalGrade: result.finalGrade,
  }));

  const downloadResults = () => {
    const csvContent = [
      [
        "S.N",
        "Subjects",
        "Credit Hour",
        "Practical/Theory",
        "Score",
        "Percentage",
        "Final Grade",
      ],
      ...filteredResults.map((row, index) => [
        index + 1,
        row.subject,
        row.creditHour,
        "Practical",
        row.practical,
        `${row.percentage}%`,
        row.finalGrade,
      ]),
      ...filteredResults.map((row, index) => [
        index + 1,
        row.subject,
        row.creditHour,
        "Theory",
        row.theory,
        `${row.percentage}%`,
        row.finalGrade,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "results.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="max-w-screen-2xl mx-auto px-10 py-5 font-poppins min-h-screen bg-[#FBFBFF] w-full text-neutral-900">
      <div className="flex justify-between items-center mb-5">
        <div>
          <h1 className="text-2xl font-semibold text-[#5964DB]">
            Exam Results
          </h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-[#928DD3] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4D45B5] focus:border-transparent"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-[#928DD3]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35M17.65 10.65a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
          <div className="text-[#928DD3]">
            <IoNotifications className="w-7 h-7 duration-200 hover:text-[#4D45B5] hover:scale-110" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-[55%_43%] gap-4 py-10">
        <div className="container mx-auto px-2 py-4 bg-white rounded-lg shadow-lg">
          <ResultChart />
        </div>
        <div className="container mx-auto px-2 py-4 bg-white rounded-lg shadow-lg">
          <StudentDetailCard student={student} />
        </div>
      </div>

      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold">Result</h2>
          <div className="space-x-4 text-[14px]">
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={examType}
              onChange={(e) => setExamType(e.target.value)}
            >
              <option value="Term 1">Term 1</option>
              <option value="Term 2">Term 2</option>
              <option value="Unit Test">Unit Test</option>
            </select>
            <button
              className="px-8 py-2 text-white bg-[#4D45B5] rounded-full focus:outline-none hover:bg-[#3a35a0]"
              onClick={downloadResults}
            >
              Download
            </button>
          </div>
        </div>
        <ResultTable
          practicalResults={practicalResults}
          theoryResults={theoryResults}
        />
      </div>
    </div>
  );
};

export default PrincipalExamResult;

// export default Billing;

// import { Component, useState } from "react";
// import OwnerInformation from "./OwnerInformation";
// import CompanyInformation from "./CompanyInformation";
// import SubscriptionPlan from "./SubscriptionPlan";
// import BaseLayout from "../../../components/BaseLayout";

// const Billing = () => {
//     const [index, setIndex] = useState(0);

//     const progress = ((index + 1) / 3) * 100;

//     return (
//         <BaseLayout title={"Billing Information"}>
//             <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
//                 <div className="relative w-[95%] h-2 mb-8 bg-gray-300 rounded-full mx-auto">
//                     <div
//                         className="absolute top-0 left-0 h-full rounded-full transition-all duration-300"
//                         style={{
//                             width: `${progress}%`,
//                             backgroundColor: progress === 100 ? 'green' : 'blue',
//                         }}
//                     ></div>
//                 </div>
//                 <div className="flex justify-between px-8 mb-4">
//                     <p>Registration</p>
//                     <p className={`${index === 0 ? 'text-blue-500 font-bold' : ''}`}>
//                         Owner Information
//                     </p>
//                     <p className={`${index === 1 ? 'text-blue-500 font-bold' : ''}`}>
//                         Company Information
//                     </p>
//                     <p className={`${index === 2 ? 'text-blue-500 font-bold' : ''}`}>
//                         Subscription Plan
//                     </p>
//                 </div>

                

//                 <div>
//                     {index === 0 && <OwnerInformation setIndex={setIndex} />}
//                     {index === 1 && <CompanyInformation setIndex={setIndex} />}
//                     {index === 2 && <SubscriptionPlan setIndex={setIndex} />}
//                 </div>
//             </div>
//         </BaseLayout>
//     );
// };

// export default Billing;


import { useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import OwnerInformation from "./OwnerInformation";
import CompanyInformation from "./CompanyInformation";
import SubscriptionPlan from "./SubscriptionPlan";
import BaseLayout from "../../../components/BaseLayout";

const Billing = () => {
    const [index, setIndex] = useState(1); // Start at 1 to indicate "Owner Information"

    return (
        <BaseLayout title={"Billing Information"}>
            <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
                <Stepper
                    activeStep={index}
                    styleConfig={{
                        activeBgColor: 'blue',
                        completedBgColor: 'green',
                        inactiveBgColor: 'gray',
                        completedTextColor: 'white',
                        inactiveTextColor: 'black',
                        size: '2em',  
                        circleFontSize: '1em', 
                    }}
                    connectorStyleConfig={{
                        activeColor: 'blue',
                        completedColor: 'green',
                        size: 2,
                        stepSize: '2em', 
                        borderRadius: '9999px'
                    }}
                    className="w-full"
                >
                    <Step label="Registration" />
                    <Step label="Owner Information" />
                    <Step label="Company Information" />
                    <Step label="Subscription Plan" />
                </Stepper>

                <div className="mt-8">
                    {index === 1 && <OwnerInformation setIndex={setIndex} />}
                    {index === 2 && <CompanyInformation setIndex={setIndex} />}
                    {index === 3 && <SubscriptionPlan setIndex={setIndex} />}
                </div>
            </div>
        </BaseLayout>
    );
};

export default Billing;

// import { useState } from "react";
// import { Stepper, Step } from "react-form-stepper";
// import OwnerInformation from "./OwnerInformation";
// import CompanyInformation from "./CompanyInformation";
// import SubscriptionPlan from "./SubscriptionPlan";
// import BaseLayout from "../../../components/BaseLayout";
// import './css/StepperStyle.css'
// const Billing = () => {
//     const [index, setIndex] = useState(1); // Start at 1 to indicate "Owner Information"

//     return (
//         <BaseLayout title={"Billing Information"}>
//             <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
//                 <Stepper
//                     activeStep={index}
//                     styleConfig={{
//                         activeBgColor: 'blue',
//                         completedBgColor: 'green',
//                         inactiveBgColor: 'transparent', // Transparent background for the connector
//                         completedTextColor: 'white',
//                         inactiveTextColor: 'black',
//                         size: '2em',  // Circle size
//                         circleFontSize: '1em',
//                     }}
//                     connectorStyleConfig={{
//                         size: 0,  // Hide default connector
//                     }}
//                     className="w-full"
//                 >
//                     <Step label="Registration" />
//                     <Step label="Owner Information" />
//                     <Step label="Company Information" />
//                     <Step label="Subscription Plan" />
//                 </Stepper>

//                 <div className="mt-8">
//                     <div className="flex items-center w-full relative">
//                         <div className={`stepper-connector ${index >= 0 ? 'completed' : 'inactive'}`}></div>
//                         <div className={`stepper-connector ${index >= 1 ? 'completed' : 'inactive'}`}></div>
//                         <div className={`stepper-connector ${index >= 2 ? 'active' : 'inactive'}`}></div>
//                     </div>
//                     {index === 1 && <OwnerInformation setIndex={setIndex} />}
//                     {index === 2 && <CompanyInformation setIndex={setIndex} />}
//                     {index === 3 && <SubscriptionPlan setIndex={setIndex} />}
//                 </div>
//             </div>
//         </BaseLayout>
//     );
// };

// export default Billing;


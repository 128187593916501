const HomeServices = () => {
    return(
        <div className="min-h-screen mx-10 flex min-w-screen">
            <div className="min-w-[25%] max-w-[25%] flex flex-col relative my-auto space-y-28 tracking-[1px]">
                <div className="max-w-[90%] self-end p-8 bg-[#F2E1D2] space-y-3 drop-shadow-lg rounded-br-[2.5rem] rounded-tl-[2.5rem] rounded-tr-[1rem] cursor-pointer hover: transition duration-675 hover:scale-110">
                    <h1 className="text-[#E6A23A] font-extrabold text-lg">Lorem Ipsum</h1>
                    <p className="text-base">Lorem ipsum dolor sit amet 
                    consectetur. Diam felis.</p>
                </div>
                <div className="max-w-[90%] self-start p-8 bg-[#D5D3F3] space-y-3 drop-shadow-lg rounded-3xl cursor-pointer hover: transition duration-675 hover:scale-110">
                    <h1 className="text-[#584CF9] font-extrabold text-lg">Lorem Ipsum</h1>
                    <p className="text-base">Lorem ipsum dolor sit amet 
                    consectetur. Diam felis.</p>
                </div>
            </div>
            <div className="min-w-[50%] max-w-[50%] flex flex-col relative my-auto space-y-8 text-center tracking-[1.5px]">
                <h1 className="font-medium text-2xl">Cloud-Based Solution</h1>
                <p className="w-[90%] mx-auto">Lorem ipsum dolor sit amet consectetur. Commodo mi eleifend fringilla massa penatibus dolor nisl vulputate. At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula </p>
            </div>
            <div className="min-w-[25%] max-w-[25%] flex flex-col relative my-auto space-y-28 tracking-[1px]">
                <div className="max-w-[90%] self-end p-8 bg-[#D5D3F3] space-y-3 drop-shadow-lg rounded-3xl cursor-pointer hover: transition duration-675 hover:scale-110">
                    <h1 className="text-[#584CF9] font-extrabold text-lg">Lorem Ipsum</h1>
                    <p className="text-base">Lorem ipsum dolor sit amet 
                    consectetur. Diam felis.</p>
                </div>
                <div className="max-w-[90%] self-start p-8 bg-[#F2E1D2] space-y-3 drop-shadow-lg rounded-br-[2.5rem] rounded-tl-[2.5rem] rounded-tr-[1rem] cursor-pointer hover: transition duration-675 hover:scale-110">
                    <h1 className="text-[#E6A23A] font-extrabold text-lg">Lorem Ipsum</h1>
                    <p className="text-base">Lorem ipsum dolor sit amet consectetur. Diam felis.</p>
                </div>
            </div>
        </div>
    )
}

export default HomeServices;
import React, { useState, useEffect } from "react";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";
import useAxios from "../../utils/axios/useAxios";
import { CgAdd } from "react-icons/cg";

const TaskManager = () => {
  const [tasks, setTasks] = useState([]);
  const [isAddingTask, setIsAddingTask] = useState({ 'in_progress': false, 'completed': false, 'pending': false });
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const axios = useAxiosAuth();

  const { data: response } = useAxios("classrooms/tasks/");

  useEffect(() => {
    if (response) {
      const data = response.map((task) => ({
        ...task,
        status: new Date(task.due_date) < new Date() ? "pending" : task.status,
      }));
      setTasks(data);
    }
  }, [response]);

  const handleAddTask = (e, status) => {
    e.preventDefault();

    const newTask = {
      title: taskTitle,
      description: taskDescription,
      due_date: dueDate,
      status: status,
      organization: response.organization
    };

    axios
      .post(`api/classrooms/tasks/`, newTask)
      .then((res) => {
        // Update the tasks list with the newly added task
        setTasks([...tasks, res.data]);

        // Reset form fields
        setTaskTitle("");
        setTaskDescription("");
        setDueDate("");
        setIsAddingTask({ ...isAddingTask, [status]: false });
      })
      .catch((error) => {
        console.error(
          "API Error:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const handleEdit = (id) => {
    console.log(`Edit task with ID: ${id}`);
  };

  const handleDelete = (id) => {

    const deleteTask = {
      organization: response.organization
    };

    axios
      .delete(`api/classrooms/tasks/${id}/`)
      .then(() => {
        setTasks((prevTasks) => prevTasks.filter((task) => task.id !== id));
      })
      .catch((error) => {
        console.error(
          "Delete Error:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const renderTasks = (status) => {
    return tasks
      .filter((task) => task.status === status)
      .map((task) => (
        <div
          key={task.id}
          className="flex justify-between items-center p-4 mb-4 bg-gray-900 rounded-lg shadow-md"
        >
          <div>
            <h4 className="text-lg font-semibold text-white">{task.title}</h4>
            <p className="text-sm text-gray-400">{task.description}</p>
            <p className="text-xs text-gray-500">
              Due: {new Date(task.due_date).toLocaleDateString()}
            </p>
          </div>
          <div className="flex space-x-4">
            <button
              className="text-sm px-4 py-1 bg-blue-600 text-white rounded-lg hover:bg-blue-500"
              onClick={() => handleEdit(task.id)}
            >
              Edit
            </button>
            <button
              className="text-sm px-4 py-1 bg-red-600 text-white rounded-lg hover:bg-red-500"
              onClick={() => handleDelete(task.id)}
            >
              Delete
            </button>
          </div>
        </div>
      ));
  };

  return (
    <div className="p-8 mx-auto">
      {["in_progress", "completed", "pending"].map((status) => (
        <section key={status} className="mb-8">
          <h2
            className={`text-2xl font-semibold mb-4 text-${
              status === "in_progress"
                ? "green"
                : status === "completed"
                ? "gray"
                : "blue"
            }-400`}
          >
            {status.replace("_", " ").toUpperCase()}
          </h2>
          {renderTasks(status)}
          <hr className="border-gray-700 my-4" />
          {isAddingTask[status] ? (
            <form
              onSubmit={(e) => handleAddTask(e, status)}
              className="p-4 rounded-lg shadow-md bg-gray-900"
            >
              <input
                type="text"
                name="title"
                value={taskTitle}
                onChange={(e) => setTaskTitle(e.target.value)}
                placeholder="Task title"
                className="w-full p-2 mb-2 font-bold text-white bg-gray-800 rounded-lg border-transparent focus:border-transparent focus:ring-0"
              />
              <textarea
                name="description"
                value={taskDescription}
                onChange={(e) => setTaskDescription(e.target.value)}
                placeholder="Task description"
                className="w-full p-2 mb-2 text-white bg-gray-800 rounded-lg border-transparent focus:border-transparent focus:ring-0"
              />
              <input
                type="date"
                name="due_date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                className="mb-2 p-2 text-white bg-gray-800 rounded-lg border border-gray-700 focus:outline-none"
              />
              <hr className="py-2" />
              <div className="flex space-x-4 justify-end">
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-500"
                >
                  Add Task
                </button>
                <button
                  type="button"
                  className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500"
                  onClick={() =>
                    setIsAddingTask({ ...isAddingTask, [status]: false })
                  }
                >
                  Cancel
                </button>
              </div>
            </form>
          ) : (
            <div
              className={`flex items-center cursor-pointer text-${
                status === "in_progress"
                  ? "green"
                  : status === "completed"
                  ? "gray"
                  : "blue"
              }-400 hover:text-${
                status === "in_progress"
                  ? "green"
                  : status === "completed"
                  ? "gray"
                  : "blue"
              }-300`}
              onClick={() =>
                setIsAddingTask({ ...isAddingTask, [status]: true })
              }
            >
              <CgAdd size={20} className="mr-2" />
              <span>Add Task</span>
            </div>
          )}
        </section>
      ))}
    </div>
  );
};

export default TaskManager;

const HomeAboutUs = () => {
    return(
        // <div className="min-h-screen flex ml-6 px-10">
        //     <div className="min-w-[50%] max-w-[50%] my-auto">
        //         <h1 className="text-3xl font-medium py-4">About Us</h1>
        //         <p className="tracking-[1px] leading-8">Lorem ipsum dolor sit amet consectetur. Commodo mi eleifend fringilla massa penatibus dolor nisl vulputate. At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula </p>
        //     </div>
        //     <div className="min-w-[50%] max-w-[50%] p-8 flex justify-end">
        //         <img className="h-[70%] w-[95%] mt-32" src={require("../../../assets/images/home/AboutUsImage.png")} alt="" />
        //     </div>
        // </div>

        <div className="min-h-screen relative">

            <div className="absolute ml-[44rem] mt-28">
                <svg width="61" height="45" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M56.4148 44.068L3.39197 56.926L18.7681 4.57785L56.4148 44.068Z" stroke="#D5D3F3" stroke-width="4"/>
                </svg>
            </div>

            <div className="absolute mt-[38rem] ml-80 flex -space-x-3">
                <svg width="32" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.0612 35.1809L4.10037 7.8587L38.2425 3.36721L25.0612 35.1809Z" stroke="#D5D3F3" stroke-width="4"/>
                </svg>
                <svg width="61" height="45" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M56.4148 44.068L3.39197 56.926L18.7681 4.57785L56.4148 44.068Z" stroke="#D5D3F3" stroke-width="4"/>
                </svg>
            </div>

            <div className="min-h-screen flex ml-6 px-10 relative">
                <div className="min-w-[50%] max-w-[50%] my-auto">
                    <h1 className="text-3xl font-medium py-4">About Us</h1>
                    <p className="tracking-[1px] leading-8 w-[90%]">Lorem ipsum dolor sit amet consectetur. Commodo mi eleifend fringilla massa penatibus dolor nisl vulputate. At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula At augue non a eget molestie lectus et faucibus nunc. Feugiat amet nec eget suspendisse tincidunt condimentum vestibulum sed. Nunc volutpat sit sed semper lorem consectetur congue. Purus pellentesque phasellus tempus cursus ligula </p>
                </div>
                <div className="min-w-[50%] max-w-[50%] p-8 flex justify-end z-10">
                    <img className="h-[70%] w-[95%] mt-32" src={require("../../../assets/images/home/AboutUsImage.png")} alt="" />
                </div>
                <div className="absolute z-0 right-0">
                    <svg width="770" height="650" viewBox="0 0 975 847" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1263 630.956C1263 979.423 980.268 801.668 631.5 801.668C282.732 801.668 0 979.423 0 630.956C0 282.488 282.732 0 631.5 0C980.268 0 1263 282.488 1263 630.956Z" fill="#E6E4F8"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default HomeAboutUs;
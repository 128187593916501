import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ResultChart = () => {
  const data = {
    labels: ['2015', '2016', '2017','2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027'],
    datasets: [
      {
        label: 'Semester 1',
        data: [80,90,67, 65, 59, 80, 81, 56, 55, 40, 65, 70, 75],
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
      },
      {
        label: 'Semester 2',
        data: [54,62,99,28, 48, 40, 19, 86, 27, 90, 50, 60, 65],
        borderColor: 'rgba(153,102,255,1)',
        backgroundColor: 'rgba(153,102,255,0.2)',
      },
    ],
  };

  return (
    <div className="p-4 h-full ">
      <Line data={data}  />
    </div>
  );
};

export default ResultChart;

import React from "react";
import Graph from "../../assets/images/dashboard1.png";
import { Link } from "react-router-dom";
import BaseLayout from "../components/BaseLayout";

const SettingLinks = [
  {
    id: "UserProfile",
    title: "User Profile",
    link: "userProfile",
  },
  {
    id: "ColorTheme",
    title: "Color Theme",
    link: "colorTheme",
  },
  {
    id: "ChangePassword",
    title: "Change Password",
    link: "changePassword",
  },
  {
    id: "Authentication",
    title: "Authentication",
    link: "authentication",
  },
];

//The name color Theme needs to be changed to something else like Time setting or anything as such wen the view changes to student, hr, teacher and parents

const Settings = () => {
  return (
    <BaseLayout title={"Settings"}>
      <div className="grid grid-cols-4 gap-4 pb-8">
        {SettingLinks.map((item) => (
          <Link
            to={`./${item.link}`}
            key={item.id}
            className="w-full h-[15vh] bg-[#EBEAFF] rounded-lg shadow-lg border-[0.8px] border-[#D2CFFC] duration-200 ease-in-out cursor-pointer  hover:-translate-y-1"
          >
            <div className="p-6">
              <h1 className="text-[#4D45B5] font-semibold mb-1">
                {item.title}
              </h1>
              <div className="flex">
                <div className="text-[10px] font-medium text-gray-700 tracking-tighter">
                  Lorem ipsum dolor sit amet consec tetur. Neque diam.
                </div>
                <div>
                  <img
                    src={Graph}
                    alt="graph-dashboard"
                    className="object-cover"
                  />
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </BaseLayout>
  );
};

export default Settings;

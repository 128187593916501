import React from "react";

import CalendarDashboard from "../../components/Dashboard/CalendarDashboard";
import NoticeDashboard from "../../components/Dashboard/NoticeDashboard";
import Layout from "../../components/Dashboard/Layout";

import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const Dashboard = () => {
  const auth = useAuthUser();

  console.log(auth)


  return (
    <Layout>
      <div className="grid grid-cols-4 gap-4 pb-8">
        <div className="w-full h-[15vh] bg-[#EBEAFF] rounded-lg shadow-lg border-[0.8px] border-[#D2CFFC] duration-200 ease-in-out hover:-translate-y-1">
          <div className="p-4">
            <h1 className="text-[#4D45B5] font-semibold">Academic Progress</h1>
            <div className="flex">
              <div className="text-[12px]">
                <span className="font-medium text-gray-700">
                  <span className="text-green-600">10%</span> increase in last
                  30 days{" "}
                </span>
                <br />
                Lorem ipsum dolor, sit amet consectetur
              </div>
              <div className="">
                <img
                  src={require("../../../assets/images/dashboard1.png")}
                  alt="graph-dashboard"
                  className="object-cover"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[15vh] bg-[#FFF7F1] rounded-lg shadow-lg border-[0.8px] border-[#FFE4CF] duration-200 ease-in-out hover:-translate-y-1">
          <div className="p-4">
            <h1 className="text-[#EFAA77] font-semibold">Assignments</h1>
            <div className="flex">
              <div className="text-[12px] w-[80%]">
                Lorem ipsum dolor, sit amet consectetur
              </div>
              <div className="">
                <img
                  src={require("../../../assets/images/dashboard2.png")}
                  alt="assignment-dashboard"
                  className="object-cover mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[15vh] bg-[#EBEAFF] rounded-lg shadow-lg border-[0.8px] border-[#D2CFFC] duration-200 ease-in-out hover:-translate-y-1">
          <div className="p-4">
            <h1 className="text-[#4D45B5] font-semibold">Past Papers</h1>
            <div className="flex">
              <div className="text-[12px] w-[80%]">
                <span className="font-medium text-gray-700">
                  {" "}
                  5 new papers have been added{" "}
                </span>
                <br />
              </div>
              <div className="">
                <img
                  src={require("../../../assets/images/dashboard3.png")}
                  alt="past-paper-dashboard"
                  className="object-cover ml-4"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[15vh] bg-[#FFF7F1] rounded-lg shadow-lg border-[0.8px] border-[#FFE4CF] duration-200 ease-in-out hover:-translate-y-1">
          <div className="p-4">
            <h1 className="text-[#EFAA77] font-semibold">
              View Study Material
            </h1>
            <div className="flex">
              <div className="text-[12px] w-[80%]">
                Lorem ipsum dolor, sit amet consectetur
              </div>
              <div className="">
                <img
                  src={require("../../../assets/images/dashboard4.png")}
                  alt="classroom-dashboard"
                  className="object-cover mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" grid xl:grid-cols-[25%_75%] md:grid-cols-2 gap-5">
        <CalendarDashboard />
        <NoticeDashboard />
      </div>
    </Layout>
  );
};

export default Dashboard;

import React from "react";

const Organization = ({ formData, handleChange }) => {
  return (
    <>
      <div className="mb-2">
        <label
          htmlFor="org_type"
          className="block text-base font-medium text-[#464646]"
        >
          Organization Type
        </label>
        <select
          id="org_type"
          name="org_type"
          value={formData.org_type}
          onChange={handleChange}
          className="w-full p-3 border border-gray-300 text-base rounded text-[#464646]"
          required
        >
          <option value="" disabled>
            Select Organization Type
          </option>
          <option value="SCHOOL">School</option>
          <option value="COLLEGE">College</option>
          <option value="UNIVERSITY">University</option>
        </select>
      </div>
      <div className="mb-2">
        <label
          htmlFor="org_name"
          className="block text-base font-medium text-[#464646]"
        >
          Organization Name
        </label>
        <input
          type="text"
          id="org_name"
          name="org_name"
          placeholder="Organization Name"
          value={formData.org_name}
          onChange={handleChange}
          className="w-full p-3 border border-gray-300 text-base rounded"
          required
        />
      </div>
      <div className="mb-2">
        <label
          htmlFor="org_phone_number"
          className="block text-base font-medium text-[#464646]"
        >
          Organization Contact
        </label>
        <input
          type="text"
          id="org_phone_number"
          name="org_phone_number"
          placeholder="Organization Contact"
          value={formData.org_phone_number}
          onChange={handleChange}
          className="w-full p-3 border border-gray-300 text-base rounded"
          required
        />
      </div>
      <div className="mb-2">
        <label
          htmlFor="org_email"
          className="block text-base font-medium text-[#464646]"
        >
          Organization Email
        </label>
        <input
          type="email"
          id="org_email"
          name="org_email"
          placeholder="Organization Email"
          value={formData.org_email}
          onChange={handleChange}
          className="w-full p-3 border border-gray-300 text-base rounded"
          required
        />
      </div>
      <div className="mb-2">
        <label
          htmlFor="org_website"
          className="block text-base font-medium text-[#464646]"
        >
          Organization Website
        </label>
        <input
          type="text"
          id="org_website"
          name="org_website"
          placeholder="Organization Website"
          value={formData.org_website}
          onChange={handleChange}
          className="w-full p-3 border border-gray-300 text-base rounded"
          required
        />
      </div>
      <div className="mb-2">
        <label
          htmlFor="org_address"
          className="block text-base font-medium text-[#464646]"
        >
          Organization Address
        </label>
        <input
          type="text"
          id="org_address"
          name="org_address"
          placeholder="Organization Address"
          value={formData.org_address}
          onChange={handleChange}
          className="w-full p-3 border border-gray-300 text-base rounded"
          required
        />
      </div>
    </>
  );
};

export default Organization;

import React from "react";

const ProgressBar = ({ steps, currentStep }) => {
  return (
    <div className="flex justify-between items-center mb-2">
      {steps.map((step, index) => (
        <React.Fragment key={step.number}>
          <div className="flex flex-col items-center">
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                currentStep >= step.number
                  ? "bg-[#4D45B5] text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
            >
              {step.number}
            </div>
            <div
              className={`m-2 text-xs ${
                currentStep >= step.number ? "text-[#4D45B5]" : "text-gray-500"
              }`}
            >
              {step.name}
            </div>
          </div>
          {index < steps.length - 1 && (
            <div
              className={`flex-1 h-0.5 mb-8 align-middle ${
                currentStep > step.number ? "bg-[#4D45B5]" : "bg-gray-300"
              }`}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgressBar;

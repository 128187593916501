import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { SUCCESS_URL, SECRET, FAILURE_URL } from "./config";
// import EsewaTransactionVerification from '../../utils/axios/EsewaTransactionVerification'; 
const EsewaForm = () => {
  const [formData, setFormData] = useState({
    amount: "100",
    tax_amount: "0",
    total_amount: "100",
    transaction_uuid: "11-200-111sss1",
    product_code: "EPAYTEST",
    product_service_charge: "0",
    product_delivery_charge: "0",
    success_url: SUCCESS_URL,
    failure_url: FAILURE_URL,
    signed_field_names: "total_amount,transaction_uuid,product_code",
    signature: "",
    secret: SECRET
  });

  const generateSignature = () => {
    const currentTime = new Date();
    const formattedTime = currentTime.toISOString().slice(2, 10).replace(/-/g, '') + '-' + currentTime.getHours() +
      currentTime.getMinutes() + currentTime.getSeconds();
    const updatedFormData = { ...formData, transaction_uuid: formattedTime };

    const { total_amount, transaction_uuid, product_code, secret } = updatedFormData;

    const hash = CryptoJS.HmacSHA256(
      `total_amount=${total_amount},transaction_uuid=${transaction_uuid},product_code=${product_code}`,
      secret
    );
    const hashInBase64 = CryptoJS.enc.Base64.stringify(hash);

    setFormData({ ...updatedFormData, signature: hashInBase64 });
  };

  useEffect(() => {
    generateSignature();
  }, [formData.total_amount, formData.transaction_uuid, formData.product_code, formData.secret]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  return (
    <div>
      <form action="https://rc-epay.esewa.com.np/api/epay/main/v2/form" method="POST" onSubmit={generateSignature} target="_blank">
        <table>
          <tbody>
            <tr>
              <td><strong>Parameter</strong></td>
              <td><strong>Value</strong></td>
            </tr>
            {Object.keys(formData).map((key) => (
              key !== "signature" && (
                <tr key={key}>
                  <td>{key.replace(/_/g, ' ')}:</td>
                  <td>
                    <input
                      type="text"
                      id={key}
                      name={key}
                      value={formData[key]}
                      className="form"
                      required
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              )
            ))}
            <tr>
              <td>Signature:</td>
              <td><input type="text" id="signature" name="signature" value={formData.signature} className="form" required readOnly /></td>
            </tr>
          </tbody>
        </table>
        <input type="submit" value="Pay with eSewa" className="button" style={{ display: 'block', backgroundColor: '#60bb46', cursor: 'pointer', color: '#fff', border: 'none', padding: '5px 10px' }} />
      </form>
    </div>
  );
};

export default EsewaForm;

import { useEffect, useRef } from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

import axiosInstance from "./axios";

const useAxiosAuth = () => {
  const authHeader = useAuthHeader();

  const interceptorRef = useRef(null);

  useEffect(() => {
    interceptorRef.current = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = authHeader;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      axiosInstance.interceptors.request.eject(interceptorRef.current);
    };
  }, [authHeader]);

  return axiosInstance;
};

export default useAxiosAuth;

// useAxiosAuth.js
// import axios from 'axios';
// import useAuthUser  from "react-auth-kit/hooks/useAuthUser";

// const useAxiosAuth = () => {
//     const auth = useAuthUser(); // This should be a function returning user details

//     const axiosInstance = axios.create({
//         baseURL: 'http://192.168.31.92:8001',
//     });

//     axiosInstance.interceptors.request.use(
//         (config) => {
//             const user = auth;
//             if (user && user.token) {
//                 config.headers['Authorization'] = `Bearer ${user.token}`;
//             }
//             return config;
//         },
//         (error) => {
//             return Promise.reject(error);
//         }
//     );

//     return axiosInstance;
// };

// export default useAxiosAuth;

import React, { useEffect, useState } from "react";
import useAxios from "../../../utils/axios/useAxios";

const StudentDetailCard = ({ parent }) => {
  const { data: classRooms, error: classRoomsError } = useAxios(
    "classrooms/classrooms"
  );
  const { data: students, error: studentsError } = useAxios(
    "classrooms/students"
  );
  const [student, setStudent] = useState(null);
  const [classRoom, setClassRoom] = useState(null);

  useEffect(() => {
    if (students && classRooms) {
      // Find the student based on the parent's profile
      const foundStudent = students.find(
        (student) => parent.profile.id === student.id
      );
      setStudent(foundStudent);

      // If the student is found, find the associated classroom
      if (foundStudent) {
        const foundClassRoom = classRooms.find(
          (classRoom) => foundStudent.classroom === classRoom.id
        );
        setClassRoom(foundClassRoom);
      }
    }
  }, [students, classRooms, parent.profile.id]);

  if (!student) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full p-4 items-center">
      <div>
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold mb-4">Student Detail</h2>
          <div>
            <img
              src={student.photo} // Update to use the student's photo
              alt="Student"
              className="w-24 h-24 rounded-full object-cover"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <p className="font-medium text-gray-600">
              Name:{" "}
              <span className="text-gray-800">
                {student.first_name} {student.last_name}
              </span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Address: <span className="text-gray-800">{student.address}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Class:{" "}
              <span className="text-gray-800">
                {classRoom ? classRoom.name : "Loading..."}
              </span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Section:{" "}
              <span className="text-gray-800">
                {classRoom && classRoom.curriculum_data
                  ? classRoom.curriculum_data.name
                  : "N/A"}
              </span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Roll No: <span className="text-gray-800">{student.id}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Attendance:{" "}
              <span className="text-gray-800">{student.attendance}%</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDetailCard;

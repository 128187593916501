import React, { useState } from "react";
import BaseLayout from "../../components/BaseLayout";

const BalanceSheet = () => {
  const balanceSheetData = [
    {
      date: "2024-02-11",
      revenues: [
        { description: "Product Sales", amount: 5000000 },
        { description: "Service Income", amount: 3000000 },
        { description: "Interest Income", amount: 1500000 },
        { description: "Dividend Income", amount: 1200000 },
      ],
      expenses: [
        { description: "Rent", amount: 2000000 },
        { description: "Salaries", amount: 3500000 },
        { description: "Utilities", amount: 4000000 },
        { description: "Marketing", amount: 5500000 },
      ],
    },
    {
      date: "2024-03-15",
      revenues: [
        { description: "Product Sales", amount: 4800000 },
        { description: "Service Income", amount: 3200000 },
        { description: "Interest Income", amount: 1800000 },
        { description: "Dividend Income", amount: 1400000 },
      ],
      expenses: [
        { description: "Rent", amount: 2200000 },
        { description: "Salaries", amount: 3300000 },
        { description: "Utilities", amount: 4200000 },
        { description: "Marketing", amount: 6000000 },
      ],
    },
    {
      date: "2024-04-10",
      revenues: [
        { description: "Product Sales", amount: 4700000 },
        { description: "Service Income", amount: 3100000 },
        { description: "Interest Income", amount: 1400000 },
        { description: "Dividend Income", amount: 1600000 },
      ],
      expenses: [
        { description: "Rent", amount: 2400000 },
        { description: "Salaries", amount: 3400000 },
        { description: "Utilities", amount: 4300000 },
        { description: "Marketing", amount: 7000000 },
      ],
    },
  ];

  const [selectedDate, setSelectedDate] = useState(balanceSheetData[0].date);

  const filteredData = balanceSheetData.find(
    (item) => item.date === selectedDate
  ) || {
    revenues: [],
    expenses: [],
  };

  const totalRevenues = filteredData.revenues.reduce(
    (acc, item) => acc + item.amount,
    0
  );
  const totalExpenses = filteredData.expenses.reduce(
    (acc, item) => acc + item.amount,
    0
  );

  return (
    <BaseLayout title={"Balance Sheet"}>
      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex space-x-4">
          <label className="block text-sm font-medium text-gray-700 my-auto">
            Select Date
          </label>
          <select
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="mt-1 block w-[14vw] pl-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            {balanceSheetData.map((item) => (
              <option key={item.date} value={item.date}>
                {item.date}
              </option>
            ))}
          </select>
        </div>
        <hr className="my-4" />

        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-[#EAECFF]">
              <th className="px-6 py-3 text-left text-lg font-medium text-gray-900 border-b border-r border-gray-300">
                S.N
              </th>
              <th className="px-6 py-3 text-left text-lg font-medium text-gray-900 border-b border-r border-gray-300">
                Liabilities
              </th>
              <th className="px-6 py-3 text-center text-lg font-medium text-gray-900 border-b border-r-2 border-r-[#B7B7B7]">
                Amount
              </th>
              <th className="px-6 py-3 text-left text-lg font-medium text-gray-900 border-b border-r border-gray-300">
                Assets
              </th>
              <th className="px-6 py-3 text-center text-lg font-medium text-gray-900 border-b border-gray-300">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.revenues.map((item, index) => (
              <tr key={index}>
                <td className="px-6 py-4 border-b border-r border-gray-300">
                  {index + 1}
                </td>
                <td className="px-6 py-4 border-b border-r border-gray-300">
                  {item.description}
                </td>
                <td className="px-6 py-4 border-b border-r-2 border-r-[#B7B7B7] text-center">
                  Rs. {item.amount.toLocaleString()}
                </td>
                {filteredData.expenses[index] && (
                  <>
                    <td className="px-6 py-4 border-b border-r border-gray-300">
                      {filteredData.expenses[index].description}
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-center">
                      Rs. {filteredData.expenses[index].amount.toLocaleString()}
                    </td>
                  </>
                )}
              </tr>
            ))}
            <tr>
              <td className="px-6 py-4 font-semibold border-t border-r border-gray-300"></td>
              <td className="px-6 py-4 font-semibold border-t border-r border-gray-300">
                Total Revenue
              </td>
              <td className="px-6 py-4 font-semibold border-t border-r-2 border-r-[#B7B7B7] text-center">
                Rs. {totalRevenues.toLocaleString()}
              </td>
              <td className="px-6 py-4 font-semibold border-t border-r border-gray-300">
                Total Expense
              </td>
              <td className="px-6 py-4 font-semibold border-t border-gray-300 text-center">
                Rs. {totalExpenses.toLocaleString()}
              </td>
            </tr>

            <tr className="bg-gray-50">
              <td
                className="px-32 py-4 font-semibold text-lg  border-t border-r-2 "
                colSpan="4"
              >
                Grand Total
              </td>
              <td className="px-6 py-4 font-semibold border-t border-gray-300 text-center">
                Rs. {(totalRevenues - totalExpenses).toLocaleString()}
              </td>
            </tr>
          </tbody>
          {/* <tfoot>
            <tr className="bg-gray-50">
              <td
                className="px-6 pl-2 py-4 font-semibold text-lg text-center border-t border-r-2 border-r-[#B7B7B7]"
                colSpan="4"
              >
                Grand Total
              </td>
              <td className="px-6 py-4 font-semibold text-lg text-center border-t border-r-2 border-r-[#B7B7B7]">
                Rs. {(totalRevenues - totalExpenses).toLocaleString()}
              </td>
            </tr>
          </tfoot> */}
        </table>
      </div>
    </BaseLayout>
  );
};

export default BalanceSheet;

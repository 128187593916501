import React from "react";
import ToDoList from "../../components/ToDoList";
import BaseLayout from "../../components/BaseLayout";

const AccountingToDoList = () => {
  return (
    <BaseLayout title={"To-Do List"}>
      <ToDoList />;
    </BaseLayout>
  );
};

export default AccountingToDoList;

import React from "react";

const AddEvent = () => {
  return (
    <form className="w-[80%] py-10 px-4 md:px-6 lg:px-8 bg-white rounded-lg shadow-lg space-y-6">
      <div className="flex flex-col md:flex-row px-4 items-center">
        <label
          className="w-full md:w-[20%] text-base font-medium text-gray-700"
          htmlFor="title"
        >
          Title
        </label>
        <input
          type="text"
          className="w-full md:w-[80%] mt-2 md:mt-0 pl-4 border border-gray-300 rounded-md bg-[#F9FAFB]"
          placeholder="Event title"
          id="title"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-[20%_30%_20%_30%] px-4 gap-x-0 items-center">
        <label className="text-base font-medium text-gray-700" htmlFor="date">
          Event Date
        </label>
        <input
          type="date"
          className="w-full pl-4 border border-gray-300 text-gray-500 rounded-md bg-[#F9FAFB]"
          id="date"
        />

        <label
          className="text-base md:text-center font-medium text-gray-700"
          htmlFor="venue"
        >
          Event Venue
        </label>
        <input
          type="text"
          className="w-full pl-4 border border-gray-300 rounded-md bg-[#F9FAFB]"
          placeholder="Event Venue"
          id="venue"
        />
      </div>
      <div className="flex flex-col md:flex-row px-4 items-start mt-2">
        <label
          className="w-full md:w-[20%] text-base font-medium text-gray-700"
          htmlFor="description"
        >
          Event Description
        </label>
        <textarea
          className="w-full md:w-[80%] mt-2 md:mt-0 pl-4 h-28 border border-gray-300 rounded-md bg-[#F9FAFB]"
          placeholder="Event Description.."
          id="description"
        ></textarea>
      </div>

      <div className="text-center flex flex-col md:flex-row justify-end space-y-4 md:space-y-0 md:space-x-4 text-md mx-4">
        <button className="px-4 py-2 bg-[#4D45B5] w-full md:w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide">
          Post
        </button>
        <button className="px-4 py-2 text-[#B54545] w-full md:w-[10rem] border-2 border-[#D46067] font-medium tracking-wide rounded-lg hover:bg-[#D46067] hover:text-white hover:ease-in-out hover:transition duration-[550ms]">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddEvent;

import React, { useState } from "react";
import BaseLayout from "../components/BaseLayout";

const Authentication = () => {
  const [selectedDivs, setSelectedDivs] = useState([]);

  const toggleDivSelection = (divName) => {
    if (selectedDivs.includes(divName)) {
      setSelectedDivs(selectedDivs.filter((name) => name !== divName));
    } else {
      setSelectedDivs([...selectedDivs, divName]);
    }
  };

  return (
    <BaseLayout title={"Settings"}>
      <div className="w-[55%] p-8 bg-white rounded-lg shadow-lg">
        <h1 className="font-semibold text-2xl mb-7">Authentication</h1>

        <div
          className={`p-4 rounded-2xl shadow-lg bg-[#F6F5FC] text-justify mb-6 cursor-pointer ${
            selectedDivs.includes("emailOtp") ? "border-2 border-[#4D45B5]" : ""
          }`}
          onClick={() => toggleDivSelection("emailOtp")}
        >
          <h1 className="font-medium text-xl text-[#4D45B5]">Email OTP</h1>
          <p className="text-[#232020b3] text-sm mb-4">
            Lorem ipsum dolor sit amet consectetur. Quis sed lobortis donec et
            ipsum tristique. Enim suspendisse amet enim enim hac egestas orci.
            Etiam aenean quis aenean.
          </p>
        </div>

        <div
          className={`p-4 rounded-2xl shadow-lg bg-[#F6F5FC] text-justify mb-6 cursor-pointer ${
            selectedDivs.includes("authApp") ? "border-2 border-[#4D45B5]" : ""
          }`}
          onClick={() => toggleDivSelection("authApp")}
        >
          <h1 className="font-medium text-xl text-[#4D45B5]">
            Authentication App
          </h1>
          <p className="text-[#232020b3] text-sm mb-4">
            Lorem ipsum dolor sit amet consectetur. Quis sed lobortis donec et
            ipsum tristique. Enim suspendisse amet enim enim hac egestas orci.
            Etiam aenean quis aenean.
          </p>
        </div>
        {/* <div
          className={`p-4 rounded-2xl shadow-lg bg-[#F6F5FC] text-justify mb-6 cursor-pointer ${
            selectedDivs.includes("smsOtp") ? "border-2 border-[#4D45B5]" : ""
          }`}
          onClick={() => toggleDivSelection("smsOtp")}
        >
          <h1 className="font-medium text-xl text-[#4D45B5]">SMS OTP</h1>
          <p className="text-[#232020b3] text-sm mb-4">
            Lorem ipsum dolor sit amet consectetur. Quis sed lobortis donec et
            ipsum tristique. Enim suspendisse amet enim enim hac egestas orci.
            Etiam aenean quis aenean.
          </p>
        </div> */}
      </div>
    </BaseLayout>
  );
};

export default Authentication;

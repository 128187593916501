import React from "react";
import BaseLayout from "../../components/BaseLayout";
import SalaryInformation from "../../components/SalaryInformation";

const TeacherSalaryInformation = () => {
  return (
    <BaseLayout title={"Salary Information"}>
      <SalaryInformation />
    </BaseLayout>
  );
};

export default TeacherSalaryInformation;

import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import circleBlue from "../../assets/images/contactus/circleBlue.svg";
import contactLanding from "../../assets/images/contactus/contactLanding.svg";

function DesktopView() {
  return (
    <div className="w-screen">
      <div className="flex flex-col md:flex-row justify-between">
        <div className="w-full md:w-3/5 lg:w-4/5 mx-4 md:ml-14 lg:ml-16 md:mr-8 lg:mr-12 my-6 p-8 bg-[#EDEFF8] rounded-2xl shadow-lg z-10 h-max">
          <h1 className="font-poppins text-2xl md:text-4xl font-semibold mb-3">
            Get in touch
          </h1>
          <p className="text-sm mb-8 text-[#282528] text-justify">
            Lorem ipsum dolor sit amet consectetur. Nibh ut imperdiet quis
            interdum sed turpis sit. Semper purus faucibus tellus eu in
            tristique pretium placerat. Amet potenti urna auctor elit.
          </p>
          <form className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2 md:gap-x-6 md:gap-y-2 lg:gap-x-8 lg:gap-y-4">
            <div>
              <label
                htmlFor="firstName"
                className="block font-medium text-sm my-1 md:my-2"
              >
                First name
              </label>
              <input
                type="text"
                id="firstName"
                className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
                placeholder="Lorem Ipsum"
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block font-medium text-sm my-1 md:my-2"
              >
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
                placeholder="Lorem Ipsum"
              />
            </div>
            <div>
              <label
                htmlFor="organization"
                className="block font-medium text-sm my-1 md:my-2"
              >
                Organization Name
              </label>
              <input
                type="text"
                id="organization"
                className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
                placeholder="Lorem Ipsum"
              />
            </div>
            <div>
              <label
                htmlFor="location"
                className="block font-medium text-sm my-1 md:my-2"
              >
                Location
              </label>
              <input
                type="text"
                id="location"
                className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
                placeholder="Lorem Ipsum"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block font-medium text-sm my-1 md:my-2"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
                placeholder="Lorem Ipsum"
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block font-medium text-sm my-1 md:my-2"
              >
                Phone Number
              </label>
              <div className="relative">
                <select
                  id="country"
                  className="border-none rounded-ss-lg rounded-es-lg text-sm w-3/12 py-2.5 pl-4 leading-tight font-medium"
                >
                  <option value="NEP">NEP</option>
                </select>
                <input
                  type="tel"
                  id="phone"
                  className="border-none rounded-ee-lg rounded-se-lg text-sm w-9/12 py-2.5 px-4 leading-tight"
                  placeholder="+977-9823414132"
                />
              </div>
            </div>
            <div className="col-span-2">
              <label
                htmlFor="message"
                className="block font-medium text-sm my-1 md:my-2"
              >
                Message
              </label>
              <textarea
                id="message"
                className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
                placeholder="Lorem Ipsum"
                rows={3}
              />
            </div>
            <div className="flex col-span-2 justify-end">
              <button
                type="submit"
                className="text-white text-sm font-medium py-1 px-10 bg-[#4D45B5] rounded-md"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="relative mt-8 md:mt-0 md:right-0 top-0 md:top-[-13vh] -z-[1] h-[50vh] md:h-[75vh] flex justify-center">
          <img src={contactLanding} alt="contact landing" />
        </div>
      </div>
      <img
        src={circleBlue}
        alt="circle decoration"
        className="absolute top-[50vh] z-0 -mb-[55vh] hidden md:block"
      />
    </div>
  );
}

function MobileView() {
  return (
    <div className="m-6 sm:mx-8 sm:my-6 md:m-10 p-8 bg-[#EDEFF8] rounded-2xl shadow-lg z-10 h-max">
      <h1 className="font-poppins text-xl sm:text-2xl md:text-3xl font-semibold mb-2 sm:mb-1 md:mb-2">
        Get in touch
      </h1>
      <p className="text-sm mb-2 sm:mb-2 md:mb-3 text-[#282528] text-justify">
        Lorem ipsum dolor sit amet consectetur. Nibh ut imperdiet quis interdum
        sed turpis sit. Semper purus faucibus tellus eu in tristique pretium
        placerat. Amet potenti urna auctor elit.
      </p>
      <form className="grid grid-cols-1 gap-y-1">
        <label
          htmlFor="firstName"
          className="block font-medium text-sm my-1 md:my-2"
        >
          First name
        </label>
        <input
          type="text"
          id="firstName"
          className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
          placeholder="Lorem Ipsum"
        />
        <label
          htmlFor="lastName"
          className="block font-medium text-sm my-1 md:my-2"
        >
          Last Name
        </label>
        <input
          type="text"
          id="lastName"
          className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
          placeholder="Lorem Ipsum"
        />
        <label
          htmlFor="organization"
          className="block font-medium text-sm my-1 md:my-2"
        >
          Organization Name
        </label>
        <input
          type="text"
          id="organization"
          className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
          placeholder="Lorem Ipsum"
        />
        <label
          htmlFor="location"
          className="block font-medium text-sm my-1 md:my-2"
        >
          Location
        </label>
        <input
          type="text"
          id="location"
          className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
          placeholder="Lorem Ipsum"
        />
        <label
          htmlFor="email"
          className="block font-medium text-sm my-1 md:my-2"
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
          placeholder="Lorem Ipsum"
        />
        <label
          htmlFor="phone"
          className="block font-medium text-sm my-1 md:my-2"
        >
          Phone Number
        </label>
        <div className="relative">
          <select
            id="country"
            className="border-none rounded-ss-lg rounded-es-lg text-sm w-3/12 py-2.5 md:pl-4 pl-2 leading-tight font-medium"
          >
            <option value="NEP">NEP</option>
          </select>
          <input
            type="tel"
            id="phone"
            className="border-none rounded-ee-lg rounded-se-lg text-sm w-9/12 py-2.5 px-4 leading-tight"
            placeholder="+977-9823414132"
          />
        </div>
        <label
          htmlFor="message"
          className="block font-medium text-sm my-1 md:my-2"
        >
          Message
        </label>
        <textarea
          id="message"
          className="border-none rounded-lg text-sm w-full py-2.5 px-4 leading-tight"
          placeholder="Lorem Ipsum"
          rows={3}
        />
        <div className="flex justify-end">
          <button
            type="submit"
            className="text-white text-sm font-medium py-1 mt-1 px-10 bg-[#4D45B5] rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

function Contact() {
  const isMobile = useMediaQuery("(max-width: 1024px)");

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
}

export default Contact;

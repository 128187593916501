import React, { useState } from "react";
import { IoNotifications } from "react-icons/io5";
import { useParams, Link } from "react-router-dom";
import { MdAttachFile, MdDeleteOutline } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import useAxios from "../../../utils/axios/useAxios";
import useAxiosAuth from "../../../utils/axios/useAxiosAuth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const AssignmentDetail = () => {
  const user = useAuthUser();

  const [fileAttachment, setFileAttachment] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [student, setStudent] = useState(null);
  const { assignmentId } = useParams();
  const { data, error } = useAxios(`classrooms/assignments/${assignmentId}`);
  const { data: students, error: studentError } =
    useAxios(`classrooms/students/`);

  const axios = useAxiosAuth();

  React.useEffect(() => {
    if (students && user) {
      const currentUser = students.find(
        (student) => student.id === user.profile.id
      );

      setStudent(currentUser);
    }
  }, [students, user]);

  function formatDate(date) {
    // Get the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    // Get timezone offset in minutes
    const offset = date.getTimezoneOffset();
    const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, "0");
    const offsetSign = offset > 0 ? "-" : "+";

    // Format the date string
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
  }

  const addAssignment = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const formattedDate = formatDate(new Date());

    // Create a FormData object
    const formData = new FormData();
    formData.append("remarks", remarks);
    formData.append("assignment", data.id);
    formData.append("submission_date", formattedDate);
    if (student) {
      formData.append("student", student.id);
    }

    if (fileAttachment) {
      formData.append("attachment", fileAttachment);
    } else {
      console.error("No file attached.");
    }

    axios
      .post("api/classrooms/assignment-submissions/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Reset form fields
        setFileAttachment(null);
        setRemarks("");

        toast.success("Assignment submitted successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Assignment submission failed!");
      });
  };

  if (!data) {
    return (
      <div className="max-w-screen-2xl mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-semibold text-red-600">
          Assignment not found
        </h2>
        <p className="mt-4">
          The assignment you are looking for does not exist. Please check the
          URL or return to the assignment list.
        </p>
        <Link
          to="/student/assignment"
          className="mt-6 inline-block px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
        >
          Go Back to Assignments
        </Link>
      </div>
    );
  }

  return (
    <div className="max-w-screen-2xl mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
      <div className="flex justify-between items-center mb-12">
        <div>
          <h1 className="text-2xl font-semibold text-[#5964DB]">
            Teacher's Content
          </h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-[#928DD3] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4D45B5] focus:border-transparent"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-[#928DD3]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35M17.65 10.65a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
          <div className="text-[#928DD3]">
            <IoNotifications className="w-7 h-7 mt-2 duration-200 hover:text-[#4D45B5] hover:scale-110" />
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <Link
            to="/student/assignment"
            className="text-lg font-semibold text-gray-800"
          >
            &larr; Assignments
          </Link>
        </div>
        <hr className="my-4" />
        <div className="px-10">
          <div className="border border-gray-200">
            <div className="p-6">
              <h2 className="text-xl font-semibold mb-4">{data.title}</h2>
              <div className="mb-4 flex">
                <p className="font-medium w-48">Assignment Description:</p>
                <ul className="list-disc ml-4 list-inside text-gray-600">
                  {data.description}
                </ul>
              </div>
              <div className="mb-4 flex">
                <p className="font-medium w-48">Assigned Material:</p>
                <a
                  href={data.attachment}
                  className="flex items-center ml-4 text-blue-500 cursor-pointer"
                  download
                >
                  <IoMdDownload />
                  <p className="mx-2">Download Attachment</p>
                </a>
              </div>
              <hr />
              <div className="mt-6">
                <h2 className="text-xl font-semibold mb-4">
                  Assignment Submission
                </h2>
                <form onSubmit={addAssignment}>
                  <div className="mb-4 flex">
                    <label className="font-medium my-auto w-32">
                      Assignment File:
                    </label>
                    <div className="flex w-[50vw]">
                      <div className="mx-4 flex justify-between items-center w-[80%] border rounded-sm p-2 bg-[#1D306D]/5">
                        <div className="flex gap-2 text-[16px] text-gray-600 cursor-pointer items-center">
                          <MdAttachFile className="text-lg" />
                          {fileAttachment ? (
                            <span>{fileAttachment.name}</span>
                          ) : (
                            <span>Attach your file here</span>
                          )}
                          <input
                            type="file"
                            name="fileAttachment"
                            id="fileAttachment"
                            className="hidden"
                            onChange={(e) => {
                              if (e.target.files.length > 0) {
                                setFileAttachment(e.target.files[0]);
                              }
                            }}
                          />
                        </div>
                        {fileAttachment && (
                          <MdDeleteOutline
                            className="text-red-400 text-lg cursor-pointer"
                            onClick={() => {
                              setFileAttachment(null);
                              document.getElementById("fileAttachment").value =
                                null;
                            }}
                          />
                        )}
                      </div>
                      <div className="">
                        <input
                          type="button"
                          value={"Add File"}
                          className="cursor-pointer border-2 border-[#4D45B5] font-semibold text-[#4D45B5] rounded-xl w-full py-2 px-6"
                          onClick={() =>
                            document.getElementById("fileAttachment").click()
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 flex">
                    <label className="font-medium my-auto w-40">Remarks:</label>
                    <textarea
                      id="remarks"
                      rows="4"
                      className="block p-2.5 w-full text-[16px] text-gray-600 bg-[#1D306D]/5 border border-gray-200 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Remarks..."
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="w-full flex justify-end items-end">
                    <button
                      type="submit"
                      className="px-6 py-2 bg-[#4D45B5] text-white duration-200 rounded-lg hover:bg-[#4D45B5]/90"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentDetail;

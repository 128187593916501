import React, { useState } from "react";
import BaseLayout from "../../components/BaseLayout";

import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useAxiosAuth from "../../../utils/axios/useAxiosAuth";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// user id, and leave days hasnt been mapped, waiting for api change
const LeaveRequest = () => {
  const user = useAuthUser();
  const axios = useAxiosAuth();

  const [title, setTitle] = useState("");
  const [reason, setReason] = useState("");
  const [start_date, setStartDate] = useState("00/00/0000");
  const [end_date, setEndDate] = useState("00/00/0000");

  // leave days havent been mapped, waiting for api change
  let leavesAllowed = 50;
  let leavesTaken = 10;
  let leavesRemaining = leavesAllowed - leavesTaken;

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const submitLeaveRequest = (event) => {
    event.preventDefault();

    // Create a FormData object
    const formData = new FormData();

    // Append each field to the FormData object
    formData.append("title", title);
    formData.append("reason", reason);
    formData.append("start_date", formatDate(start_date));
    formData.append("end_date", formatDate(end_date));
    formData.append("teacher", user.id); // user id hasnt been mapped, waiting for api change

    // Log FormData content (for debugging)
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    axios
      .post("api/classrooms/leaves/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Reset form fields
        setTitle("");
        setReason("");
        setStartDate("00/00/0000");
        setEndDate("00/00/0000");
        toast.success("Leave request uploaded successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Leave request upload request has failed!");
      });
  };

  return (
    <BaseLayout title={"Leave Request"}>
      <div className=" w-[80%] grid grid-cols-3 gap-8 pb-8">
        <div className="w-full border border-[#928DD3] bg-white rounded-xl shadow-lg">
          <div className="p-6">
            <h1 className="text-3xl mb-2">{leavesTaken}</h1>
            <div className="text-sm text-[#0000008c]">Days of leave taken</div>
          </div>
        </div>
        <div className="w-full border border-[#928DD3] bg-white rounded-xl shadow-lg">
          <div className="p-6">
            <h1 className="text-3xl mb-2">{leavesRemaining}</h1>
            <div className="text-sm text-[#0000008c]">
              Days of leave available
            </div>
          </div>
        </div>
        <div className="w-full border border-[#928DD3] bg-white rounded-xl shadow-lg">
          <div className="p-6">
            <h1 className="text-3xl mb-2">{leavesAllowed}</h1>
            <div className="text-sm text-[#0000008c]">
              Total allocated leaves
            </div>
          </div>
        </div>
      </div>
      <div className=" w-[80%] py-4 px-8 bg-white rounded-lg shadow-lg">
        <form className="grid grid-cols-4 gap-y-5 gap-x-1 p-5 items-center">
          <label
            htmlFor="title"
            className="first-letter:text-base text-left font-medium text-gray-700 "
          >
            Title
          </label>
          <input
            type="text"
            id="title"
            className="pl-3 border border-gray-300 rounded-md bg-[#F9FAFB] col-span-3"
            placeholder="Leave title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <label
            htmlFor="reason"
            className="text-base text-left font-medium text-gray-700 col-start-1"
          >
            Leave Reason
          </label>
          <textarea
            type="text"
            id="reason"
            className="pl-3 border border-gray-300 rounded-md bg-[#F9FAFB] col-span-3"
            placeholder="Leave Reason"
            required
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          ></textarea>

          <label
            htmlFor="start_date"
            className="text-base text-left font-medium text-gray-700 "
          >
            Start date
          </label>
          <input
            type="date"
            id="start_date"
            className="w-full pl-3 border border-gray-300 text-gray-500 rounded-md bg-[#F9FAFB]"
            required
            value={start_date}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <label
            htmlFor="end_date"
            className="text-base text-center font-medium text-gray-700 "
          >
            End date
          </label>
          <input
            type="date"
            id="end_date"
            className="w-full pl-3 border border-gray-300 text-gray-500 rounded-md bg-[#F9FAFB]"
            required
            value={end_date}
            onChange={(e) => setEndDate(e.target.value)}
          />

          <div className="text-center col-span-4 flex justify-end text-sm space-x-4 mt-4">
            <button
              type="Submit"
              className="px-4 py-2 bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
              onClick={submitLeaveRequest}
            >
              Request
            </button>
            <button className="px-4 py-2 text-[#B54545] w-[10rem] border-2 border-[#D46067] font-medium tracking-wide rounded-lg hover:bg-[#D46067] hover:text-white hover:ease-in-out hover:transition duration-[550ms]">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </BaseLayout>
  );
};

export default LeaveRequest;

import { act, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import OwnerInformation from "./OwnerInformation";
import SubscriptionPlan from "./SubscriptionPlan";

const CompanyInformation = ({setIndex}) => {

    const [activeComponent, setActiveComponent] = useState("");
    const [certificationFile, setCertificationFile] = useState("");
    const [panCertificateFile, setPanCertificateFile] = useState("");
    const [registrationCertificateFile, setRegistrationCertificateFile] = useState("");

    const [panError, setPanError] = useState(false);
    const [registrationError, setRegistrationError] = useState(false);


    const handleFileChange = (e, setter) => {
        const file = e.target.files[0];
        if (file) {
            setter(file.name);
        }
    };

    const handleGoBack = () =>{
        //setActiveComponent("OwnerInformation")
        setIndex(1);
        console.log(setIndex)
    }

    const handleNext = () => {
        let isValid = true;

        if (!panCertificateFile) {
            setPanError(true);
            isValid = false;
        } else {
            setPanError(false);
        }

        if (!registrationCertificateFile) {
            setRegistrationError(true);
            isValid = false;
        } else {
            setRegistrationError(false);
        }

        if (isValid) {
            //setActiveComponent("SubscriptionPlan")
            setIndex(3);
            console.log(setIndex)
        }
    };
    

    if(activeComponent === "OwnerInformation")
        return <OwnerInformation />
    else if(activeComponent === "SubscriptionPlan")
        return <SubscriptionPlan />

    return (
        <div className="mt-16 px-8">
            <div className="space-y-4">
                <h1 className="font-semibold text-[#4D45B5] text-xl tracking-wider">ORGANIZATION REGISTRATION</h1>
                <p className="text-[#4D45B5] tracking-wide text-sm">Country Information*</p>
            </div>

            <div className="flex">
                <div className="w-[33%] flex flex-col mt-6 space-y-8">
                    <div>
                        <label className="w-[95%] pl-3 opacity-70" htmlFor="CountryInfo">Country</label>
                        <select className="w-[95%] mt-1 rounded-md opacity-80" id="CountryInfo">
                            <option value="Nepal">Nepal</option>
                            <option value="India">India</option>
                            <option value="China">China</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Bhutan">Bhutan</option>
                        </select>
                    </div>

                    <div>
                        <label className="w-[95%] pl-3 opacity-70" htmlFor="state">State/Province</label>
                        <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="state" placeholder="Sweet Home Alabama" />
                    </div>
                </div>

                <div className="w-[33%] mt-6 space-y-8">
                    <div>
                        <label className="w-[95%] pl-3 opacity-70" htmlFor="countryCode">Country Code</label>
                        <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="countryCode" placeholder="977" />
                    </div>

                    <div>
                        <label className="w-[95%] pl-3 opacity-70" htmlFor="postal">Postal/Zip Code</label>
                        <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="postal" placeholder="90210" />
                    </div>
                </div>

                <div className="w-[33%] mt-6 space-y-8">
                    <div>
                        <label className="w-[95%] pl-3 opacity-70" htmlFor="City">City</label>
                        <select className="w-[95%] mt-1 rounded-md opacity-80" id="City">
                            <option value="Kathmandu">Kathmandu</option>
                            <option value="New Delhi">New Delhi</option>
                            <option value="Beijing">Beijing</option>
                            <option value="Dhaka">Dhaka</option>
                            <option value="Islambad">Islambad</option>
                            <option value="Thimpu">Thimpu</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="mt-20">
                <h1 className="text-[#4D45B5] tracking-wide text-sm font-medium">Organization Information</h1>
            </div>

            <div className="flex">
                <div className="w-[33%] flex flex-col mt-6 space-y-8">
                    <div>
                        <label className="w-[95%] pl-3 opacity-70" htmlFor="organizationType">Organization Type</label>
                        <select className="w-[95%] mt-1 rounded-md opacity-80" id="organizationType">
                            <option value="School">School</option>
                            <option value="College">College</option>
                            <option value="University">University</option>
                        </select>
                    </div>

                    <div>
                        <label className="w-[95%] pl-3 opacity-70" htmlFor="Email">Email</label>
                        <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="Email" placeholder="Cheezeitz@gmail.com" />
                    </div>
                </div>

                <div className="w-[33%] mt-6 space-y-8">
                    <div>
                        <label className="w-[95%] pl-3 opacity-70" htmlFor="OrganizationName">Organization Name</label>
                        <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="OrganizationName" placeholder="Devandsiu" />
                    </div>

                    <div>
                        <label className="w-[95%] pl-3 opacity-70" htmlFor="phoneNo">Phone Number</label>
                        <div className="flex">
                            <select className="w-[27%] mt-1 rounded-md opacity-80 border-r-0 rounded-r-none" id="Country">
                                <option value="Nepal">+977</option>
                                <option value="India">+91</option>
                                <option value="China">+92</option>
                                <option value="Bangladesh">+975</option>
                                <option value="Pakistan">+880</option>
                                <option value="Bhutan">+86</option>
                            </select>
                            <input className="mt-1 rounded-md opacity-80 border-l-0 rounded-l-none w-[68%]" type="tel" id="phoneNo" placeholder="9801294712" />
                        </div>
                    </div>
                </div>

                <div className="w-[33%] mt-6 space-y-8">
                    <div>
                        <label className="w-[95%] pl-3 opacity-70" htmlFor="OrganizationAddress">Address*</label>
                        <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="OrganizationAddress" placeholder="Devandsiu" />
                    </div>

                    <div>
                        <label className="w-[95%] pl-3 opacity-70" htmlFor="PanNo">Pan Number*</label>
                        <input className="w-[95%] mt-1 rounded-md opacity-80" type="text" id="PanNo" placeholder="Devandsiu" />
                    </div>
                </div>
            </div>

            <div className="w-[66%] mt-8">
                <label className={`w-[98%] pl-3 opacity-70`} htmlFor="addCertificate">
                    Add Certificate
                </label>
                <div className="relative flex items-center">
                    <input
                        type="file"
                        id="addCertificate"
                        className="invisible w-0"
                        onChange={(e) => handleFileChange(e, setCertificationFile)}
                    />
                    <input
                        className={`w-[98%] mt-1 rounded-md border-2 pr-12 border-gray-300 `}
                        type="text"
                        value={certificationFile}
                        placeholder="Certificate File"
                        readOnly
                    />
                    <label htmlFor="addCertificate" className="cursor-pointer">
                        <IoMdAdd className="absolute right-7 bottom-2 text-gray-600" size={24} />
                    </label>
                </div>
            </div>
            

            <div className="w-[66%] mt-8">
                <label className={`w-[98%] pl-3 ${panError ? 'text-red-500' : 'opacity-70'}`} htmlFor="panCertificate">
                    PAN Certificate*
                </label>
                <div className="relative flex items-center">
                    <input
                        type="file"
                        id="panCertificate"
                        className="invisible w-0"
                        onChange={(e) => handleFileChange(e, setPanCertificateFile)}
                    />
                    <input
                        className={`w-[98%] mt-1 rounded-md ${panError ? 'border-red-500' : 'border-gray-300'} border-2 pr-12`}
                        type="text"
                        value={panCertificateFile}
                        placeholder="PAN Certificate"
                        readOnly
                    />
                    <label htmlFor="panCertificate" className="cursor-pointer">
                        <IoMdAdd className="absolute right-7 bottom-2 text-gray-600" size={24} />
                    </label>
                </div>
            </div>

            <div className="w-[66%] mt-8">
                <label className={`w-[98%] pl-3 ${registrationError ? 'text-red-500' : 'opacity-70'}`} htmlFor="registrationCertificate">
                    Registration Certificate*
                </label>
                <div className="relative flex items-center">
                    <input
                        type="file"
                        id="registrationCertificate"
                        className="invisible w-0"
                        onChange={(e) => handleFileChange(e, setRegistrationCertificateFile)}
                    />
                    <input
                        className={`w-[98%] mt-1 rounded-md ${registrationError ? 'border-red-500' : 'border-gray-300'} border-2 pr-12`}
                        type="text"
                        value={registrationCertificateFile}
                        placeholder="Registration Certificate"
                        readOnly
                    />
                    <label htmlFor="registrationCertificate" className="cursor-pointer">
                        <IoMdAdd className="absolute right-7 bottom-2 text-gray-600" size={24} />
                    </label>
                </div>
            </div>

            <div className="space-x-4 mt-8 w-[35%] ml-[43rem]">
                <button onClick={handleGoBack} 
                className="px-4 py-2 text-[#4D45B5] w-[11rem] border-2 border-[#4D45B5] font-medium tracking-wide rounded-lg hover:bg-[#4D45B5] hover:text-white hover:ease-in-out hover:transition duration-[100ms]">
                    Go Back
                </button>
                <button
                    onClick={handleNext}
                    className="px-4 py-2 bg-[#4D45B5] w-[11rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default CompanyInformation;

import { useState, useRef, useEffect } from "react";
import OTP from "./OTP";


const TermsAndConditions = ({onClose , onNext}) => {

    
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return(
        <div ref={modalRef} className="w-[45rem] py-8 px-6 h-[90vh] overflow-y-auto">

            <div className="space-y-8">
                <h1 className="text-xl font-semibold  tracking-wide">Plan Selection</h1>
                <p className="">Lorem ipsum dolor sit amet consectetur. Sed id tempor ullamcorper at erat ornare egestas ut ornare. Commodo aliquam vestibulum nec mattis quis amet in diam ut. Amet interdum eget feugiat vestibulum ac pellentesque volutpat eu. A elementum urna sed laoreet vel id blandit cursus integer. Donec tincidunt fermentum pellentesque turpis magna arcu viverra eros.</p>
                <div className="text-justify space-y-2">
                    <li>Enim porta quis adipiscing bibendum turpis at arcu ullamcorper. Velit bibendum eu sodales blandit sagittis.</li>
                    <li>Tincidunt varius at cursus dui pellentesque vulputate elementum velit.Neque eget diam proin tellus mauris pellentesque viverra pretium.</li>
                    <li>Tortor ante a sollicitudin accumsan. Accumsan tincidunt tellus imperdiet diam. Massa velit vitae amet magna felis. </li>
                    <li>Felis mauris parturient sit malesuada habitant vel id augue facilisi. Ornare aliquet in arcu dui elementum. Ac sit lectus eleifend.</li>
                </div>
            </div>

            <div className="text-justify mt-8 space-y-4">
                <h1 className="text-xl font-semibold tracking-wide">Supplier's Information</h1>
                <p>Lorem ipsum dolor sit amet consectetur. Sed ornare lectus iaculis suspendisse mauris rutrum imperdiet lacus ut. Morbi euismod eu semper tincidunt cursus sociis auctor. Dui nunc lectus dapibus risus. Diam cras diam in etiam risus volutpat consequat. Rhoncus volutpat feugiat semper eleifend. Lectus orci mollis nisi eleifend. Habitasse nisl sapien dui enim. Eget sem platea ut mattis cras maecenas sit nunc. Consequat gravida integer a est. Laoreet tempor et nisl augue non quam dolor quis. At odio molestie viverra pharetra aliquam nisl. Gravida ut aliquet nunc magna blandit volutpat posuere.</p>
            </div>

            <div className="text-justify mt-8 space-y-4">
                <h1 className="text-xl font-semibold tracking-wide">Terms and Conditions</h1>
                <p>Lorem ipsum dolor sit amet consectetur. Sed ornare lectus iaculis suspendisse mauris rutrum imperdiet lacus ut. Morbi euismod eu semper tincidunt cursus sociis auctor. Dui nunc lectus dapibus risus. Diam cras diam in etiam risus volutpat consequat. Rhoncus volutpat feugiat semper eleifend. Lectus orci mollis nisi eleifend. Habitasse nisl sapien dui enim. Eget sem platea ut mattis cras maecenas sit nunc. Consequat gravida integer a est. Laoreet tempor et nisl augue non quam dolor quis. At odio molestie viverra pharetra aliquam nisl. Gravida ut aliquet nunc magna blandit volutpat posuere.
                    Mi in donec imperdiet in ullamcorper diam semper. Orci elementum mattis sit pellentesque eget. At tempor feugiat ultricies dolor tristique vitae amet. Amet faucibus nulla sapien massa orci pharetra amet lacus scelerisque. Leo risus ipsum vitae aliquam in. Felis urna ipsum faucibus posuere massa odio lacus dui. Eu sit nibh nunc egestas vitae quam. Mi dui rutrum fusce id. Sed vitae neque lacus habitasse. Eros facilisis condimentum ullamcorper blandit cursus. Amet est gravida semper quisque volutpat amet eros.
                </p>
            </div>

            <div className="mt-6 space-y-3">
                <div className="flex space-x-4 items-center">
                    <input className="rounded-full border-gray-600" type="checkbox" name="" id="terms" />
                    <label htmlFor="terms">I Agree with the Terms and Conditions</label>
                </div>

                <div className="flex space-x-4 items-center">
                    <input className="rounded-full border-gray-600" type="checkbox" name="" id="privacy" />
                    <label htmlFor="privacy">I Agree with the Terms and Conditions</label>
                </div>
            </div>

            <div className="">
                <div className="flex w-[40%] justify-between ml-[60%] mt-8 items-center space-x-6">
                    <button className="px-4 py-2 text-[#4D45B5] w-[11rem] border-2 border-[#4D45B5] font-medium tracking-wide rounded-lg hover:bg-[#4D45B5] hover:text-white hover:ease-in-out hover:transition duration-[100ms]" onClick={onClose}>Cancel</button>
                    <button className="px-4 py-2 bg-[#4D45B5] w-[11rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide" onClick={onNext}>Next</button>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import BaseLayout from "../../components/BaseLayout";
import useAxios from "../../../utils/axios/useAxios";
import StudentDetailCard from "../../components/StudentResult/StudentDetailCard";

const StudentProgress = () => {
  
  const { data: students, error: studentError } = useAxios(`classrooms/students/`);
  const { data: classrooms, error: classroomError } = useAxios(`classrooms/classrooms/`);
  const { data: curriculums, error: curriculumError } = useAxios(`classrooms/curriculums/`);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [filterStudent, setFilterStudent] = useState("");
  const [filterCurriculum, setFilterCurriculum] = useState("");
  const [filterClassroom, setFilterClassroom] = useState("");

  const itemsPerPage = 6;

  const studentMap = new Map(
    students.map((student) => [student.id, `${student.first_name} ${student.last_name}`]),
  )

  const classroomMap = new Map(
    classrooms.map((classroom) => [classroom.id, classroom.name])
  )
  
  const curriculumMap = new Map(
    curriculums.map((curriculum) => [curriculum.id, curriculum.name])
  )

  const filteredStudents = students?.filter((student) => {
    return (
      (filterClassroom ? student.classroom.toString() === filterClassroom : true) &&
      (filterCurriculum ? student.curriculum === filterCurriculum : true)
    );
  });

  const totalPages = Math.ceil(filteredStudents.length / itemsPerPage);

  const currentData = filteredStudents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const uniqueClassrooms = classrooms?.map((classroom) => classroom.id);
  const uniqueCurriculums = curriculums?.map((curriculum) => curriculum.id);

  return (
    <BaseLayout title={"Results"}>
      <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold ml-4">Student Progress</h2>
          <div className="space-x-4 text-[14px] mr-4">
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none focus:ring-[#4D45B5] focus:border-transparent"
              value={filterClassroom}
              onChange={(e) => setFilterClassroom(e.target.value)}
            >
              <option value="">All Classrooms</option>
              {uniqueClassrooms.map((classroom) => (
                <option key={classroom} value={classroom}>
                  {classroomMap.get(classroom)}
                </option>
              ))}
            </select>
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none focus:ring-[#4D45B5] focus:border-transparent"
              value={filterCurriculum}
              onChange={(e) => setFilterCurriculum(e.target.value)}
            >
              <option value="">All Curriculums</option>
              {uniqueCurriculums.map((curriculum) => (
                <option key={curriculum} value={curriculum}>
                  {curriculumMap.get(curriculum)}
                </option>
              ))}
            </select>
          </div>
        </div>

        <hr className="my-4" />

        <div className="container mx-auto px-4">
          <table className="min-w-full bg-white">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px]">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">S.N</th>
                <th className="p-4 text-left font-light">Name</th>
                <th className="p-4 text-center font-light">Student ID</th>
                <th className="p-4 text-center font-light">Roll No.</th>
                <th className="p-4 text-center font-light rounded-tr-lg">Result</th>
              </tr>
            </thead>

            <tbody className="text-[14px] border border-gray-300">
              {currentData.map((item, index) => (
                <tr
                  key={item.id}
                  className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg"
                >
                  <td className="p-4 border-b border-gray-300 text-center">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-left">
                    {studentMap.get(item.id)}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {item.id}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {item.id}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    <Link
                      to={`/teacher/examResult/${item.id}`}
                      className="hover:text-[#4D45B5] text-[#4D45B5] underline font-medium duration-200"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mt-4 flex space-x-6 justify-center">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="size-5 text-gray-400"
            >
              <FaChevronLeft />
            </button>
            <span className="">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="size-5 text-gray-400"
            >
              <FaChevronRight className="text-sm" />
            </button>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default StudentProgress;

import React from "react";
import FeaturesLanding from "../components/features/featuresLanding";
import FeaturesScroll from "../components/features/featuresScroll";

const Features = () => {
  return (
    <div className="mt-[-15vh]">
      <FeaturesLanding />
      <FeaturesScroll />
    </div>
  );
};
export default Features;

import React, { useState } from "react";
import LoginArc from "../../assets/images/login/loginArc.png";
import LoginRect from "../../assets/images/login/LoginRect.png";

import axiosInstance from "../../utils/axios/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import Organization from "../components/Registration/Organization";
import CountryInfoStep from "../components/Registration/CountryInfo";
import CredentialsStep from "../components/Registration/Credentials";
import ProgressBar from "../components/Registration/ProgressBar";

const RegistrationForm = () => {
  const totalCapacity = 10;
  const usedCapacity = 4;
  const percentage = (usedCapacity / totalCapacity) * 100;
  const circumference = 2 * Math.PI * 20;

  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    // Organization data
    org_type: "",
    org_name: "",
    org_phone_number: "",
    org_email: "",
    org_website: "",
    org_subdomain: "",
    org_custom_domain: "",
    org_address: "",
    // Country info data
    country: "",
    code: "",
    province: "",
    city: "",
    postal_code: "",
    // Principal data
    first_name: "",
    middle_name: "",
    last_name: "",
    date_of_birth: "",
    principal_contact: "",
    principal_email: "",
    principal_address: "",
    password: "",
    confirmPassword: "",
  });

  const steps = [
    { number: 1, name: "Organization" },
    { number: 2, name: "Country/Info" },
    { number: 3, name: "Credentials" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const nextStep = () => {
    if (currentStep < 3) setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      // Create organization
      const orgData = await axiosInstance.post("/api/core/organizations/", {
        type: { value: formData.org_type },
        name: { value: formData.org_name },
        phone_number: { value: formData.org_phone_number },
        email: { value: formData.org_email },
        website: { value: formData.org_website },
        subdomain: { value: formData.org_subdomain },
        custom_domain: { value: formData.org_custom_domain },
        country: { value: formData.country },
        code: { value: formData.code },
        province: { value: formData.province },
        city: { value: formData.city },
        postal_code: { value: formData.postal_code },
        address: { value: formData.org_address },
      });

      // Create principal
      const principalData = await axiosInstance.post(
        "/api/classrooms/principals/",
        {
          organization: orgData.data.id,
          first_name: formData.first_name,
          middle_name: formData.middle_name,
          last_name: formData.last_name,
          date_of_birth: formData.date_of_birth,
          address: formData.principal_address,
          contact: formData.principal_contact,
          email: formData.principal_email,
        }
      );

      toast.success("Registration Successful.");
      navigate("../login");
    } catch (error) {
      toast.error("Registration Failed: " + error.message);
    }
  };

  return (
    <div className="min-h-screen w-full overflow-hidden">
      {/* Header */}
      <header className="fixed w-full h-[7vh] flex items-center">
        <div className="text-4xl font-bold pl-4">LOGO</div>
      </header>

      {/* Left Side: Sign In Form */}
      <div className="w-[40%]">
        <div className="h-screen pt-20 mx-auto overflow-y-scroll">
          <div className="mx-auto w-[63%]">
            <h2 className="text-2xl text-[#4D45B5] mb-4 font-semibold text-center">
              REGISTER YOUR ACCOUNT TODAY
            </h2>

            <ProgressBar steps={steps} currentStep={currentStep} />

            <form>
              {currentStep === 1 && (
                <Organization formData={formData} handleChange={handleChange} />
              )}
              {currentStep === 2 && (
                <CountryInfoStep
                  formData={formData}
                  handleChange={handleChange}
                />
              )}
              {currentStep === 3 && (
                <CredentialsStep
                  formData={formData}
                  handleChange={handleChange}
                />
              )}

              <div className="flex flex-col items-center">
                {currentStep < 3 && (
                  <button
                    type="button"
                    onClick={nextStep}
                    className="w-full bg-[#4D45B5] text-white font-bold py-2 px-4 my-2 rounded"
                  >
                    Next Page
                  </button>
                )}

                {currentStep === 3 && (
                  <button
                    type="submit"
                    className="w-full bg-[#4D45B5] text-white font-bold py-2 px-4 my-2 rounded"
                    onClick={submitForm}
                  >
                    Submit
                  </button>
                )}
                {currentStep > 1 && (
                  <button
                    type="button"
                    onClick={prevStep}
                    className="w-full my-2 bg-[#e2dede] hover:bg-[#d2cece] font-bold py-2 px-4 rounded"
                  >
                    Back
                  </button>
                )}
              </div>
            </form>

            <div className="flex justify-center items-center my-2 text-sm">
              <p className="text-[#919191]">Already have an account? </p>
              <a href="./login" className="text-[#4D45B5] ml-2 hover:underline">
                Sign in
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Right Side: Information*/}
      <div className="absolute w-[60%] h-screen right-0 top-0 bg-[#766FD0] flex items-center">
        <div className="text-center px-16 z-10">
          <h1 className="text-5xl text-white mb-8 font-semibold">
            Lorem Ipsum
          </h1>
          <p className="text-xl text-[#F2F2F2] tracking-tight font-light">
            Lorem ipsum dolor sit amet consectetur. Egestas eu volutpat potenti
            tellus viverra ultrices facilisis arcu. Ipsum elementum purus tellus
            cras morbi malesuada. Lorem ipsum dolor sit amet consectetur.
            Egestas eu volutpat potenti tellus viverra ultrices facilisis arcu.
            Ipsum elementum purus tellus cras morbi malesuada.
          </p>

          <div className="relative mt-12">
            <img src={LoginRect} alt="arc" className="p-12" />

            {/* Storage Capacity */}
            <div className="absolute top-2 right-2 bg-white px-2 py-1 rounded-2xl shadow-md w-fit">
              <span className="text-sm font-medium">Storage Capacity</span>
              <div className="flex items-center">
                <div className="relative w-[50px] h-[50px]">
                  <svg className="w-full h-full transform -rotate-90">
                    <circle
                      cx="25"
                      cy="25"
                      r="20"
                      stroke="#E0E0E0"
                      strokeWidth="4"
                      fill="none"
                    />
                    <circle
                      cx="25"
                      cy="25"
                      r="20"
                      stroke="#534BB6"
                      strokeWidth="4"
                      fill="none"
                      strokeDasharray={circumference}
                      strokeDashoffset={
                        circumference - (percentage / 100) * circumference
                      }
                    />
                  </svg>
                  <div className="absolute inset-0 flex items-center justify-center text-xs font-semibold text-[#534BB6]">
                    {usedCapacity}GB
                  </div>
                </div>
                <span className="text-lg font-bold mx-1">
                  {usedCapacity} GB/{totalCapacity} GB
                </span>
              </div>
            </div>
          </div>
        </div>

        <img
          src={LoginArc}
          alt="arc"
          className="absolute right-0 bottom-0 z-0"
        />
      </div>
    </div>
  );
};

export default RegistrationForm;

import React, { useState } from "react";
import { IoNotifications } from "react-icons/io5";
import {
  FaDownload,
  FaFolder,
  FaFilePdf,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import useAxios from "../../../utils/axios/useAxios";

const PastPaper = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterTeacher, setFilterTeacher] = useState("");
  const [filterSubject, setFilterSubject] = useState("");
  const { data, error } = useAxios(`classrooms/testpapers`);

  const { data: teachers = [], error: teacherError } =
    useAxios(`classrooms/teachers`);
  const { data: subjects = [], error: subjectError } =
    useAxios(`classrooms/subjects`);

  const itemsPerPage = 6;

  const handleDownload = (id) => {
    alert(`Download paper with id: ${id}`);
    // Implement download logic here
  };

  const filteredData = data.filter((item) => {
    return (
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filterTeacher ? item.created_by.toString() === filterTeacher : true) &&
      (filterSubject ? item.subject.toString() === filterSubject : true)
    );
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const uniqueTeachers = [...new Set(data.map((item) => item.uploadedBy))];
  const uniqueSubjects = [...new Set(data.map((item) => item.subject))];

  return (
    <div className="max-w-screen-2xl mx-auto px-10 py-5 font-poppins min-h-screen bg-[#FBFBFF]">
      <div className="flex justify-between items-center mb-12">
        <div>
          <h1 className="text-2xl font-semibold text-[#5964DB]">
            Teachers Content
          </h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-[#928DD3] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4D45B5] focus:border-transparent"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-[#928DD3]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35M17.65 10.65a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
          <div className="text-[#928DD3]">
            <IoNotifications className="w-7 h-7 mt-2 duration-200 hover:text-[#4D45B5] hover:scale-110" />
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold">Past Paper</h2>
          <div className="space-x-4 text-[14px]">
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={filterTeacher}
              onChange={(e) => setFilterTeacher(e.target.value)}
            >
              <option value="" disabled selected>
                Subject Teacher
              </option>
              <option value="">All Teachers</option>
              {teachers.map((teacher) => (
                <option key={teacher.id} value={teacher.id.toString()}>
                  {`${teacher.first_name} ${teacher.last_name}`}
                </option>
              ))}
            </select>
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={filterSubject}
              onChange={(e) => setFilterSubject(e.target.value)}
            >
              <option value="" disabled selected>
                Select Subject
              </option>
              <option value="">All Subjects</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id.toString()}>
                  {subject.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <hr className="my-4" />

        <div className="container mx-auto px-4">
          <table className="min-w-full bg-white ">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px] ">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">
                  S.N
                </th>
                <th className="p-4  text-left  font-light">Resources</th>
                <th className="p-4  text-center  font-light">Uploaded By</th>
                <th className="p-4  text-center  font-light">Uploaded On</th>
                <th className="p-4 text-center  font-light rounded-tr-lg">
                  Download
                </th>
              </tr>
            </thead>
            {/* {console.log(data)} */}
            <tbody className="text-[14px] border  border-gray-300">
              {currentData.map((item, index) => {
                const teacher = teachers.find(
                  (teacher) => teacher.id === item.created_by
                );
                return (
                  <tr
                    key={item.id}
                    className="hover:bg-[#F5F7FF] divide-x divide-gray-300  rounded-b-lg"
                  >
                    <td className="p-4 border-b border-gray-300 text-center">
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-left ">
                      <div className=" flex items-center">
                        {item.type === "pdf" ? (
                          <span className="mr-2 text-[#5964DB]">
                            <FaFilePdf />
                          </span>
                        ) : (
                          <span className="mr-2 text-[#5964DB]">
                            <FaFolder />
                          </span>
                        )}
                        {item.title}
                      </div>
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {teacher
                        ? `${teacher.first_name} ${teacher.last_name}`
                        : "Unknown"}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {new Date(item.created_at).toLocaleDateString()}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      <button
                        onClick={() => handleDownload(item.id)}
                        className="text-[#5964DB]"
                      >
                        <FaDownload />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="mt-4 flex space-x-6 justify-center">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="size-5 text-gray-400 "
            >
              <FaChevronLeft />
            </button>
            <span className="">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="size-5 text-gray-400  "
            >
              <FaChevronRight className="text-sm " />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastPaper;

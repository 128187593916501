import React from "react";
import '../../../../src/index.css';
import waves from '../../../assets/images/accountportal/bg-portal-waves.svg';
import singleman from '../../../assets/images/accountportal/singleMan.svg';
import semicircle from '../../../assets/images/accountportal/semi-circle.svg';

const Register = () => {
    return (
        <div className="relative flex flex-col items-center min-h-screen p-5 bg-[#766FD0] text-gray-900">
            <img src={waves} alt="waves" className="absolute inset-0 w-full h-full object-cover z-0" />
            <img src={singleman} alt="man" className="absolute bottom-0 left-8 mb-4 ml-4 w-156 h-345 object-contain z-10" />
            {/* <img src={semicircle} alt="man" className="" /> */}
            <img
                src={semicircle}
                alt="semicircle"
                className="absolute bottom-[-0rem] right-[-1rem] w-50 h-50 object-contain z-10"
            />
            <a
                href="https://en.wikipedia.org/wiki/Brainfuck"
                className="font-poppins absolute bottom-[2.7rem] right-[-0.2rem] text-xl text-[#4D45B5] px-5 py-2 rounded-full z-20 font-medium"
            >
                Sign In
            </a>


            <header className="relative w-full flex justify-between items-center p-3 z-20">
                <div className="text-2xl font-bold text-white">LOGO</div>
            </header>

            <main className="relative bg-[#ECE9EE] rounded-2xl my-auto p-10 w-[74vw] shadow-md z-20">
                <section className="w-full">
                    <h1 className="font-poppins text-2xl text-[#766FD0] mb-5 md:font-semibold">
                        REGISTER YOUR ORGANIZATION TODAY
                    </h1>
                    <form>
                        <fieldset className="mb-5">
                            <legend className=" font-poppins text-lg text-[#766FD0] mb-6 md:font-medium">
                                Country Information *
                            </legend>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-[1rem]">
                                <div className="flex flex-col mb-3  ">
                                    <label htmlFor="organization" className="mb-1">
                                        Organization
                                    </label>
                                    <select
                                        id="organization"
                                        name="organization"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    >
                                        <option value="college">College</option>
                                        <option value="college">College</option>
                                        <option value="college">College</option>
                                        <option value="college">College</option>
                                    </select>
                                </div>
                                <div className="flex flex-col mb-3">
                                    <label htmlFor="organization-name" className="mb-1">
                                        Organization Name
                                    </label>
                                    <input
                                        type="text"
                                        id="organization-name"
                                        name="organization-name"
                                        placeholder="Lorem Ipsum"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    />
                                </div>
                                <div className="flex flex-col mb-3">
                                    <label htmlFor="country" className="mb-1">
                                        Country
                                    </label>
                                    <input
                                        type="text"
                                        id="country"
                                        name="country"
                                        placeholder="Lorem Ipsum"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    />
                                </div>
                                <div className="flex flex-col mb-3">
                                    <label htmlFor="country-code" className="mb-1">
                                        Country Code
                                    </label>
                                    <input
                                        type="text"
                                        id="country-code"
                                        name="country-code"
                                        placeholder="Lorem Ipsum"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    />
                                </div>
                                <div className="flex flex-col mb-3">
                                    <label htmlFor="address" className="mb-1">
                                        Address
                                    </label>
                                    <input
                                        type="text"
                                        id="address"
                                        name="address"
                                        placeholder="Lorem Ipsum"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    />
                                </div>
                                <div className="flex flex-col mb-3">
                                    <label htmlFor="city" className="mb-1">
                                        City
                                    </label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        placeholder="Lorem Ipsum"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    />
                                </div>
                                <div className="flex flex-col mb-3">
                                    <label htmlFor="state-province" className="mb-1">
                                        State/Province
                                    </label>
                                    <input
                                        type="text"
                                        id="state-province"
                                        name="state-province"
                                        placeholder="Lorem Ipsum"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    />
                                </div>
                                <div className="flex flex-col mb-3">
                                    <label htmlFor="postal-code" className="mb-1">
                                        Postal/Zip Code
                                    </label>
                                    <input
                                        type="text"
                                        id="postal-code"
                                        name="postal-code"
                                        placeholder="Lorem Ipsum"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="mb-5">
                            <legend className="font-poppins text-lg text-[#766FD0] mb-4 md:font-medium">
                                Other Information *
                            </legend>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-[1rem]">
                                <div className="flex flex-col mb-3">
                                    <label htmlFor="email" className="mb-1">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Lorem Ipsum"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    />
                                </div>
                                <div className="flex flex-col mb-3">
                                    <label htmlFor="contact-number" className="mb-1">
                                        Contact Number
                                    </label>
                                    <input
                                        type="text"
                                        id="contact-number"
                                        name="contact-number"
                                        placeholder="Lorem Ipsum"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    />
                                </div>
                                <div className="row-span-2"></div>
                                <div className="flex flex-col mb-3">
                                    <label htmlFor="password" className="mb-1">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        placeholder="Lorem Ipsum"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    />
                                </div>
                                <div className="flex flex-col mb-3">
                                    <label htmlFor="confirm-password" className="mb-1">
                                        Confirm Password
                                    </label>
                                    <input
                                        type="password"
                                        id="confirm-password"
                                        name="confirm-password"
                                        placeholder="Lorem Ipsum"
                                        className="p-2 border border-stone-600/75 rounded bg-[#ECE9EE]"
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <div className="flex justify-end gap-x-[2rem]">
                            <button
                                type="reset"
                                className="px-16 py-2 text-[#4D45B5] border border-[#4D45B5] bg-transparent rounded-lg 
                                hover:bg-[#4D45B5] hover:text-white transition-all duration-300"
                            >
                                Clear
                            </button>
                            <button
                                type="submit"
                                className="px-16 py-2 bg-[#4D45B5] text-white rounded-lg 
                                hover:bg-transparent hover:text-[#4D45B5] border hover:border-[#4D45B5] transition-all duration-300"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </section>
            </main>
        </div>
    );
};

export default Register;

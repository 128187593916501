import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-primary pt-5 text-sm relative">
      <img
        className="absolute bottom-0 left-0 opacity-5"
        src={require("../../assets/images/svgs/Vector 11.png")}
        alt="design"
      />
      <div className="text-white mx-auto w-full max-w-screen-2xl 2xl:px-8 md:px-16 px-5 p-4 py-6 lg:py-8 z-10 relative">
        <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 sm:gap-16">
          <div className="mb-6 md:mb-0 lg:col-span-2 md:col-span-3 lg:mr-20">
            <a href="/" className="items-center">
              <span className="self-center font-semibold whitespace-nowrap uppercase text-4xl pb-3 block">
                <img
                  className="w-20"
                  src={require("../../assets/images/logo.png")}
                  alt="logo"
                />
              </span>
            </a>
            <p className="text-sm font-light">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
              tempore! Repellat nobis asperiores magni quos tenetur cumque autem
              placeat error a, eligendi dolores impedit laboriosam consequuntur!
              Aspernatur dolore quaerat ipsa.
            </p>
          </div>
          <div className="">
            <h2 className="mb-6 font-semibold uppercase">Resources</h2>
            <ul>
              <li className="mb-4">
                <a href="/view/plans" className="hover:underline">
                  Plans
                </a>
              </li>
              <li>
                <a href="#!" className="hover:underline">
                  Lorem ipsum
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold uppercase">Follow us</h2>
            <ul className="">
              <li className="mb-4">
                <a href="#!" className="hover:underline ">
                  Lorem ipsum
                </a>
              </li>
              <li>
                <a href="#!" className="hover:underline">
                  Lorem ipsum
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold uppercase">Legal</h2>
            <ul className="">
              <li className="mb-4">
                <a href="#!" className="hover:underline">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#!" className="hover:underline">
                  Terms &amp; Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm sm:text-center">
            © {year}{" "}
            <a
              className="text-blue-300 hover:text-blue-200"
              href="https://octacore.com.np/"
              target="_blank"
              rel="noreferrer"
            >
              Octacore Solutions
            </a>
            . All Rights Reserved.
          </span>
          <div className="flex gap-5 mt-4 sm:justify-center sm:mt-0">
            <a href="#!">Terms</a>
            <a href="#!">Privacy</a>
            <a href="#!">Cookies</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { useEffect, useState } from "react";
import useAxiosAuth from "../../utils/axios/useAxiosAuth.js";

const useAxios = (url) => {
  const axios = useAxiosAuth();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    if (url) {
      axios
        .get(`/api/${url}`)
        .then((response) => {
          console.log(response.data);
          setData(response.data);
        })
        .catch((error) => {
          console.error(
            "Error fetching assignments:",
            error.response || error.message
          );
          setError(error.response || error.message);
        });
    }
  }, [url]);
  return { data, error };
};

export default useAxios;

import React from "react";
import CalendarDashboard from "../../components/Dashboard/CalendarDashboard";
import NoticeDashboard from "../../components/Dashboard/NoticeDashboard";
import Layout from "../../components/Dashboard/Layout";

const AccountingDashboard = () => {
  const data = [
    {
      description: "Lorem ipsum dolor sit amet consectetur.",
      account: "#15252345",
      revenue: "#15252345",
    },
    {
      description: "Lorem ipsum dolor sit amet consectetur.",
      account: "#15252345",
      revenue: "#15252345",
    },
    {
      description: "Lorem ipsum dolor sit amet consectetur.",
      account: "#15252345",
      revenue: "#15252345",
    },
  ];

  return (
    <Layout>
      <div className="flex justify-between space-x-4">
        {/* Manual Journal Table */}
        <div
          className="bg-white rounded-lg shadow-lg p-4 w-7/12"
          style={{ opacity: 0.9 }}
        >
          <div className="flex justify-between items-center mb-3">
            <h3 className="text-md font-medium text-black">Manual Journal</h3>
            <div className="flex items-center">
              <span className="text-xs text-gray-600 mr-2">Select Date</span>
              <input
                type="date"
                className="border border-gray-300 rounded p-1 text-xs"
                defaultValue="2024-02-11"
              />
            </div>
          </div>
          <table
            className="mt-5 min-w-full h-[80%] bg-white border border-gray-200 rounded-lg text-xs"
            style={{ opacity: 0.9 }}
          >
            <thead
              className="bg-[#EAECFF] rounded-tl-lg"
              style={{ opacity: 0.9 }}
            >
              <tr>
                <th className="p-2 text-center font-medium border-r border-gray-300 rounded-tl-lg">
                  S.N
                </th>
                <th className="p-2 text-left font-medium border-r border-gray-300 pl-3">
                  Description
                </th>
                <th className="p-2 text-center font-medium rounded-tr-lg">
                  Account
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="border-b border-gray-200">
                  <td className="p-2 text-center border-r border-gray-300">
                    {index + 1}
                  </td>
                  <td className="p-2 text-left text-blue-600 border-r border-gray-300 pl-3">
                    {item.description}
                  </td>
                  <td className="p-2 text-center">{item.account}</td>
                </tr>
              ))}
              <tr className="font-semibold">
                <td className="p-2 text-center border-r border-gray-300"></td>

                <td className="p-2 text-left border-r border-gray-300 pl-3">
                  Total
                </td>
                <td colSpan="2" className="p-2 text-center">
                  0.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Revenue Table */}
        <div
          className="bg-white rounded-lg shadow-lg p-4 w-5/12 h-[40vh]"
          style={{ opacity: 0.9 }}
        >
          <div className="flex justify-between items-center mb-3">
            <h3 className="text-md font-medium text-black">Revenue</h3>
            <div className="flex items-center">
              <span className="text-xs text-gray-600 mr-2">Select Date</span>
              <input
                type="date"
                className="border border-gray-300 rounded p-1 text-xs"
                defaultValue="2024-02-11"
              />
            </div>
          </div>
          <table
            className=" mt-5 min-w-full h-[80%] bg-white border border-gray-200 rounded-lg text-xs"
            style={{ opacity: 0.9 }}
          >
            <thead className="bg-[#EAECFF]" style={{ opacity: 0.9 }}>
              <tr>
                <th className="p-2 text-center font-medium border-r border-gray-300 rounded-tl-lg">
                  S.N
                </th>
                <th className="p-2 text-left font-medium border-r border-gray-300 pl-3">
                  Revenue Entry
                </th>
                <th className="p-2 text-center font-medium rounded-tr-lg">
                  Revenue
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="border-b border-gray-200">
                  <td className="p-2 text-center border-r border-gray-300">
                    {index + 1}
                  </td>
                  <td className="p-2 text-left text-blue-600 border-r border-gray-300 pl-3">
                    {item.description}
                  </td>
                  <td className="p-2 text-center">{item.revenue}</td>
                </tr>
              ))}
              <tr className="font-semibold">
                <td className="p-2 text-center border-r border-gray-300"></td>
                <td className="p-2 text-left border-r border-gray-300 pl-3">
                  Total
                </td>
                <td colSpan="2" className="p-2 text-center">
                  0.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-6 grid xl:grid-cols-[25%_75%] md:grid-cols-2 gap-5">
        <CalendarDashboard />
        <NoticeDashboard />
      </div>
    </Layout>
  );
};

export default AccountingDashboard;

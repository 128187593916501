import React from 'react';
import { Scheduler } from "@aldabil/react-scheduler";
import { EVENTS } from "../../components/Teacher/Events";

const TeacherScheduler = () => {
  return (
    <Scheduler
      events={EVENTS}
      disableViewer
      editable={false} // Disable editing and deleting events
      draggable={false} // Disable dragging events
      onCellClick={() => {
        // Blkank for disabling adding events
        console.log("Adding events is disabled");
      }}
      onEventClick={() => {
        console.log("onEventClick");
      }}
    />
  )
}

export default TeacherScheduler;

export const EVENTS = [
    // Week 1
    {
      event_id: 1,
      title: "Math Class - Grade 10",
      start: new Date("2024-08-01T09:00:00"),
      end: new Date("2024-08-01T10:00:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 2,
      title: "Math Class - Grade 11",
      start: new Date("2024-08-01T10:15:00"),
      end: new Date("2024-08-01T11:15:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 3,
      title: "Math Class - Grade 12",
      start: new Date("2024-08-01T11:30:00"),
      end: new Date("2024-08-01T12:30:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 4,
      title: "Math Class - Grade 10",
      start: new Date("2024-08-02T09:00:00"),
      end: new Date("2024-08-02T10:00:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 5,
      title: "Math Class - Grade 11",
      start: new Date("2024-08-02T10:15:00"),
      end: new Date("2024-08-02T11:15:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 6,
      title: "Math Class - Grade 12",
      start: new Date("2024-08-02T11:30:00"),
      end: new Date("2024-08-02T12:30:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
  
    // Week 2
    {
      event_id: 7,
      title: "Math Class - Grade 10",
      start: new Date("2024-08-05T09:00:00"),
      end: new Date("2024-08-05T10:00:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 8,
      title: "Math Class - Grade 11",
      start: new Date("2024-08-05T10:15:00"),
      end: new Date("2024-08-05T11:15:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 9,
      title: "Math Class - Grade 12",
      start: new Date("2024-08-05T11:30:00"),
      end: new Date("2024-08-05T12:30:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 10,
      title: "Math Class - Grade 10",
      start: new Date("2024-08-06T09:00:00"),
      end: new Date("2024-08-06T10:00:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 11,
      title: "Math Class - Grade 11",
      start: new Date("2024-08-06T10:15:00"),
      end: new Date("2024-08-06T11:15:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 12,
      title: "Math Class - Grade 12",
      start: new Date("2024-08-06T11:30:00"),
      end: new Date("2024-08-06T12:30:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
  

    // Week 3
    {
      event_id: 13,
      title: "Math Class - Grade 10",
      start: new Date("2024-08-07T09:00:00"),
      end: new Date("2024-08-07T10:00:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 14,
      title: "Math Class - Grade 11",
      start: new Date("2024-08-07T10:15:00"),
      end: new Date("2024-08-07T11:15:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 15,
      title: "Math Class - Grade 12",
      start: new Date("2024-08-07T11:30:00"),
      end: new Date("2024-08-07T12:30:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
  
    // Week 4
    {
      event_id: 16,
      title: "Math Class - Grade 10",
      start: new Date("2024-08-08T09:00:00"),
      end: new Date("2024-08-08T10:00:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 17,
      title: "Math Class - Grade 11",
      start: new Date("2024-08-08T10:15:00"),
      end: new Date("2024-08-08T11:15:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    {
      event_id: 18,
      title: "Math Class - Grade 12",
      start: new Date("2024-08-08T11:30:00"),
      end: new Date("2024-08-08T12:30:00"),
      admin_id: 1,
      editable: false,
      deletable: false,
    },
    

  ];
  
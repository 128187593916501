import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const CalendarDashboard = () => {
  const today = new Date();
  const initialYear = today.getFullYear();
  const initialMonth = today.getMonth(); // JavaScript Date month is 0-indexed
  const initialDate = today.getDate();

  const [currentMonth, setCurrentMonth] = useState(initialMonth);
  const [currentYear, setCurrentYear] = useState(initialYear);
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const events = {
    "2024-08-10": [],
    "2024-08-11": [
      {
        description:
          "Lorem ipsum dolor sit amet. Laoreet ac dictum eget tellus eu.",
      },
      {
        description:
          "Lorem ipsum dolor sit amet. Laoreet ac dictum eget tellus eu.",
      },
    ],
    "2024-09-12": [
      {
        description:
          "Lorem ipsum dolor sit amet. Laoreet ac dictum eget tellus eu.",
      },
      {
        description:
          "Lorem ipsum dolor sit amet. Laoreet ac dictum eget tellus eu.",
      },
      {
        description:
          "Lorem ipsum dolor sit amet. Laoreet ac dictum eget tellus eu.",
      },
      {
        description:
          "Lorem ipsum dolor sit amet. Laoreet ac dictum eget tellus eu.",
      },
      {
        description:
          "Lorem ipsum dolor sit amet. Laoreet ac dictum eget tellus eu.",
      },
      {
        description:
          "Lorem ipsum dolor sit amet. Laoreet ac dictum eget tellus eu.",
      },
    ],
    "2024-08-13": [],
    "2024-08-14": [
      {
        description:
          "Lorem ipsum dolor sit amet. Laoreet ac dictum eget tellus eu.",
      },
    ],
  };

  const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

  const handlePreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const generateCalendar = () => {
    const daysInCurrentMonth = daysInMonth(currentYear, currentMonth);
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    const calendarDays = [];

    // Previous month's trailing days
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.push(null);
    }

    // Current month's days
    for (let day = 1; day <= daysInCurrentMonth; day++) {
      calendarDays.push(day);
    }

    return calendarDays;
  };

  const calendarDays = generateCalendar();

  const formattedDate = `${currentYear}-${String(currentMonth + 1).padStart(
    2,
    "0"
  )}-${String(selectedDate).padStart(2, "0")}`;
  const currentEvents = events[formattedDate] || [];

  return (
    <div className="w-full px-4 py-8 mx-auto bg-white rounded-lg shadow-md h-[62vh]">
      <div className="flex items-center justify-around mb-4">
        <button
          className="focus:outline-none rounded-full border border-gray-500 p-1"
          onClick={handlePreviousMonth}
        >
          <FaChevronLeft className="text-gray-500" />
        </button>
        <div className="text-lg font-bold mx-4">
          {new Date(currentYear, currentMonth).toLocaleString("default", {
            month: "long",
          })}{" "}
          {currentYear}
        </div>
        <button
          className="focus:outline-none rounded-full border border-gray-500 p-1"
          onClick={handleNextMonth}
        >
          <FaChevronRight className="text-gray-500" />
        </button>
      </div>

      <div className="grid grid-cols-7 mb-2 ">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div
            key={day}
            className="text-center font-medium text-sm text-gray-700"
          >
            {day}
          </div>
        ))}
        {calendarDays.map((day, index) => (
          <div key={index} className="text-center">
            {day ? (
              <div
                className={`mt-1 text-sm font-medium rounded-full p-2 cursor-pointer ${
                  selectedDate === day
                    ? "bg-[#4D45B5] text-white"
                    : "text-gray-700"
                }`}
                onClick={() => setSelectedDate(day)}
              >
                {day}
              </div>
            ) : (
              <div className="mt-1 text-lg font-medium p-2">&nbsp;</div>
            )}
          </div>
        ))}
      </div>

      <div className="text-lg font-bold mb-2">Events</div>
      <div className="h-[14vh] overflow-y-scroll">
        {currentEvents.length > 0 ? (
          currentEvents.map((event, index) => (
            <div key={index} className="flex items-start mb-2 ">
              <div
                className={`flex items-center justify-center w-20 h-12 text-sm font-bold rounded-xl py-8 px-2  ${
                  selectedDate === String(event.date)
                    ? "bg-[#4D45B5] text-white"
                    : "border"
                }`}
              >
                {selectedDate} <br />{" "}
                {new Date(currentYear, currentMonth).toLocaleString("default", {
                  month: "short",
                })}
              </div>
              <div className="ml-2 text-sm text-gray-700">
                {event.description}
              </div>
            </div>
          ))
        ) : (
          <div className="text-sm text-gray-500">No events for this date</div>
        )}
      </div>
    </div>
  );
};

export default CalendarDashboard;

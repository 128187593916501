import React from 'react';
import { Scheduler } from "@aldabil/react-scheduler";
import { EVENTS } from "../../components/Teacher/Events";

const PrincipalScheduler = () => {
  return (
    <Scheduler
      events={EVENTS}

      onEventClick={() => {
        console.log("onEventClick");
      }}
    />
  )
}
export default PrincipalScheduler;

import React, { useState } from "react";
import {
  FaRegEdit,
  FaRegTrashAlt,
  FaFolder,
  FaFilePdf,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddPastPaperForm from "../../components/AddPastPaper";
import EditPastPaperForm from "../../components/Teacher/EditPastPaper";
import BaseLayout from "../../components/BaseLayout";
import useAxios from "../../../utils/axios/useAxios";

import useAuthUser from "react-auth-kit/hooks/useAuthUser";


const PastPaper = () => {

  const { data, error } = useAxios(`classrooms/testpapers/`);

  const { data: teachers, error: teacherError } = useAxios(
    `classrooms/teachers/`
  );

  const currentUser = useAuthUser();
  const currentUserId = currentUser?.id;
  

  // temporary data
  // const data = [
  //   {
  //     id: 1,
  //     title: "Algebra Basics exam paper",
  //     uploadedBy: "John Doe",
  //     uploadedOn: "03, July 2025",
  //     type: "folder",
  //     subject: "Math",
  //   },
  //   {
  //     id: 2,
  //     title: "Photosynthesis Process",
  //     uploadedBy: "Jane Smith",
  //     uploadedOn: "03, July 2025",
  //     type: "pdf",
  //     subject: "Science",
  //   },
  //   {
  //     id: 3,
  //     title: "World War II",
  //     uploadedBy: "Mike Johnson",
  //     uploadedOn: "04, July 2025",
  //     type: "folder",
  //     subject: "History",
  //   },
  //   {
  //     id: 4,
  //     title: "Chemical Reactions",
  //     uploadedBy: "Emily Davis",
  //     uploadedOn: "04, July 2025",
  //     type: "pdf",
  //     subject: "Chemistry",
  //   },
  //   {
  //     id: 5,
  //     title: "Trigonometry Advanced",
  //     uploadedBy: "John Doe",
  //     uploadedOn: "05, July 2025",
  //     type: "pdf",
  //     subject: "Math",
  //   },
  //   {
  //     id: 6,
  //     title: "Physics Fundamentals",
  //     uploadedBy: "Jane Smith",
  //     uploadedOn: "05, July 2025",
  //     type: "folder",
  //     subject: "Physics",
  //   },
  //   {
  //     id: 7,
  //     title: "Ancient Civilizations",
  //     uploadedBy: "Mike Johnson",
  //     uploadedOn: "06, July 2025",
  //     type: "pdf",
  //     subject: "History",
  //   },
  //   {
  //     id: 8,
  //     title: "Organic Chemistry",
  //     uploadedBy: "Emily Davis",
  //     uploadedOn: "06, July 2025",
  //     type: "folder",
  //     subject: "Chemistry",
  //   },
  //   {
  //     id: 9,
  //     title: "Linear Equations",
  //     uploadedBy: "John Doe",
  //     uploadedOn: "07, July 2025",
  //     type: "pdf",
  //     subject: "Math",
  //   },
  //   {
  //     id: 10,
  //     title: "Newton's Laws",
  //     uploadedBy: "Jane Smith",
  //     uploadedOn: "07, July 2025",
  //     type: "folder",
  //     subject: "Physics",
  //   },
  //   {
  //     id: 11,
  //     title: "Medieval Europe",
  //     uploadedBy: "Mike Johnson",
  //     uploadedOn: "08, July 2025",
  //     type: "pdf",
  //     subject: "History",
  //   },
  //   {
  //     id: 12,
  //     title: "Biochemistry Basics",
  //     uploadedBy: "Emily Davis",
  //     uploadedOn: "08, July 2025",
  //     type: "folder",
  //     subject: "Biology",
  //   },
  //   {
  //     id: 13,
  //     title: "Calculus Introduction",
  //     uploadedBy: "John Doe",
  //     uploadedOn: "09, July 2025",
  //     type: "pdf",
  //     subject: "Math",
  //   },
  //   {
  //     id: 14,
  //     title: "Electric Circuits",
  //     uploadedBy: "Jane Smith",
  //     uploadedOn: "09, July 2025",
  //     type: "folder",
  //     subject: "Physics",
  //   },
  //   {
  //     id: 15,
  //     title: "Renaissance Art",
  //     uploadedBy: "Mike Johnson",
  //     uploadedOn: "10, July 2025",
  //     type: "pdf",
  //     subject: "Art History",
  //   },
  //   {
  //     id: 16,
  //     title: "Genetics and DNA",
  //     uploadedBy: "Emily Davis",
  //     uploadedOn: "10, July 2025",
  //     type: "folder",
  //     subject: "Biology",
  //   },
  //   {
  //     id: 17,
  //     title: "Statistics Basics",
  //     uploadedBy: "John Doe",
  //     uploadedOn: "11, July 2025",
  //     type: "pdf",
  //     subject: "Math",
  //   },
  //   {
  //     id: 18,
  //     title: "Astronomy Overview",
  //     uploadedBy: "Jane Smith",
  //     uploadedOn: "11, July 2025",
  //     type: "folder",
  //     subject: "Astronomy",
  //   },
  //   {
  //     id: 19,
  //     title: "Modern Art Movements",
  //     uploadedBy: "Mike Johnson",
  //     uploadedOn: "12, July 2025",
  //     type: "pdf",
  //     subject: "Art History",
  //   },
  //   {
  //     id: 20,
  //     title: "Human Anatomy",
  //     uploadedBy: "Emily Davis",
  //     uploadedOn: "12, July 2025",
  //     type: "folder",
  //     subject: "Biology",
  //   },
  // ];

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const getTeacherName = (teacherId) => {
    const teacher = teachers?.find((t) => t.id === teacherId);
    return teacher ? `${teacher.first_name} ${teacher.last_name}` : "Unknown";
  };


  const itemsPerPage = 6;

  const handleDownload = (id) => {
    alert(`Download paper with id: ${id}`);
    // Implement download logic here
  };

  const filteredData = data?.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      item.created_by === currentUserId
  ) || [];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const AddPastPaper = withReactContent(Swal);
  const EditPastPaper = withReactContent(Swal);

  const showAddPastPaperForm = () => {
    const firePopup = () => {
      AddPastPaper.fire({
        html: <AddPastPaperForm />,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          container: "overflow-hidden",
          popup: "rounded-lg",
        },
        padding: 0,
        width: "auto",
      });
    };

    firePopup(); // Initial call to create the popup
  };

  const showEditPastPaperForm = () => {
    const firePopup = () => {
      EditPastPaper.fire({
        html: <EditPastPaperForm />,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          container: "overflow-hidden",
          popup: "rounded-lg",
        },
        padding: 0,
        width: "auto",
      });
    };

    firePopup(); // Initial call to create the popup
  };

  return (
    <BaseLayout title={"Teacher's Content"}>
      <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold ml-4">Past Paper</h2>
          <button
            className="px-4 py-2 text-sm bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
            onClick={showAddPastPaperForm}
          >
            Add Past Paper
          </button>
        </div>

        <hr className="my-4" />

        <div className="container mx-auto px-4">
          <table className="min-w-full bg-white ">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px] ">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">
                  S.N
                </th>
                <th className="p-4 text-left font-light">Resources</th>
                <th className="p-4 text-center font-light">Uploaded By</th>
                <th className="p-4 text-center font-light">Uploaded On</th>
                <th className="p-4 text-center font-light rounded-tr-lg">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="text-[14px] border  border-gray-300">
              {currentData.map((item, index) => (
                <tr
                  key={item.id}
                  className="hover:bg-[#F5F7FF] divide-x divide-gray-300  rounded-b-lg"
                >
                  <td className="p-4 border-b border-gray-300 text-center">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-left ">
                    <div className=" flex items-center">
                      {item.type === "pdf" ? (
                        <span className="mr-2 text-[#5964DB]">
                          <FaFilePdf />
                        </span>
                      ) : (
                        <span className="mr-2 text-[#5964DB]">
                          <FaFolder />
                        </span>
                      )}
                      {item.title}
                    </div>
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {getTeacherName(item.created_by)}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {formatDate(item.created_at)}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    <button>
                      <FaRegEdit
                        className="w-5 h-5 mr-5"
                        onClick={showEditPastPaperForm}
                      />
                    </button>
                    <button>
                      <FaRegTrashAlt className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex space-x-6 justify-center">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="size-5 text-gray-400 "
            >
              <FaChevronLeft />
            </button>
            <span className="">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="size-5 text-gray-400  "
            >
              <FaChevronRight className="text-sm " />
            </button>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default PastPaper;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import BaseLayout from "../../components/BaseLayout";
import useAxios from "../../../utils/axios/useAxios";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const ViewSubmission = () => {
  const navigate = useNavigate();
  const currentUser = useAuthUser();
  const { data: students, error: studentError } = useAxios("classrooms/students/");
  const { data: curriculums, error: curriculumError } = useAxios("classrooms/curriculums/");
  const { data: assignments, error: assignmentError } = useAxios("classrooms/assignments/");
  const { data: assignmentSubmissions, error: assignmentSubmissionError } = useAxios("classrooms/assignment-submissions/");

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterGrade, setFilterGrade] = useState("");
  const [filterSection, setFilterSection] = useState("");

  const teacherAssignments = assignments?.filter(
    (assignment) => assignment.created_by === currentUser.id
  ) || [];

  const filteredSubmissions = assignmentSubmissions?.filter((submission) =>
    teacherAssignments.some((assignment) => assignment.id === submission.assignment)
  ) || [];

  const getStudentName = (studentId) => {
    const student = students?.find((s) => s.id === studentId);
    return student ? `${student.first_name} ${student.last_name}` : "Unknown";
  };

  const itemsPerPage = 6;

  const filteredStudents = students?.filter((student) => {
    const studentClassrooms = Array.isArray(student.classroom) ? student.classroom : [student.classroom];
    const teacherClassrooms = Array.isArray(currentUser.profile.classroom)
      ? currentUser.profile.classroom
      : [currentUser.profile.classroom];

    return studentClassrooms.some((classroom) => teacherClassrooms.includes(classroom));
  }) || [];

  const totalPages = Math.ceil(filteredStudents.length / itemsPerPage);

  const currentData = filteredStudents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const uniqueGrade = [...new Set(curriculums.map((item) => item.id))];
  const uniqueSection = [...new Set(filteredStudents.map((item) => item.classroom))];

  return (
    <BaseLayout title={"Assignment Submission"}>
      <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <div className="flex items-center ml-4 space-x-2">
            <IoMdArrowRoundBack
              className="text-xl"
              onClick={() => navigate(-1)}
            />
            <h2 className="text-xl font-semibold">Student Progress</h2>
          </div>
          <div className="space-x-4 text-[14px] mr-4">
            <select
              className="border px-8 py-1 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={filterGrade}
              onChange={(e) => setFilterGrade(e.target.value)}
            >
              <option value="">All Grade</option>
              {uniqueGrade.map((grade) => (
                <option key={grade} value={grade}>
                  {grade}
                </option>
              ))}
            </select>
            <select
              className="border px-8 py-1 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={filterSection}
              onChange={(e) => setFilterSection(e.target.value)}
            >
              <option value="">All Section</option>
              {uniqueSection.map((section) => (
                <option key={section} value={section}>
                  {section}
                </option>
              ))}
            </select>
          </div>
        </div>

        <hr className="my-4" />

        <div className="container mx-auto px-4">
          <table className="min-w-full bg-white ">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px] ">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">S.N</th>
                <th className="p-4 text-left font-light">Name</th>
                <th className="p-4 text-center font-light">Student ID</th>
                <th className="p-4 text-center font-light">Roll No.</th>
                <th className="p-4 text-center font-light rounded-tr-lg">Submission</th>
              </tr>
            </thead>

            <tbody className="text-[14px] border border-gray-300">
              {currentData.map((student, index) => {
                const submission = filteredSubmissions.find(
                  (submission) => submission.student === student.id
                );

                let submissionStatus = "Pending";
                const currentDate = new Date();

                if (submission) {
                  const assignment = teacherAssignments.find(
                    (assignment) => assignment.id === submission.assignment
                  );
                  if (assignment) {
                    const submissionDate = new Date(submission.submission_date);
                    const deadline = new Date(assignment.deadline);

                    if (submissionDate > deadline) {
                      submissionStatus = "Late Submission";
                    } else {
                      submissionStatus = "Submitted";
                    }
                  }
                } else {
                  const assignment = teacherAssignments.find((assignment) => {
                    const studentClassrooms = Array.isArray(student.classroom)
                      ? student.classroom
                      : [student.classroom];
                    return studentClassrooms.includes(assignment.classroom);
                  });

                  if (assignment && currentDate > new Date(assignment.deadline)) {
                    submissionStatus = "Overdue";
                  }
                }

                return (
                  <tr
                    key={student.id}
                    className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg"
                  >
                    <td className="p-4 border-b border-gray-300 text-center">
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-left ">
                      {getStudentName(student.id)}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {student.id}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center">
                      {student.id}
                    </td>
                    <td className="p-4 border-b border-gray-300 text-center ">
                      <div className="flex justify-center pr-6">
                        <div
                          className={`h-2 w-2 rounded-full text-white my-auto mx-2 ${
                            submissionStatus === "Submitted"
                              ? "bg-green-500"
                              : submissionStatus === "Late Submission"
                              ? "bg-red-500"
                              : submissionStatus === "Overdue"
                              ? "bg-orange-500"
                              : "bg-yellow-300"
                          }`}
                        ></div>
                        <span>{submissionStatus}</span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="mt-4 flex space-x-6 justify-center">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="size-5 text-gray-400 "
            >
              <FaChevronLeft />
            </button>
            <span className="">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="size-5 text-gray-400"
            >
              <FaChevronRight className="text-sm" />
            </button>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ViewSubmission;



import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import featuresPictures from "../../../assets/images/features/Rectangle 10.png";
import blueBall from "../../../assets/images/features/blueBall.svg";

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

const FeaturesScroll = () => {
  const circleRef = useRef(null);
  const svgRef = useRef(null);
  const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    // Create a timeline for the section animations
    const sectionTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: svgRef.current,
        start: "top center",
        end: "bottom top",
        scrub: true,
        invalidateOnRefresh: true,
      },
    });

    // Apply the scroll trigger to each section
    sectionRefs.forEach((sectionRef, index) => {
      createScrollTriggerForSection(sectionRef, sectionTimeline, index);
    });

    // Circle animation
    const path = svgRef.current.querySelector("path");
    const circleTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: svgRef.current,
        start: "top center",
        end: "bottom top",
        scrub: 2, // Adjust scrub value for smoother animation
        invalidateOnRefresh: true,
      },
    });

    circleTimeline.to(circleRef.current, {
      motionPath: {
        path: path,
        align: path,
        alignOrigin: [0.5, 0.5],
        autoRotate: true,
      },
      duration: 10, // Adjust duration as needed
      ease: "none", // Use 'none' for linear movement
    });
  }, [sectionRefs]);

  // Function to create ScrollTrigger for each section with timeline
  const createScrollTriggerForSection = (sectionRef, timeline, index) => {
    const duration = 1.5; // Adjust duration for smoother animations
    const delay = index * 0.5; // Stagger the animations

    timeline
      .fromTo(
        sectionRef.current,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: duration,
          ease: "power2.out",
          delay: delay,
        },
        index === 0 ? 0 : "<+=0.5"
      )
      .to(
        sectionRef.current,
        {
          opacity: 0,
          y: -50,
          duration: duration,
          ease: "power2.in",
        },
        `+=2`
      ); // Delay before the section fades out
  };

  return (
    <>
      <div className="relative h-[3590px]">
        <div className="absolute z-0">
          <svg
            ref={svgRef}
            width="1400"
            height="3580"
            viewBox="0 0 1368 3580"
            fill="none"
          >
            <path
              d="M10 10C10 10 1304.48 287.51 1352.67 582.438C1423.66 1016.87 1071.02 1101.21 686.5 1214C499 1269 188.797 1461.9 151 1624.5C41.1643 2097 1306.57 1996.94 1374.5 2464.5C1443.57 2939.91 74.7099 2822.64 151 3297C211.486 3673.1 1351 3985 1351 3985"
              stroke="#D5D3F3"
              strokeOpacity="0.6"
              strokeWidth="10"
              strokeLinecap="round"
            />
            <circle ref={circleRef} cx="0" cy="0" r="30" fill="#D5D3F3" />{" "}
            {/* Increased radius for visibility */}
          </svg>
        </div>

        <div>
          <img
            className="max-w-[10%] min-w-[10%] z-20 translate-y-[-20vh] w-[100px]"
            src={blueBall}
            alt="features pictures"
          />
        </div>

        {/* Section 1 */}
        <div
          ref={sectionRefs[0]}
          className="section-1 absolute left-0 right-0 flex items-center justify-center opacity-0 translate-y-[-50px] transition-all duration-300"
          style={{
            top: "600px",
            zIndex: 10,
          }}
        >
          <div className="flex flex-col w-[700px] mr-[100px]">
            <h1 className="text-7xl font-medium mb-5 text-[#4D45B5]">01</h1>
            <h2 className="text-2xl font-semibold mt-2 mb-2">
              Lorem ipsum dolor sit amet
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. In mauris a consequat amet
              massa lorem. Nam morbi eu at quisque accumsan at tellus cras.
            </p>
          </div>
          <div>
            <img
              className="w-[600px] h-auto"
              src={featuresPictures}
              alt="features pictures"
            />
          </div>
        </div>

        {/* Section 2 */}
        <div
          ref={sectionRefs[1]}
          className="section-2 absolute left-0 right-0 flex items-center justify-center opacity-0 translate-y-[-50px] transition-all duration-300"
          style={{
            top: "1400px", // Adjust position as needed
            zIndex: 10, // Ensure it is above the SVG
          }}
        >
          <div>
            <img
              className="w-[600px] h-auto"
              src={featuresPictures}
              alt="features pictures"
            />
          </div>
          <div className="flex flex-col w-[700px] ml-[100px]">
            <h1 className="text-7xl font-medium mb-5 text-[#4D45B5]">02</h1>
            <h2 className="text-2xl font-semibold mt-2 mb-2">
              Sed do eiusmod tempor
            </h2>
            <p>
              Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div>

        {/* Section 3 */}
        <div
          ref={sectionRefs[2]}
          className="section-3 absolute left-0 right-0 flex items-center justify-center opacity-0 translate-y-[-50px] transition-all duration-300"
          style={{
            top: "2300px", // Adjust position as needed
            zIndex: 10, // Ensure it is above the SVG
          }}
        >
          <div className="flex flex-col w-[700px] mr-[100px]">
            <h1 className="text-7xl font-medium mb-5 text-[#4D45B5]">03</h1>
            <h2 className="text-2xl font-semibold mt-2 mb-2">
              Ut enim ad minim veniam
            </h2>
            <p>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
          <div>
            <img
              className="w-[600px] h-auto"
              src={featuresPictures}
              alt="features pictures"
            />
          </div>
        </div>

        {/* Section 4 */}
        <div
          ref={sectionRefs[3]}
          className="section-4 absolute left-0 right-0 flex items-center justify-center opacity-0 translate-y-[-50px] transition-all duration-300"
          style={{
            top: "3000px", // Adjust position as needed
            zIndex: 10, // Ensure it is above the SVG
          }}
        >
          <div>
            <img
              className="w-[600px] h-auto"
              src={featuresPictures}
              alt="features pictures"
            />
          </div>
          <div className="flex flex-col w-[700px] ml-[100px]">
            <h1 className="text-7xl font-medium mb-5 text-[#4D45B5]">04</h1>
            <h2 className="text-2xl font-semibold mt-2 mb-2">
              Duis aute irure dolor
            </h2>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesScroll;

import React from "react";
import BaseLayout from "../../components/BaseLayout";

const LeaveRequest = () => {
  return (
    <BaseLayout title={"Leave Request"}>
      <div className=" w-[90%] grid grid-cols-3 gap-8 pb-8 ">
        <div className="w- h-[16vh] bg-[#EBEAFF] rounded-lg shadow-lg border-[0.8px]  duration-200 ease-in-out hover:-translate-y-1">
          <div className="p-4">
            <h1 className="text-gray-700 text-3xl">5</h1>
            <div className="flex">
              <div className="text-[12px]">
                <br />
                Days of Leave available
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[16vh] bg-[#EBEAFF] rounded-lg shadow-lg border-[0.8px]  duration-200 ease-in-out hover:-translate-y-1">
          <div className="p-4">
            <h1 className="text-gray-700 text-3xl">10</h1>
            <div className="flex">
              <div className="text-[12px]">
                <br />
                Days of Leave available for the month
              </div>
            </div>
          </div>
        </div>
        <div className="w- h-[16vh] bg-[#EBEAFF] rounded-lg shadow-lg border-[0.8px]  duration-200 ease-in-out hover:-translate-y-1">
          <div className="p-4">
            <h1 className="text-gray-700 text-3xl">100</h1>
            <div className="flex">
              <div className="text-[12px]">
                <br />
                Total allocated hoidays
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-[90%] px-4 py-10 bg-white rounded-lg shadow-lg">
        <form className="grid grid-cols-[20%_80%] gap-4 pl-5">
          <label className="first-letter:text-base text-left col-span-1 font-medium text-gray-700 mt-2 ">
            Title
          </label>
          <input
            type="text"
            className=" w-[95%] pl-5 border border-gray-300 rounded-md "
            placeholder="Leave title"
          />

          <label className=" w-[95%] text-base text-left col-span-1 font-medium text-gray-700 mt-2 ">
            Leave Reason
          </label>
          <input
            type="text"
            className="w-[95%] pl-5 border border-gray-300 rounded-md "
            placeholder="Leave Reason"
          />

          <label className="text-left col-span-1 text-base font-medium text-gray-700 mt-2">
            Leave Description
          </label>
          <textarea
            className="w-[95%] pl-5 border border-gray-300 rounded-md "
            placeholder="Description.."
          ></textarea>

          <label className="text-base text-left col-span-1 font-medium text-gray-700 mt-2 ">
            Leave Duration
          </label>
          <input
            type="date"
            className="w-[30%] pl-5 border border-gray-300 rounded-md text-gray-700  "
            placeholder="Leave Reason"
          />
          <div className="text-center col-span-2 flex justify-end space-x-5 mr-10">
            <button className="px-6 py-2 bg-[#5964DB] text-white rounded-md">
              Request
            </button>
            <button className="px-6 py-2 border-2 border-[#5964DB] text-[#5964DB] font-semibold rounded-md ">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </BaseLayout>
  );
};

export default LeaveRequest;

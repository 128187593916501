import React from "react";
import useAxios from "../../../utils/axios/useAxios";

const TeacherResultTable = ({ student }) => {
  const { data: testPaperResults, error: resultError } = useAxios(
    `classrooms/testpaper-results`
  );
  const { data: testPapers, error: testPapersError } = useAxios(
    `classrooms/testpapers`
  );
  const { data: subjects, error: subjectError } = useAxios(
    `classrooms/subjects`
  );


//   console.log("TestPaperResults : " +JSON.stringify(testPaperResults, null, 2))
//   console.log("TestPapers : " +JSON.stringify(testPapers, null, 2))
//   console.log("Subjects : " +JSON.stringify(subjects, null, 2))
//   console.log("Students from TeacherResultTable : " +JSON.stringify(student, null, 2))



  if (!testPaperResults){
    return <div>Loading Test paper Results</div>;
  }
  else if(!testPapers){
    return <div>Loading Test papers</div>;
  }
  else if(!subjects){
    return <div>Loading Subjects</div>;
  }

  const resultOfStudent = testPaperResults.filter(
    (result) => result.student === student.id
  );

  const theoryPapers = testPapers.filter(
    (paper) => paper.category === "theory"
  );
  const practicalPapers = testPapers.filter(
    (paper) => paper.category === "practical"
  );

  const theoryPaperIds = theoryPapers.map((paper) => paper.id);
  const practicalPaperIds = practicalPapers.map((paper) => paper.id);

  // Filter results based on whether they are in theory or practical papers
  const filteredTheoryResults = resultOfStudent.filter((result) =>
    theoryPaperIds.includes(result.testpaper)
  );
  const filteredPracticalResults = resultOfStudent.filter((result) =>
    practicalPaperIds.includes(result.testpaper)
  );

  return (
    <div className="mt-6">
      {/* Practical Examination Table */}
      <h3 className="text-lg font-medium mb-4">Practical Examination</h3>
      <table className="min-w-full bg-white">
        <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px]">
          <tr>
            <th className="p-4 text-center rounded-tl-lg font-light">S.N</th>
            <th className="p-4 text-left font-light">Subject</th>
            <th className="p-4 text-center font-light">Test Paper</th>
            <th className="p-4 text-center font-light">Marks</th>
            <th className="p-4 text-center font-light">Percentage</th>
            <th className="p-4 text-center font-light rounded-tr-lg">Status</th>
          </tr>
        </thead>
        <tbody className="text-[14px] border border-gray-300">
          {filteredPracticalResults.length > 0 ? (
            filteredPracticalResults.map((result, index) => {
              const currentTestPaper = testPapers.find(
                (paper) => paper.id === result.testpaper
              );
              const currentSubject = subjects.find(
                (subject) => subject.id === currentTestPaper.subject
              );

              return (
                <tr
                  key={index}
                  className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg"
                >
                  <td className="p-4 border-b border-gray-300 text-center">
                    {index + 1}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-left">
                    {currentSubject.name}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {currentTestPaper.title}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {result.marks}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {result.percentage} %
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {result.failed ? "Fail" : "Pass"}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6" className="p-4 text-center">
                No practical results available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Theoretical Examination Table */}
      <h3 className="text-lg font-medium mt-10 mb-4">
        Theoretical Examination
      </h3>
      <table className="min-w-full bg-white">
        <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px]">
          <tr>
            <th className="p-4 text-center rounded-tl-lg font-light">S.N</th>
            <th className="p-4 text-left font-light">Subject</th>
            <th className="p-4 text-center font-light">Test Paper</th>
            <th className="p-4 text-center font-light">Marks</th>
            <th className="p-4 text-center font-light">Percentage</th>
            <th className="p-4 text-center font-light rounded-tr-lg">Status</th>
          </tr>
        </thead>
        <tbody className="text-[14px] border border-gray-300">
          {filteredTheoryResults.length > 0 ? (
            filteredTheoryResults.map((result, index) => {
              const currentTestPaper = testPapers.find(
                (paper) => paper.id === result.testpaper
              );
              const currentSubject = subjects.find(
                (subject) => subject.id === currentTestPaper.subject
              );

              return (
                <tr
                  key={index}
                  className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg"
                >
                  <td className="p-4 border-b border-gray-300 text-center">
                    {index + 1}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-left">
                    {currentSubject.name}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {currentTestPaper.title}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {result.marks}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {result.percentage} %
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {result.failed ? "Fail" : "Pass"}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6" className="p-4 text-center">
                No theoretical results available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TeacherResultTable;

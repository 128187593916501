import BaseLayout from "../../components/BaseLayout";
import { FaDownload, FaClipboard } from "react-icons/fa";
import useAxios from "../../../utils/axios/useAxios";
import { useState, useEffect } from "react";
import useAxiosAuth from "../../../utils/axios/useAxiosAuth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const PrincipalLeaveRequest = () => {
  const user = useAuthUser();

  const [remarks, setRemarks] = useState("");
  const [approved, setApproved] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [approvedBy, setApprovedBy] = useState("");
  const [organization, setOrganization] = useState("");
  const [teacher, setTeacher] = useState("");
  const [leaveId, setLeaveId] = useState();

  const axios = useAxiosAuth();

  const { data: leaveRequests, error } = useAxios(`classrooms/leaves/`);
  const { data: teachers } = useAxios(`classrooms/teachers/`);
  const { data: organizations } = useAxios(`core/organizations/`);

  useEffect(() => {
    if (organizations && user && user.profile.organization) {
      const currentOrganization = organizations.find(
        (org) => org.code === user.profile.organization
      );
      setOrganization(currentOrganization);
    }
  }, [organizations, user]);

  const submitLeaveStatus = (event, accepted) => {
    event.preventDefault();
    const request = {
      teacher: teacher,
      remarks: remarks,
      approved: approved,
      approved_by: approvedBy,
      organization: organization.code,
      attachment: null,
    };

    axios
      .patch(`api/classrooms/leaves/${leaveId}/`, request)
      .then((response) => {
        setRemarks("");
        setApproved(false);
        toast.success(
          `Leave ${accepted ? "Accepted" : "Rejected"} Successfully!`
        );
      })
      .catch((error) => {
        console.log(error);
        toast.error("Leave status update has failed!");
      });
  };

  return (
    <BaseLayout title={"Leave Request"}>
      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        {leaveRequests && leaveRequests.length > 0 ? (
          leaveRequests.map((request) => {
            const teacher = teachers?.find(
              (teacher) => teacher.id === request.teacher
            );

            return (
              !request.approved &&
              request.approved_by === null && (
                <div
                  key={request.id}
                  className="mb-4 py-10 px-8 space-y-6 border rounded-lg shadow-sm"
                >
                  <div className="flex justify-between items-center">
                    <h3 className="text-xl text-[#4D45B5] font-bold opacity-85">
                      {request.title}
                    </h3>
                    <p className="">
                      <span className="font-semibold opacity-75">Date: </span>
                      <span className="font-medium opacity-[57%]">
                        {new Date(request.created_at)
                          .toISOString()
                          .split("T")[0]}
                      </span>
                    </p>
                  </div>
                  <p className="">
                    <span className="font-semibold opacity-75">
                      Leave Taker:{" "}
                    </span>
                    <span className="opacity-[57%] font-medium">
                      {teacher
                        ? `${teacher.first_name} ${
                            teacher.middle_name ? teacher.middle_name : ""
                          } ${teacher.last_name}`
                        : "Unknown"}
                    </span>
                  </p>
                  <p className="">
                    <span className="font-semibold opacity-75">
                      Leave Reason:{" "}
                    </span>
                    <span className="opacity-[57%] font-medium">
                      {request.reason}
                    </span>
                  </p>
                  <p className="opacity-[57%] leading-[28px] text-justify font-medium tracking-wide">
                    {request.body}
                  </p>
                  <p className="">
                    <span className="font-semibold opacity-75">
                      Leave Duration:{" "}
                    </span>
                    <span className="opacity-[57%] font-medium">
                      {(new Date(request.end_date) -
                        new Date(request.start_date)) /
                        (1000 * 3600 * 24)}{" "}
                      days
                    </span>
                  </p>

                  {request.attachment && (
                    <button className="text-[#5964DB]">
                      <FaDownload /> Download Attachment
                    </button>
                  )}

                  <form onSubmit={(event) => submitLeaveStatus(event, accepted)}>
                    <div className="flex space-x-12 py-4">
                      <label
                        className="font-semibold opacity-75"
                        htmlFor="remarks"
                      >
                        Remarks:
                      </label>
                      <textarea
                        className="h-[10rem] w-full font-medium opacity-[57%]"
                        name="remarks"
                        placeholder="Remarks"
                        id="message"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="mt-4 flex justify-end space-x-4">
                      <button
                        type="submit"
                        className="px-4 py-2 bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
                        onClick={() => {
                          setApproved(true);
                          setLeaveId(request.id);
                          setApprovedBy(user.profile.id);
                          setTeacher(teacher.id);
                          setAccepted(true)
                        }}
                      >
                        Accept
                      </button>

                      <button
                        type="submit"
                        className="px-4 py-2 text-[#B54545] w-[10rem] border-2 border-[#D46067] font-medium tracking-wide rounded-lg hover:bg-[#D46067] hover:text-white hover:ease-in-out hover:transition duration-[550ms]"
                        onClick={() => {
                          setApproved(false);
                          setLeaveId(request.id);
                          setApprovedBy(user.profile.id);
                          setTeacher(teacher.id);
                          setAccepted(false)
                        }}
                      >
                        Reject
                      </button>
                    </div>
                  </form>
                </div>
              )
            );
          })
        ) : (
          <div className="z-20 mb-10 py-10 px-8 space-y-6 border rounded-2xl shadow-sm h-[24rem] border-gray-400 flex flex-col items-center justify-center">
            <p className="text-xl text-gray-400 font-bold opacity-85">
              No leave requests found.
            </p>
            <p className="text-sm text-gray-400 opacity-75">
              You're all caught up!
            </p>
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

export default PrincipalLeaveRequest;

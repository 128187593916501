import React, { useState } from "react";
import BaseLayout from "../../components/BaseLayout";
import withReactContent from "sweetalert2-react-content";
import AddNewAccountEntry from "./AddNewAccountEntry";
import Swal from "sweetalert2";


const AccountEntry = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const AddNewEntry = withReactContent(Swal);

  const showAddNewEntryForm = () => {
    const updatePopupWidth = () => {
      const isSmallScreen = window.innerWidth <= 768;
      const width = isSmallScreen ? "96%" : "60%";
      const popupContent = document.querySelector(".swal2-popup");
      if (popupContent) {
        popupContent.style.width = width;
      }
    };

    AddNewEntry.fire({
      html: (
        <AddNewAccountEntry />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        container: "overflow-hidden",
        popup: "rounded-lg",
      },
      padding: 0,
      width: "auto",
      didOpen: () => {
        window.addEventListener("resize", updatePopupWidth);
      },
      willClose: () => {
        window.removeEventListener("resize", updatePopupWidth);
      },
    });
  };

  const entries = [
    {
      name: "Product A",
      code: 101001,
      type: "revenue",
      tax: "Tax on sales",
      taxPercent: "10%",
    },
    {
      name: "Product B",
      code: 101002,
      type: "revenue",
      tax: "Tax on exempt",
      taxPercent: "8%",
    },
    {
      name: "Service C",
      code: 102001,
      type: "revenue",
      tax: "Tax on sales",
      taxPercent: "15%",
    },
    {
      name: "Product D",
      code: 101003,
      type: "revenue",
      tax: "Tax on sales",
      taxPercent: "7%",
    },
    {
      name: "Service E",
      code: 102002,
      type: "revenue",
      tax: "Tax on exempt",
      taxPercent: "12%",
    },
  ];
  

  const filteredEntries = entries.filter((entry) =>
    entry.code.toString().includes(searchTerm)
  );

  return (
    <BaseLayout title={"Account Entry"}>
      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <div className="relative align-middle">
            <input
              type="text"
              className="w-full ml-1 py-2 pl-4 pr-10 text-gray-700 bg-white border border-[#928DD3] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4D45B5] focus:border-transparent"
              placeholder="Search By Code"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-[#928DD3]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35M17.65 10.65a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
          <button className="px-4 py-2 bg-[#4D45B5] w-[12rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide" 
          onClick={showAddNewEntryForm}>
            + Add New Entry
          </button>
        </div>

        {/* Horizontal line added here */}
        <hr className="my-4" />

        <div className="mt-3">
          <table className="min-w-full bg-white">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px]">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">
                  S.N
                </th>
                <th className="p-4 text-left font-light">Name</th>
                <th className="p-4 text-center font-light">Code</th>
                <th className="p-4 text-center font-light">Type</th>
                <th className="p-4 text-center font-light">Tax</th>
                <th className="p-4 text-center font-light rounded-tr-lg">
                  Tax Percent
                </th>
              </tr>
            </thead>
            <tbody className="text-[14px] border border-gray-300">
              {filteredEntries.length > 0 ? (
                filteredEntries.map((entry, index) => (
                  <tr
                    key={index}
                    className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg"
                  >
                    <td className="p-4 border-b border-gray-300 text-center">{index + 1}</td>
                    <td className="p-4 border-b border-gray-300 text-left">{entry.name}</td>
                    <td className="p-4 border-b border-gray-300 text-center">{entry.code}</td>
                    <td className="p-4 border-b border-gray-300 text-center">{entry.type}</td>
                    <td className="p-4 border-b border-gray-300 text-center">{entry.tax}</td>
                    <td className="p-4 border-b border-gray-300 text-center">{entry.taxPercent}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="p-4 text-center">No entries found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AccountEntry;

import React, { useState, useEffect } from "react";
import {
  FaRegEdit,
  FaRegTrashAlt,
  FaFolder,
  FaFilePdf,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddReadingMaterial from "../../components/Teacher/AddReadingMaterial";
import EditReadingMaterial from "../../components/Teacher/EditReadingMaterial";
import BaseLayout from "../../components/BaseLayout";
import useAxios from "../../../utils/axios/useAxios";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import AuthProvider from "react-auth-kit/AuthProvider";
import createStore from "react-auth-kit/createStore";
import DeleteReading from "../../components/Teacher/DeleteReading";

const ReadingMaterial = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [studyMaterialId, setStudyMaterialId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const currentUser = useAuthUser();
  const currentUserId = currentUser?.id;

  const { data: readingMaterials } = useAxios("classrooms/study-materials/");
  const { data: teachers } = useAxios("classrooms/teachers/");
  const { data: readingMaterial } = useAxios(
    studyMaterialId ? `classrooms/study-materials/${studyMaterialId}` : null
  );

  const itemsPerPage = 6;
  const store = createStore({
    authName: "_auth",
    authType: "cookie",
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === "https",
  });

  const handleDownload = (id) => {
    alert(`Download paper with id: ${id}`);
    // Implement download logic here
  };

  const filteredData =
    readingMaterials?.filter(
      (item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        item.created_by === currentUserId
    ) || [];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const getTeacherName = (teacherId) => {
    const teacher = teachers?.find((t) => t.id === teacherId);
    return teacher ? `${teacher.first_name} ${teacher.last_name}` : "Unknown";
  };

  const AddPastPaper = withReactContent(Swal);
  const EditPastPaper = withReactContent(Swal);

  const showAddPastPaperForm = () => {
    const updatePopupWidth = () => {
      const isSmallScreen = window.innerWidth <= 768;
      const width = isSmallScreen ? "96%" : "60%";
      const popupContent = document.querySelector(".swal2-popup");
      if (popupContent) {
        popupContent.style.width = width;
      }
    };

    AddPastPaper.fire({
      html: (
        <AuthProvider store={store}>
          <AddReadingMaterial />
        </AuthProvider>
      ),
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        container: "overflow-hidden",
        popup: "rounded-lg",
      },
      padding: 0,
      width: "auto",
      didOpen: () => {
        window.addEventListener("resize", updatePopupWidth);
      },
      willClose: () => {
        window.removeEventListener("resize", updatePopupWidth);
      },
    });
  };

  const showEditPastPaperForm = () => {
    const updatePopupWidth = () => {
      const isSmallScreen = window.innerWidth <= 768;
      const width = isSmallScreen ? "96%" : "60%";
      const popupContent = document.querySelector(".swal2-popup");
      if (popupContent) {
        popupContent.style.width = width;
      }
    };

    if (readingMaterial) {
      EditPastPaper.fire({
        html: (
          <AuthProvider store={store}>
            <EditReadingMaterial readingMaterial={readingMaterial} />
          </AuthProvider>
        ),
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          container: "overflow-hidden",
          popup: "rounded-lg",
        },
        padding: 0,
        width: "auto",
        didOpen: () => {
          window.addEventListener("resize", updatePopupWidth);
        },
        willClose: () => {
          window.removeEventListener("resize", updatePopupWidth);
        },
      });
    }
  };

  useEffect(() => {
    if (studyMaterialId) {
      setIsEditModalOpen(true);
    }
  }, [studyMaterialId]);

  useEffect(() => {
    if (isEditModalOpen && readingMaterial) {
      showEditPastPaperForm();
    }
  }, [isEditModalOpen, readingMaterial]);

  return (
    <BaseLayout title={"Teacher's Content"}>
      <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold ml-4">Reading Material</h2>
          <button
            className="px-4 py-2 text-sm bg-[#4D45B5] w-[12rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
            onClick={showAddPastPaperForm}
          >
            Add Reading Material
          </button>
        </div>

        <hr className="my-4" />

        <div className="container mx-auto px-4">
          <table className="min-w-full bg-white">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px]">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">
                  S.N
                </th>
                <th className="p-4 text-left font-light">Resources</th>
                <th className="p-4 text-center font-light">Uploaded By</th>
                <th className="p-4 text-center font-light">Uploaded On</th>
                <th className="p-4 text-center font-light rounded-tr-lg">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="text-[14px] border border-gray-300">
              {currentData.map((item, index) => (
                <tr
                  key={item.id}
                  className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg"
                >
                  <td className="p-4 border-b border-gray-300 text-center">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-left">
                    <div className="flex items-center">
                      {item.type === "pdf" ? (
                        <span className="mr-2 text-[#5964DB]">
                          <FaFilePdf />
                        </span>
                      ) : (
                        <span className="mr-2 text-[#5964DB]">
                          <FaFolder />
                        </span>
                      )}
                      {item.title}
                    </div>
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {getTeacherName(item.created_by)}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {formatDate(item.created_at)}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    <button
                      onClick={() => {
                        setStudyMaterialId(item.id);
                      }}
                    >
                      <FaRegEdit className="w-5 h-5 mr-5" />
                    </button>
                    <button>
                      <FaRegTrashAlt className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex space-x-6 justify-center">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="size-5 text-gray-400"
            >
              <FaChevronLeft />
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="size-5 text-gray-400"
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ReadingMaterial;

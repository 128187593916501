import React, { useEffect, useState } from "react";
import useAxios from "../../../utils/axios/useAxios";

const TeacherStudentDetailCard = ({ student }) => {
  const { data: classRooms, error: classRoomError } = useAxios('classrooms/classrooms');
  //const { data: attendances, error: attendanceError } = useAxios('classrooms/student-attendances/');
  const [classRoom, setClassRoom] = useState(null);
  //const [attendance, setAttendance] = useState(null);


  useEffect(() => {
    if (classRooms && student && student.classroom) {
      const foundClassRoom = classRooms.find(classroom =>
        student.classroom === classroom.id
      );
      setClassRoom(foundClassRoom);
    }
  }, [classRooms, student]);

//   useEffect(() => {
//     if (attendances && student && student.id) {
//       const foundAttendance = attendances.find(attendance =>
//         student.id === attendance.id
//       );
//       setAttendance(foundAttendance);
//     }
//   }, [classRooms, student]);

//   console.log("Student classroom data:", student.classroom);
//   console.log("Found classroom:", classRoom);
  //console.log("Attendance :", JSON.stringify(attendances, null, 2))
  //console.log("Found attendance:", attendance);



  return (
    <div className="w-full p-4 items-center">
      <div>
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold mb-4">Student Detail</h2>
          <div>
            <img
              src={student.profile?.photo || '/default-photo.png'}
              alt="Student"
              className="w-24 h-24 rounded-full object-cover"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <p className="font-medium text-gray-600">
              Name: <span className="text-gray-800">{student ? `${student.first_name} ${student.last_name}` : 'N/A'}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Address: <span className="text-gray-800">{student ? student.address : 'N/A'}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Class: <span className="text-gray-800">{classRoom ? classRoom.name : 'Loading...'}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Section: <span className="text-gray-800">{classRoom && classRoom.curriculum_data ? classRoom.curriculum_data.name : 'N/A'}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Roll No: <span className="text-gray-800">{student ? student.id : 'N/A'}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Attendance: <span className="text-gray-800">%</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherStudentDetailCard;

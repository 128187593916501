import React from "react";
import BaseLayout from "../../components/BaseLayout";
import AddEvent from "../../components/AddEvents";

const PrincipalAddEvent = () => {
  return (
    <BaseLayout title={"Add Event"}>
      <AddEvent />
    </BaseLayout>
  );
};
export default PrincipalAddEvent;


const Faqs = [
    {
        question: "Lorem ipsum dolor sit amet consectetur. Mollis a.",
        answer: "Answer the frequently asked question in a simple sentence, a longish paragraph, or even in a list."
    },

    {
        question: "Lorem ipsum dolor sit amet consectetur.",
        answer: "Lorem ipsum dolor sit amet consectetur. Diam felis."
    },

    {
        question: "Lorem ipsum dolor sit amet consectetur.",
        answer: "Lorem ipsum dolor sit amet consectetur. Diam felis."
    },
    {
        question: "Lorem ipsum dolor sit amet consectetur.",
        answer: "Lorem ipsum dolor sit amet consectetur. Diam felis."
    },
    {
        question: "Lorem ipsum dolor sit amet consectetur.",
        answer: "Lorem ipsum dolor sit amet consectetur. Diam felis."
    }
    
]



const HomeFaq = () => {
    return(
        <div className="min-h-screen flex px-12">
            <div className="my-auto min-w-[50%] max-w-[50%]">
                <img className="w-[80%] h-[80%]" src={require("../../../assets/images/home/homeFaqPic.png")} alt="" />
            </div>
            <div className="my-auto min-w-[50%] max-w-[50%]">
                <h1 className="font-medium text-2xl text-[#3118A3]">Lorem Ipsum</h1>
                <div className="">
                    {Faqs.map((item, i) => {
                        <div className="">
                            <h1>{item.question}</h1>
                            <p>{item.answer}</p>
                            <p>a</p>
                        </div>
                    })}
                </div>
            </div>
            
        </div>
    )
}

export default HomeFaq;
import React, { useState } from "react";
import LoginArc from "../../assets/images/login/loginArc.png";
import LoginRect from "../../assets/images/login/LoginRect.png";
import { LuEye, LuEyeOff } from "react-icons/lu";

import useSignIn from "react-auth-kit/hooks/useSignIn";
import axiosInstance from "../../utils/axios/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";


const LoginPage = () => {

  const totalCapacity = 10;
  const usedCapacity = 3;
  const percentage = (usedCapacity / totalCapacity) * 100;
  const circumference = 2 * Math.PI * 20;

  const signIn = useSignIn();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisibility((prevVisibility) => !prevVisibility);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    axiosInstance
      .post("/api/users/token/", formData)
      .then((response) => {
        console.log({
          auth: {
            token: response.data.access,
            type: "Bearer",
          },
          userState: response.data.user,
        });
        if (
          signIn({
            auth: {
              token: response.data.access,
              type: "Bearer",
            },
            userState: response.data.user,
          })
        ) {
          toast.success("Login Successful.");

          switch (response.data.user.user_type) {
            case "TEACHER":
              navigate("/teacher/dashboard");
              break;
            
            case "STUDENT":
              navigate("/student/dashboard");
              break;
            
            case "PARENT":
              navigate("/parents/dashboard");
              break;
            
            case "ACCOUNTANT":
              navigate("/accounting/dashboard");
              break;
            
            case "HR":
              navigate("/hr/dashboard");
              break;
            
            case "PRINCIPAL":
              navigate("/principal/dashboard");
              break;
            
            default:
              console.log("Unknown user type");
              break;
          }
          
          
        } else {
          toast.error("Login Failed.");
        }
      })
      .catch((error) => {
        toast.error("Incorrect Username or Password.");
      });
  };

  return (
    <div className="min-h-screen w-full overflow-hidden">
      {/* Header */}
      <header className="w-full flex items-center h-max">
        <div className="text-4xl font-bold m-6">LOGO</div>
      </header>

      {/* Left Side: Sign In Form */}
      <div className="absolute w-[40%] h-screen top-0 flex items-center">
        <div className="align-middle mx-auto w-[63%]">
          <h2 className="text-2xl text-[#4D45B5] mb-6 font-semibold text-center">
            SIGN IN TO YOUR ACCOUNT
          </h2>
          <form onSubmit={onSubmit}>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-lg text-[#464646]"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 text-base font-light rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-lg text-[#464646]"
              >
                Password
              </label>
              <div className=" relative flex items-center">
                <input
                  type={passwordVisibility ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 text-base font-light rounded"
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 m-4 flex items-center"
                >
                  {passwordVisibility ? <LuEyeOff /> : <LuEye />}
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="rememberMe"
                  className="mr-2 text-base font-light focus:ring-transparent "
                />
                <label htmlFor="rememberMe">Remember me</label>
              </div>
              <a href="../view" className="text-[#4D45B5] text-sm">
                Forgot your password?
              </a>
            </div>
            <button
              type="submit"
              className="w-full bg-[#4D45B5] text-white font-bold py-2 px-4 my-2 rounded"
            >
              Sign In
            </button>
          </form>
          <div className="flex justify-center items-center my-4 text-sm">
            <p className="text-[#919191]">Don't have an account? </p>
            <a
              href="./register"
              className="text-[#4D45B5] ml-2 hover:underline"
            >
              Sign up
            </a>
          </div>
        </div>
      </div>

      {/* Right Side: Information*/}
      <div className="absolute w-[60%] h-screen right-0 top-0 bg-[#766FD0] flex items-center">
        <div className="text-center px-16 z-10">
          <h1 className="text-5xl text-white mb-8 font-semibold">
            Lorem Ipsum
          </h1>
          <p className="text-xl text-[#F2F2F2] tracking-tight font-light">
            Lorem ipsum dolor sit amet consectetur. Egestas eu volutpat potenti
            tellus viverra ultrices facilisis arcu. Ipsum elementum purus tellus
            cras morbi malesuada. Lorem ipsum dolor sit amet consectetur.
            Egestas eu volutpat potenti tellus viverra ultrices facilisis arcu.
            Ipsum elementum purus tellus cras morbi malesuada.
          </p>

          <div className="relative mt-12">
            <img src={LoginRect} alt="arc" className="p-12" />

            {/* Storage Capacity */}
            <div className="absolute top-2 right-2 bg-white px-2 py-1 rounded-2xl shadow-md w-fit">
              <span className="text-sm font-medium">Storage Capacity</span>
              <div className="flex items-center">
                <div className="relative w-[50px] h-[50px]">
                  <svg className="w-full h-full transform -rotate-90">
                    <circle
                      cx="25"
                      cy="25"
                      r="20"
                      stroke="#E0E0E0"
                      strokeWidth="4"
                      fill="none"
                    />
                    <circle
                      cx="25"
                      cy="25"
                      r="20"
                      stroke="#534BB6"
                      strokeWidth="4"
                      fill="none"
                      strokeDasharray={circumference}
                      strokeDashoffset={
                        circumference - (percentage / 100) * circumference
                      }
                    />
                  </svg>
                  <div className="absolute inset-0 flex items-center justify-center text-xs font-semibold text-[#534BB6]">
                    {usedCapacity}GB
                  </div>
                </div>
                <span className="text-lg font-bold mx-1">
                  {usedCapacity} GB/{totalCapacity} GB
                </span>
              </div>
            </div>
          </div>
        </div>

        <img
          src={LoginArc}
          alt="arc"
          className="absolute right-0 bottom-0 z-0"
        />
      </div>
    </div>
  );
};

export default LoginPage;

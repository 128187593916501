import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddAssignmentForm from "../../components/Teacher/AddAssignment";
import EditAssignmentForm from "../../components/Teacher/EditAssignment";

import useAxios from "../../../utils/axios/useAxios.js";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

import {
  FaRegEdit,
  FaRegTrashAlt,
  FaFolder,
  FaFilePdf,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import BaseLayout from "../../components/BaseLayout";

import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const TeacherAssignment = () => {
  // API data pull
  const { data, error } = useAxios(`classrooms/assignments/`);

  const currentUser = useAuthUser();
  const currentUserId = currentUser?.id;

  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();

  console.log(isAuthenticated);
  console.log(authHeader);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const { data: teachers, error: teacherError } = useAxios(
    `classrooms/teachers/`
  );

  // const [assignments, setAssignments] = useState([])

  const AddAssignment = withReactContent(Swal);
  const EditAssignment = withReactContent(Swal);

  const itemsPerPage = 6;

  const filteredData = data?.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      item.created_by === currentUserId
  ) || [];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const getTeacherName = (teacherId) => {
    const teacher = teachers?.find((t) => t.id === teacherId);
    return teacher ? `${teacher.first_name} ${teacher.last_name}` : "Unknown";
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const showAddAssignmentForm = () => {
    const firePopup = () => {
      AddAssignment.fire({
        html: <AddAssignmentForm />,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          container: "overflow-hidden",
          popup: "rounded-lg",
        },
        padding: 0,
        width: "auto",
      });
    };

    firePopup(); // Initial call to create the popup
  };

  const showEditAssignmentForm = () => {
    const firePopup = () => {
      EditAssignment.fire({
        html: <EditAssignmentForm />,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          container: "overflow-hidden",
          popup: "rounded-lg",
        },
        padding: 0,
        width: "auto",
      });
    };

    firePopup(); // Initial call to create the popup
  };

  return (
    <BaseLayout title={"Teacher's Content"}>
      <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold ml-4">Assignments</h2>
          <button
            className="px-4 py-2 text-sm bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
            onClick={showAddAssignmentForm}
          >
            Add Assignment
          </button>
        </div>

        <hr className="my-4" />

        <div className="container mx-auto px-4">
          <table className="min-w-full bg-white ">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px] ">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">
                  S.N
                </th>
                <th className="p-4 text-left font-light">Resources</th>
                <th className="p-4 text-center font-light">Uploaded By</th>
                <th className="p-4 text-center font-light">Uploaded On</th>
                <th className="p-4 text-center font-light rounded-tr-lg">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="text-[14px] border  border-gray-300">
              {currentData.map((item, index) => (
                <tr
                  key={item.id}
                  className="hover:bg-[#F5F7FF] divide-x divide-gray-300  rounded-b-lg"
                >
                  <td className="p-4 border-b border-gray-300 text-center">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-left ">
                    <div className=" flex items-center">
                      {item.type === "pdf" ? (
                        <span className="mr-2 text-[#5964DB]">
                          <FaFilePdf />
                        </span>
                      ) : (
                        <span className="mr-2 text-[#5964DB]">
                          <FaFolder />
                        </span>
                      )}
                      <Link
                        to={`/teacher/assignments/${item.id}`}
                        className="hover:text-[#4D45B5] duration-200"
                      >
                        {item.title}
                      </Link>
{/* hello developers this code is gud */}
                      {/* {item.title} */}
                    </div>
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {getTeacherName(item.created_by)}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {formatDate(item.created_at)}
                  </td>
                  <td className="p-4 border-b border-gray-300 justify-center flex items-center">
                    <Link to={`/teacher/viewSubmission/${item.id}`}>
                      <MdOutlineRemoveRedEye className="w-5 h-5 mr-5" />
                    </Link>
                    <button>
                      <FaRegEdit
                        className="w-5 h-5 mr-5"
                        onClick={showEditAssignmentForm}
                      />
                    </button>
                    <button>
                      <FaRegTrashAlt className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex space-x-6 justify-center">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="size-5 text-gray-400 "
            >
              <FaChevronLeft />
            </button>
            <span className="">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="size-5 text-gray-400  "
            >
              {/* good work developers */}
              <FaChevronRight className="text-sm " />
            </button>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default TeacherAssignment;

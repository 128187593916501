import React, { useState } from "react";
import BaseLayout from "../../components/BaseLayout";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddNewTransaction from "./AddNewTransaction"; // Ensure this component is created

const ManualJournal = () => {
  const AddNewTransactionPopup = withReactContent(Swal);

  const showAddNewTransactionForm = () => {
    const updatePopupWidth = () => {
      const isSmallScreen = window.innerWidth <= 768;
      const width = isSmallScreen ? "96%" : "60%";
      const popupContent = document.querySelector(".swal2-popup");
      if (popupContent) {
        popupContent.style.width = width;
      }
    };

    AddNewTransactionPopup.fire({
      html: <AddNewTransaction />,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        container: "overflow-hidden",
        popup: "rounded-lg",
      },
      padding: 0,
      width: "auto",
      didOpen: () => {
        window.addEventListener("resize", updatePopupWidth);
      },
      willClose: () => {
        window.removeEventListener("resize", updatePopupWidth);
      },
    });
  };

  const entries = [
    {
      description: "Product A Sales",
      account: 101001,
      taxRate: "10%",
      debit: "$500",
      credit: "$50",
    },
    {
      description: "Product B Sales",
      account: 101002,
      taxRate: "8%",
      debit: "$400",
      credit: "$32",
    },
    {
      description: "Service C Income",
      account: 102001,
      taxRate: "15%",
      debit: "$1000",
      credit: "$150",
    },
    {
      description: "Product D Sales",
      account: 101003,
      taxRate: "7%",
      debit: "$600",
      credit: "$42",
    },
    {
      description: "Service E Income",
      account: 102002,
      taxRate: "12%",
      debit: "$800",
      credit: "$96",
    },
  ];

  return (
    <BaseLayout title={"Manual Journal"}>
      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="space-y-8">
          <div className="flex">
            <div className="flex px-4 items-center w-[75%] justify-between">
              <label className="w-[25%] font-semibold opacity-75" htmlFor="narration">
                Narration
              </label>
              <input
                className="w-[75%] rounded-md border-opacity-[57%] px-4 py-2"
                type="text"
                id="narration"
                placeholder="Notice Title"
                required
              />
            </div>

            <div className="w-[25%] flex justify-end">
              <p className="">
                <span className="font-semibold opacity-75">Date: </span>
                <span className="font-medium opacity-[57%]">2024-02-24</span>
              </p>
            </div>
          </div>

          <div className="flex">
            <div className="grid grid-cols-4 mx-4 gap-x-0 items-center w-[72%]">
              <label className="text-nowrap font-semibold opacity-75" htmlFor="narrationDate">
                Narration Date
              </label>
              <input
                className="w-full rounded-md border-opacity-[57%] px-4 py-2"
                type="date"
                id="narrationDate"
                placeholder="announcementDate"
                required
              />
              <label className="text-nowrap w-[70%] ml-12 font-semibold opacity-75" htmlFor="accounts">
                Accounts Are
              </label>
              <input
                className="w-full rounded-md border-opacity-[57%] px-4 py-2"
                type="text"
                id="accounts"
                placeholder="Account"
                required
              />
            </div>

            <div className="w-[25%] flex justify-end">
              <button
                type="button"
                className="px-4 py-2 bg-[#4D45B5] w-[12rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
                onClick={showAddNewTransactionForm}
              >
                + Add Transaction
              </button>
            </div>
          </div>

          <div className="flex px-4 items-center">
            <table className="min-w-full bg-white">
              <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px]">
                <tr>
                  <th className="p-4 text-center rounded-tl-lg font-light">
                    S.N
                  </th>
                  <th className="p-4 text-left font-light w-[25%]">
                    Description
                  </th>
                  <th className="p-4 text-center font-light w-[20%]">Account</th>
                  <th className="p-4 text-center font-light">Tax Rate</th>
                  <th className="p-4 text-center font-light">Debit</th>
                  <th className="p-4 text-center font-light rounded-tr-lg">
                    Credit
                  </th>
                </tr>
              </thead>
              <tbody className="text-[14px] border border-gray-300">
                {entries.map((entry, index) => (
                  <tr key={index} className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg">
                    <td className="p-4 border-b border-gray-300 text-center">{index + 1}</td>
                    <td className="p-4 border-b border-gray-300 text-left w-[25%]">{entry.description}</td>
                    <td className="p-4 border-b border-gray-300 text-center w-[20%]">{entry.account}</td>
                    <td className="p-4 border-b border-gray-300 text-center">{entry.taxRate}</td>
                    <td className="p-4 border-b border-gray-300 text-center">{entry.debit}</td>
                    <td className="p-4 border-b border-gray-300 text-center">{entry.credit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ManualJournal;
  
import React, { useState } from "react";
import { IoNotifications } from "react-icons/io5";
import ResultChart from "../../components/StudentResult/ResultChart";
import useAxios from "../../../utils/axios/useAxios";
import TeacherStudentDetailCard from "./TeacherStudentDetailCard";
import { useParams } from "react-router-dom";
import TeacherResultTable from "./TeacherResultTable";

const ExamResult = () => {

  const { studentId } = useParams();
  
  const [searchTerm, setSearchTerm] = useState("");
  const [year, setYear] = useState("2027");
  const [examType, setExamType] = useState("Term 1");
  const { data: students, error } = useAxios(`classrooms/students/${studentId}`); 

  // Log student data to the console
  // console.log("Student Data:", students);
  // console.log("Error:", error);

  // Optionally handle error case

  // Assuming students is an array and you want to display the first student as an example
  //const student = students && students.length > 0 ? students[0] : null;

  // console.log(studentId)

  // console.log("Student ID from URL:", studentId);
  // console.log("Fetched student data:", students);
  
  // console.log("Fetched student data:", students);


  return (
    <div className="max-w-screen-2xl mx-auto px-10 py-5 font-poppins min-h-screen bg-[#FBFBFF] w-full text-neutral-900">
      <div className="flex justify-between items-center mb-12">
        <div>
          <h1 className="text-2xl font-semibold text-[#5964DB]">
            Exam Results
          </h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-[#928DD3] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4D45B5] focus:border-transparent"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-[#928DD3]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35M17.65 10.65a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
          <div className="text-[#928DD3]">
            <IoNotifications className="w-7 h-7 mt-2 duration-200 hover:text-[#4D45B5] hover:scale-110" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-[55%_43%] gap-4 py-10">
        <div className="container mx-auto px-2 py-4 bg-white rounded-lg shadow-lg">
          <ResultChart />
        </div>
        <div className="container mx-auto px-2 py-4 bg-white rounded-lg shadow-lg">
          {students ? <TeacherStudentDetailCard student={students} /> : <div>Loading...</div>}
        </div>
      </div>

      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold">Result</h2>
          <div className="space-x-4 text-[14px]">
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="2027">2027</option>
              <option value="2026">2026</option>
              <option value="2025">2025</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
            </select>
            <select
              className="border px-8 py-2 text-[#928DD3] border-[#928DD3] bg-[#FBFBFF] rounded-full focus:outline-none  focus:ring-[#4D45B5] focus:border-transparent"
              value={examType}
              onChange={(e) => setExamType(e.target.value)}
            >
              <option value="Term 1">Term 1</option>
              <option value="Term 2">Term 2</option>
              <option value="Unit Test">Unit Test</option>
            </select>
            <button
              className="px-4 py-2 bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide"
              // onClick={downloadResults}
            >
              Download
            </button>
          </div>
        </div>
        {<TeacherResultTable student={students} />}
      </div>
    </div>
  );
};

export default ExamResult;

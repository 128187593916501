import React, { useState } from "react";

import Editor from "../Editor.js";
import { MdAttachFile, MdDeleteOutline } from "react-icons/md";
import axiosInstance from "../../../utils/axios/axios.js";
import useAxiosAuth from "../../../utils/axios/useAxiosAuth.js";

const AddAssignmentForm = () => {
  const [fileAttachment, setFileAttachment] = useState("");



  return (
    <div className="p-6 max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-[#827CCC]">
          Add Assignment
        </h2>
      </div>

      <div className="grid grid-cols-4 gap-y-6 gap-x-1 mb-4">
        <label className="text-left text-base font-medium text-gray-700 mt-2">
          Assigned By
        </label>
        <input
          type="text"
          className="w-full p-2 border border-gray-300 rounded-md"
          placeholder="Assigned By"
        />
        <label className="text-center text-base font-medium text-gray-700 mt-2">
          Assigned To
        </label>
        
        <select className="w-full p-2 border border-gray-300 rounded-md appearance-none bg-white">
          <option>Lorem</option>
        </select>

        <label className="text-base text-left col-span-1 font-medium text-gray-700 mt-2">
          Assignment Topic
        </label>
        <input
          type="text"
          className="col-span-3 p-2 border border-gray-300 rounded-md"
          placeholder="Assignment topic"
        />

        <label className="text-left col-span-1 text-base font-medium text-gray-700 mt-2">
          Assignment Description
        </label>
        <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full col-span-3">
          <Editor/>
        </div>
          

        <label className="text-left text-base font-medium text-gray-700 mt-2">
          Assigned Date
        </label>
        <input
          type="date"
          className="w-full p-2 border border-gray-300 rounded-md"
        />

        <label className="text-center text-base font-medium text-gray-700 mt-2">
          Submission Date
        </label>
        <input
          type="date"
          className="w-full p-2 border border-gray-300 rounded-md"
        />

        <label className="text-left text-base font-medium text-gray-700 mt-2">
          Assignment File
        </label>
        <div className="flex items-center col-span-3 justify-between">
          <div className="border border-gray-300 rounded-md p-2 w-full">
            <div className="flex text-base text-gray-600 items-center">
              <MdAttachFile />
              {fileAttachment ? (
                <span>{fileAttachment}</span>
              ) : (
                <span>Attach assignment file here</span>
              )}
              <input
                type="file"
                name="fileAttachment"
                id="fileAttachment"
                className="hidden"
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    setFileAttachment(e.target.files[0].name);
                  }
                }}
              />
            </div>
            {fileAttachment && (
              <MdDeleteOutline
                className="text-base cursor-pointer"
                onClick={() => {
                  setFileAttachment("");
                  document.getElementById("fileAttachment").value = null;
                }}
              />
            )}
          </div>
          <button
            className="ml-2 px-4 py-2.5 flex-shrink-0 border border-[#5964DB] text-[#4D45B5] text-sm rounded-md"
            onClick={() => document.getElementById("fileAttachment").click()}
          >
            Add File
          </button>
        </div>

        <div className="text-base text-center col-span-4 flex justify-end">
          <button className="px-6 py-2 bg-[#5964DB] text-white font-semibold rounded-md">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAssignmentForm;

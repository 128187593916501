import React from "react";
import { HiUserAdd } from "react-icons/hi";
import BaseLayout from "../components/BaseLayout";

const UserProfile = () => {
  return (
    <BaseLayout title={"Settings"}>
      <div className=" w-[95%] p-8 bg-white rounded-lg shadow-lg">
        <h1 className="font-semibold text-2xl mb-4">User Profile</h1>
        <form className="grid grid-cols-3 gap-x-12 gap-y-6 text-base text-left font-medium">
          <div>
            <label className="text-gray-700">First Name</label>
            <input
              type="text"
              className="w-full pl-4 border border-gray-300 rounded-md"
              placeholder="Lorem"
            />
          </div>
          <div>
            <label className="text-gray-700">Middle Name</label>
            <input
              type="text"
              className="w-full pl-4 border border-gray-300 rounded-md"
              placeholder="Lorem"
            />
          </div>
          <div className="row-span-4 border border-gray-300 rounded-md mb-16 mr-12 flex items-center justify-center cursor-pointer">
            <HiUserAdd className="text-[160px] text-gray-700" />
          </div>
          <div>
            <label className="text-gray-700">Last Name</label>
            <input
              type="text"
              className="w-full pl-4 border border-gray-300 rounded-md"
              placeholder="Lorem"
            />
          </div>
          <div>
            <label className="text-gray-700">Country</label>
            <input
              type="text"
              className="w-full pl-4 border border-gray-300 rounded-md"
              placeholder="Lorem"
            />
          </div>
          <div>
            <label className="text-gray-700">Phone Number</label>
            <input
              type="text"
              className="w-full pl-4 border border-gray-300 rounded-md"
              placeholder="Lorem"
            />
          </div>
          <div>
            <label className="text-gray-700">Contact Email</label>
            <input
              type="email"
              className="w-full pl-4 border border-gray-300 rounded-md"
              placeholder="Lorem"
            />
          </div>
          <div>
            <label className="text-gray-700">College Email</label>
            <input
              type="email"
              className="w-full pl-4 border border-gray-300 rounded-md"
              placeholder="Lorem"
            />
          </div>

          {/*Emergency contact information */}
          <p className="font-semibold text-lg mt-2 col-start-1 underline">
            Emergency contact Information
          </p>
          <div className="col-start-1">
            <label className="text-gray-700">First Name</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-md pl-4 text-[#5E5D5D]"
              placeholder="Lorem"
            />
          </div>
          <div>
            <label className="text-gray-700">Last Name</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-md pl-4 text-[#5E5D5D]"
              placeholder="Lorem"
            />
          </div>
          <div className="col-start-1">
            <label className="text-gray-700">Relation</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-md pl-4 text-[#5E5D5D]"
              placeholder="Lorem"
            />
          </div>
          <div>
            <label className="text-gray-700">Contact Number</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-md pl-4 text-[#5E5D5D]"
              placeholder="+977 9841547704"
            />
          </div>

          <div className="col-span-2 flex justify-end mt-4 text-sm">
            <button className="py-1 px-12 mr-4 text-[#5964DB] border border-[#5964DB] rounded-md">
              Clear
            </button>
            <button className="py-1 px-12 bg-[#5964DB] text-white rounded-md">
              Save
            </button>
          </div>
        </form>
      </div>
    </BaseLayout>
  );
};

export default UserProfile;

import React, { useState, useEffect } from "react";
import { IoNotifications } from "react-icons/io5";
import { useParams, Link } from "react-router-dom";
import { MdAttachFile, MdDeleteOutline } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import useAxios from "../../../utils/axios/useAxios";

const TeacherAssignmentDetail = () => {

  const [searchTerm, setSearchTerm] = useState("");

  const { assignmentId } = useParams();
  const { data, error } = useAxios(`classrooms/assignments/${assignmentId}`);

  console.log(data);
  if (!data) {
    return (
      <div className="max-w-screen-2xl mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-semibold text-red-600">
          Assignment not found
        </h2>
        <p className="mt-4">
          The assignment you are looking for does not exist. Please check the
          URL or return to the assignment list.
        </p>
        <Link
          to="/teacher/teacherAssignments"
          className="mt-6 inline-block px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
        >
          Go Back to Assignments
        </Link>
      </div>
    );
  }


  return (
    <div className="max-w-screen-2xl mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
      <div className="flex justify-between items-center mb-12">
        <div>
          <h1 className="text-2xl font-semibold text-[#5964DB]">
            Teachers Content
          </h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-[#928DD3] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4D45B5] focus:border-transparent"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-[#928DD3]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35M17.65 10.65a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
          <div className="text-[#928DD3]">
            <IoNotifications className="w-7 h-7 mt-2 duration-200 hover:text-[#4D45B5] hover:scale-110" />
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <Link
            to="/teacher/assignments"
            className="text-lg font-semibold text-gray-800"
          >
            &larr; Assignments
          </Link>
        </div>
        <hr className="my-4" />
        <div className="px-10">
          <div className="border border-gray-200">
            <div className="p-6 ">
              <h2 className="text-xl font-semibold mb-4">
                {data.title}
              </h2>
              <div className="mb-4 flex">
                <p className="font-medium w-48">Assignment Description :</p>
                <ul className="list-disc ml-4 list-inside text-gray-600">
                  {data.description}
                </ul>
              </div>
              <div className="mb-4 flex">
                <p className="font-medium w-48">Assigned Material :</p>
                <a href={data.attachment} className="flex items-center ml-4 text-blue-500 cursor-pointer">
                  <IoMdDownload />
                  <p className="mx-2">Download Attachment</p>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherAssignmentDetail;

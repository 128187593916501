import React from "react";
import useAxios from "../../../utils/axios/useAxios";

const NoticeDashboard = () => {
  const { data: notices, error: noticesError } = useAxios(
    `classrooms/announcements/`
  );
  const { data: issuedBy, error: issuedByError } = useAxios(`users/users/`);

  if (!notices || !issuedBy) {
    return (
      <div className="w-[98%] bg-white rounded-lg shadow-md px-4 py-6 h-[62vh]">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="w-[98%] bg-white rounded-lg shadow-md px-4 py-6 h-[62vh]">
      <div className="flex justify-between">
        <h2 className="text-2xl font-bold mb-4">Notices</h2>
      </div>
      <hr className="py-2" />
      <div className="max-h-[48vh] overflow-y-auto space-y-4 mb-2">
        {notices.map((notice, index) => {
          const issuedUser = issuedBy.find(
            (user) => user.id === notice.created_by
          );

          return (
            <div key={index} className="bg-gray-50 p-4 rounded-lg shadow">
              <h3 className="text-xl font-semibold mb-2">{notice.title}</h3>
              <p className="text-gray-700 mb-2">{notice.description}</p>
              <div className="text-right">
                <span className="text-gray-500">
                  -{issuedUser?.username || "Unknown"}
                </span>
                <span className="text-gray-500 ml-4">
                  {new Date(notice.announcement_date).toLocaleDateString()} 
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NoticeDashboard;

import React from "react";
import BaseLayout from "../../components/BaseLayout";
import HRSchedule from "../../components/HR/HRSchedule";

const HrTimeTable = () => {
  return (
    <BaseLayout title={"Time Table"}>
      <div className="container mx-auto px-4 py-6 bg-white rounded-lg shadow-lg">
        <HRSchedule />
      </div>
    </BaseLayout>
  );
};

export default HrTimeTable;

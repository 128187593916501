import React, { useState } from "react";
import { IoNotifications } from "react-icons/io5";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SalaryDetail from "./SalaryInforamation";

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}

const SalaryInformation = () => {
  const data = [
    {
      id: 1,
      designationlevel: "Lorem ipsum dolor sit amet consectetur.",
      month: "January",
      amount: 25,
    },
  ];

  const ViewSalaryInformation = withReactContent(Swal);
  const [currentDate] = useState(getDate());

  const showSalaryInformation = () => {
    ViewSalaryInformation.fire({
      html: <SalaryDetail />,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        container: "overflow-hidden",
        popup: "rounded-lg",
      },
      padding: 0,
      width: "auto",
    });
  };

  return (
    <div className="max-w-screen-2xl mx-auto px-10 py-5 font-poppins min-h-screen bg-[#FBFBFF] w-full text-neutral-900">
      <div className="container mx-auto px-4 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold ml-4">John Doe</h2>
          <div className="space-x-4 text-[16px] mr-4">Date: {currentDate} </div>
        </div>

        <hr className="my-4" />

        <div className="container mx-auto px-4">
          <table className="min-w-full bg-white ">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px] ">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">
                  S.N
                </th>
                <th className="p-4 text-left font-light">Designation Level</th>
                <th className="p-4 text-center font-light">Month</th>
                <th className="p-4 text-center font-light">Amount</th>
                <th className="p-4 text-center font-light rounded-tr-lg">
                  Result
                </th>
              </tr>
            </thead>

            <tbody className="text-[14px] border border-gray-300">
              {data.map((item, index) => (
                <tr
                  key={item.id}
                  className="hover:bg-[#F5F7FF] divide-x divide-gray-300 rounded-b-lg"
                >
                  <td className="p-4 border-b border-gray-300 text-center">
                    {index + 1}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-left">
                    {item.designationlevel}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {item.month}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {item.amount}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    <button
                      className="hover:text-[#4D45B5] text-[#4D45B5] underline font-medium duration-200"
                      onClick={showSalaryInformation}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="text-center col-span-2 flex justify-end">
            <button className="mt-4 px-4 py-2 bg-[#4D45B5] w-[10rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide">
              Request
            </button>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default SalaryInformation;

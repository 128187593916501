import { useState } from "react";
import BaseLayout from "../../components/BaseLayout";
import { BiSearchAlt } from "react-icons/bi";
import useAxios from "../../../utils/axios/useAxios";

const PrincipalLeaveData = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const { data: leaveRequests, error } = useAxios(`classrooms/leaves/`);
  const { data: teachers } = useAxios(`classrooms/teachers/`);
  const { data: approvers } = useAxios(`users/admins/`); // Assuming `approvedBy` refers to users/admins

  const currentDate = new Date().toLocaleDateString();

  return (
    <BaseLayout title={"Leave Requests"}>
      <div className="container mx-auto px-12 py-10 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-6">
          <div className="relative w-[30%]">
            <input
              className="py-[0.4rem] pl-4 pr-10 border rounded-full w-full border-[#827CCC] text-[#827CCC]"
              type="text"
              placeholder="Search by person"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <BiSearchAlt
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#827CCC]"
              size={24}
            />
          </div>
          <p className="opacity-[57%] tracking-wider">Date: {currentDate}</p>
        </div>

        {leaveRequests && leaveRequests.length > 0 ? (
          leaveRequests
            .filter(request => (request.approved && request.approved_by!==null) || (!request.approved && request.approved_by!==null))
            .map((request) => {
              const teacher = teachers?.find(teacher => teacher.id === request.teacher);
              const approver = approvers?.find(admin => admin.id === request.approvedBy); // Get the approver's data

              return (
                <div
                  key={request.id}
                  className={`mb-10 py-10 px-8 space-y-6 border rounded-2xl shadow-sm ${
                    request.approved
                      ? "border-l-[#4D45B5] border-l-4"
                      : "border-l-[#D46067] border-l-4"
                  }`}
                >
                  {console.log(request)}
                  <div className="flex justify-between items-center">
                  {request.approved ?(
                      <h3 className="text-xl text-[#4D45B5] font-bold opacity-85">
                      {request.title}
                    </h3>
                    ):
                    (<h3 className="text-xl text-[#D46067] font-bold opacity-85">
                      {request.title}
                    </h3>)}
                    
                    <p className="">
                      <span className="font-semibold opacity-75">Date: </span>
                      <span className="font-medium opacity-[57%]">
                        {new Date(request.created_at).toISOString().split("T")[0]}
                      </span>
                    </p>
                  </div>
                  <p className="">
                    <span className="font-semibold opacity-75">Leave Taker: </span>
                    <span className="opacity-[57%] font-medium">
                      {teacher ? `${teacher.first_name} ${teacher.middle_name ? teacher.middle_name : ""} ${teacher.last_name}` : "Unknown"}
                    </span>
                  </p>
                  <p className="">
                    <span className="font-semibold opacity-75">Leave Reason: </span>
                    <span className="opacity-[57%] font-medium">
                      {request.reason}
                    </span>
                  </p>
                  <p className="opacity-[57%] leading-[28px] text-justify font-medium tracking-wide">
                    {request.body}
                  </p>
                  <p className="">
                    <span className="font-semibold opacity-75">
                      Leave Duration:{" "}
                    </span>
                    <span className="opacity-[57%] font-medium">
                      {(new Date(request.end_date) - new Date(request.start_date)) /
                        (1000 * 3600 * 24)}{" "}
                      days
                    </span>
                  </p>
                  <div className="mt-4 flex space-x-4">
                    {request.approved ?(
                      <span className="cursor-default px-4 py-2 w-full bg-[#4D45B5]/80  text-white rounded-lg font-medium tracking-wide">
                        Accepted
                      </span>
                    ):
                    (<span className="cursor-default px-4 py-2 w-full bg-[#D46067]/80  text-white rounded-lg font-medium tracking-wide">
                      Rejected
                    </span>)}

                  </div>
                </div>
              );
            })
        ) : (
          <div className="mb-10 py-10 px-8 space-y-6 border rounded-2xl shadow-sm h-[24rem] border-gray-400 flex items-center justify-center">
            <p className="text-xl text-gray-400 font-bold opacity-85">
              No results found
            </p>
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

export default PrincipalLeaveData;

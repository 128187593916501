import React, { useState } from "react";
import BaseLayout from "../../components/BaseLayout";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import useAxios from "../../../utils/axios/useAxios";

const TeacherAddResult = () => {
  // temporary data
  // const data = [
  //   {
  //     id: 1,
  //     name: "Lorem ipsum dolor sit amet consectetur. ",
  //     grade: "2",
  //     section: "A",
  //     student_id: 22067735,
  //     roll_no: 25,
  //     subject: "English",
  //     marks: 87,
  //   },
  //   {
  //     id: 2,
  //     name: "Lorem ipsum dolor sit amet consectetur. ",
  //     grade: "3",
  //     section: "A",
  //     student_id: 22067736,
  //     roll_no: 1,
  //     subject: "Maths",
  //     marks: 23,
  //   },
  //   {
  //     id: 3,
  //     name: "Lorem ipsum dolor sit amet consectetur. ",
  //     grade: "2",
  //     section: "B",
  //     student_id: 22067739,
  //     roll_no: 4,
  //     subject: "Physics",
  //     marks: 23,
  //   },
  //   {
  //     id: 4,
  //     name: "Lorem ipsum dolor sit amet consectetur. ",
  //     grade: "5",
  //     section: "A",
  //     student_id: 22067737,
  //     roll_no: 5,
  //     subject: "Biology",
  //     marks: 23,
  //   },
  //   {
  //     id: 5,
  //     name: "Lorem ipsum dolor sit amet consectetur. ",
  //     grade: "2",
  //     section: "A",
  //     student_id: 22067734,
  //     roll_no: 7,
  //     subject: "Health",
  //     marks: 23,
  //   },
  //   {
  //     id: 6,
  //     name: "Lorem ipsum dolor sit amet consectetur. ",
  //     grade: "7",
  //     section: "C",
  //     student_id: 22067745,
  //     roll_no: 50,
  //     subject: "English",
  //     marks: 23,
  //   },
  //   {
  //     id: 7,
  //     name: "Lorem ipsum dolor sit amet consectetur. ",
  //     grade: "2",
  //     section: "A",
  //     student_id: 22067748,
  //     roll_no: 32,
  //     subject: "English",
  //     marks: 23,
  //   },
  //   {
  //     id: 8,
  //     name: "Lorem ipsum dolor sit amet consectetur. ",
  //     grade: "2",
  //     section: "A",
  //     student_id: 22067788,
  //     roll_no: 22,
  //     subject: "History",
  //     marks: 23,
  //   },
  //   {
  //     id: 9,
  //     name: "Lorem ipsum dolor sit amet consectetur. ",
  //     grade: "9",
  //     section: "B",
  //     student_id: 22067792,
  //     roll_no: 12,
  //     subject: "English",
  //     marks: 23,
  //   },
  // ];

  const { data : results, error : resultError } = useAxios(`classrooms/testpaper-results/`);
  const { data : students, error : studentError} = useAxios(`classrooms/students/`);
  const { data : testpapers, error : testpaperError} = useAxios(`classrooms/students/`);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSubject, setFilterSubject] = useState("");
  const [filterGrade, setFilterGrade] = useState("");
  const [filterSection, setFilterSection] = useState("");

  const itemsPerPage = 6;

  const getStudentName = (studentId) => {
    const studentName = students?.find((t) => t.id === studentId);
    return studentName ? `${studentName.first_name} ${studentName.last_name}` : "Unknown";
  };

  const getStudentRollNo = (studentId) => {
    const student = students?.find((s) => s.id === studentId);
    return student ? student.id : "Unknown";
  };

  const filteredData = results.filter((item) => {
    return (
      //item.testpaper.includes(searchTerm.toLowerCase()) &&
      (filterSubject ? item.subject === filterSubject : true) &&
      (filterGrade ? item.grade === filterGrade : true) &&
      (filterSection ? item.section === filterSection : true)
    );
  });

  const uniqueSubject = [...new Set(testpapers.map((item) => item.subject))];
  const uniqueGrade = [...new Set(results.map((item) => item.grade))];
  const uniqueSection = [...new Set(results.map((item) => item.section))];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleInput = (e) => {
    const value = e.target.innerText;
    if (!/^\d*$/.test(value)) {
      e.target.innerText = value.replace(/\D/g, "");
    }
  };

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  return (
    <BaseLayout title={"Add Marks"}>
      <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
        <div className="flex">
          <div className="grid grid-cols-3 md:grid-cols-2 gap-x-24 gap-y-3 items-center mx-4 w-[75%]">
            <div className="flex space-x-32 text-base">
              <label htmlFor="year" className="text-[#5E5D5D]">
                Year
              </label>
              <input
                type="date"
                id="year"
                className="text-[#6B6C6C] text-sm w-[10rem] border px-2 py-1.5 bg-[#F9FAFB] border-[#434242] rounded-md focus:ring-none"
              ></input>
            </div>
            <div className="flex space-x-32">
              <label htmlFor="subject" className="text-[#5E5D5D]">
                Subject
              </label>
              <select
                id="subject"
                className="text-[#6B6C6C] text-sm w-[10rem] border px-2 py-1.5 bg-[#F9FAFB] border-[#434242] rounded-md focus:ring-none"
                value={filterSubject}
                onChange={(e) => setFilterSubject(e.target.value)}
              >
                <option value="" selected>
                  All Subjects
                </option>
                {uniqueSubject.map((subject) => (
                  <option key={subject} value={subject}>
                    {subject}
                  </option>
                ))}
              </select>
            </div>
      
            <div className="flex space-x-28 col-start-1">
              <label htmlFor="grade" className="text-[#5E5D5D]">
                Grade
              </label>
              <select
                id="grade"
                className="text-[#6B6C6C] text-sm w-[10.2rem] border px-2 py-1.5 bg-[#F9FAFB] border-[#434242] rounded-md focus:ring-none"
                value={filterGrade}
                onChange={(e) => setFilterGrade(e.target.value)}
              >
                <option value="" selected>
                  All Grades
                </option>
                {uniqueGrade.map((grade) => (
                  <option key={grade} value={grade}>
                    {grade}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex space-x-32">
              <label htmlFor="section" className="text-[#5E5D5D]">
                Section
              </label>
              <select
                id="section"
                className="text-[#6B6C6C] text-sm w-[10rem] border px-2 py-1.5 bg-[#F9FAFB] border-[#434242] rounded-md focus:ring-none"
                value={filterSection}
                onChange={(e) => setFilterSection(e.target.value)}
              >
                <option value="" selected>
                  All Sections
                </option>
                {uniqueSection.map((section) => (
                  <option key={section} value={section}>
                    {section}
                  </option>
                ))}
              </select>
              
            </div>

            
          </div>
          <div className="text-base text-[#5E5D5D] flex justify-end ml-28">
              <p>
                <span className="font-semibold">Date: </span>
                {currentYear}-{currentMonth}-{currentDay}
              </p>
            </div>
            

            
          </div>

        <hr className="my-4" />

        <div className="container mx-auto px-4">
          <table className="min-w-full bg-white ">
            <thead className="bg-[#EAECFF] divide-x divide-gray-300 text-[16px] ">
              <tr>
                <th className="p-4 text-center rounded-tl-lg font-light">
                  S.N
                </th>
                <th className="p-4 text-left font-light">Name</th>
                <th className="p-4 text-center font-light">Roll No.</th>
                <th className="p-4 text-center font-light rounded-tr-lg">
                  Marks
                </th>
              </tr>
            </thead>

            <tbody className="text-[14px] border  border-gray-300">
              {currentData.map((item, index) => (
                <tr
                  key={item.id}
                  className="hover:bg-[#F5F7FF] divide-x divide-gray-300  rounded-b-lg"
                >
                  <td className="p-4 border-b border-gray-300 text-center">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td className="p-4 border-b border-gray-300 text-left ">
                    <div className=" flex items-center">{getStudentName(item.student)}</div>
                  </td>
                  <td className="p-4 border-b border-gray-300 text-center">
                    {getStudentRollNo(item.student)}
                  </td>
                  <td
                    className="p-4 border-b border-gray-300 justify-center flex items-center"
                    contentEditable
                    onInput={handleInput}
                  >
                    {item.marks}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="text-center col-start-3 flex justify-end space-x-5 mt-8">
            <button className="py-1 w-[7rem] text-sm bg-[#4D45B5] text-white rounded-lg font-medium hover:opacity-[88%]">
              Save
            </button>
            <button className="py-1 w-[7rem] text-sm text-[#B54545] border-2 border-[#D46067] font-medium rounded-lg hover:bg-[#D46067] hover:text-white hover:ease-in-out hover:transition duration-[500ms]">
              Cancel
            </button>
          </div>

          <div className="mt-4 flex space-x-6 justify-center">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="size-5 text-gray-400 "
            >
              <FaChevronLeft />
            </button>
            <span className="">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="size-5 text-gray-400  "
            >
              <FaChevronRight className="text-sm " />
            </button>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default TeacherAddResult;

import React from 'react'

const AddNewTransaction = () => {
  return (
    <div className="p-6 max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-[#827CCC]">Add New Transaction</h2>
      </div>

      <div className="grid grid-cols-4 gap-y-6 gap-x-1 mb-4">
        <label
          className="text-left text-base font-medium text-gray-700 mt-2"
          htmlFor="description"
        >
          Description
        </label>
        <input
          type="text"
          className="col-span-3 p-2 border border-gray-300 rounded-md"
          placeholder="Product A Sales"
          id="description"
        />

        <label
          className="text-base text-left font-medium text-gray-700 mt-2"
          htmlFor="account"
        >
          Account
        </label>
        <input
          className="p-2 border border-gray-300 rounded-md appearance-none bg-white"
          id="account"
          placeholder="101001"
        />
        <label
          className="text-base text-center font-medium text-gray-700 mt-2"
          htmlFor="taxRate"
        >
          Tax Rate
        </label>
        <input
          className="p-2 border border-gray-300 rounded-md appearance-none bg-white"
          id="taxRate"
          placeholder='10%'
          />
        <label
          className="text-left text-base font-medium text-gray-700 mt-2"
          htmlFor="debit"
        >
          Debit
        </label>
        <input
          id="debit"
          className="p-2 border border-gray-300 rounded-md appearance-none bg-white"
          placeholder='$500'
          />

        <label
          className="text-base text-center font-medium text-gray-700 mt-2"
          htmlFor="credit"
        >
          Credit
        </label>
        <input
          className="p-2 border border-gray-300 rounded-md appearance-none bg-white"
          id="credit"
          placeholder="$50"
        />

        <div className="text-base text-center col-span-4 flex justify-end">
          <button className="px-4 py-2 bg-[#4D45B5] w-[12rem] text-white rounded-lg font-medium hover:opacity-[88%] tracking-wide">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewTransaction
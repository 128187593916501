import React, { useEffect, useState } from "react";
import useAxios from "../../../utils/axios/useAxios";

const StudentDetailCard = ({ student }) => {
  const { data: classRooms, error: classRoomError } = useAxios('classrooms/classrooms');
  const [classRoom, setClassRoom] = useState(null);

  console.log(classRooms)

  useEffect(() => {
    if (classRooms) {
      const foundClassRoom = classRooms.find(classroom =>
        student.profile.classroom === classroom.id
      );
      setClassRoom(foundClassRoom);
    }
  }, [classRooms, student.profile.classroom]);



  return (
    <div className="w-full p-4 items-center">
      <div>
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold mb-4">Student Detail</h2>
          <div>
            <img
              src={student.profile.photo}
              alt="Student"
              className="w-24 h-24 rounded-full object-cover"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <p className="font-medium text-gray-600">
              Name: <span className="text-gray-800">{student.profile.first_name} {student.profile.last_name}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Address: <span className="text-gray-800">{student.profile.address}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Class: <span className="text-gray-800">{classRoom ? classRoom.name : 'Loading...'}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Section: <span className="text-gray-800">{classRoom && classRoom.curriculum_data ? classRoom.curriculum_data.name : 'N/A'}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Roll No: <span className="text-gray-800">{student.profile.id}</span>
            </p>
          </div>
          <div>
            <p className="font-medium text-gray-600">
              Attendance: <span className="text-gray-800">{student.attendance}%</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDetailCard;

import React, { useState, useEffect } from "react";
import { MdAttachFile, MdDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosAuth from "../../../utils/axios/useAxiosAuth";
import useAxios from "../../../utils/axios/useAxios";

const AddReadingMaterial = ({ readingMaterial }) => {
  const axios = useAxiosAuth();
  const { data: classrooms } = useAxios(`classrooms/classrooms`);
  const { data: curriculums } = useAxios(`classrooms/curriculums`);
  const { data: subjects } = useAxios(`classrooms/subjects`);

  const [fileAttachment, setFileAttachment] = useState(
    readingMaterial.fileAttachment
  );

  const [title, setTitle] = useState(readingMaterial.title);
  const [description, setDescription] = useState(readingMaterial.description);
  const [curriculum, setCurriculum] = useState(readingMaterial.curriculum);
  const [classroom, setClassroom] = useState(readingMaterial.classroom);
  const [subject, setSubject] = useState(readingMaterial.subject);
  const [target, setTarget] = useState(readingMaterial.target);
  const [created_at, setUploadDate] = useState(readingMaterial.created_at);
  const [attachment, setAttachment] = useState(null);

  const targets = [
    { name: "CURRICULUM_SPECIFIC" },
    { name: "CLASSROOM_SPECIFIC" },
    { name: "SUBJECT_SPECIFIC" },
  ];
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Update the local state optimistically before sending the request
    setTitle(title);
    setDescription(description);
    setCurriculum(curriculum);
    setClassroom(classroom);
    setSubject(subject);
    setTarget(target);
    setUploadDate(created_at);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("target", target);
    formData.append("classroom", classroom ? String(classroom) : "");
    formData.append("subject", subject ? String(subject) : "");
    formData.append("curriculum", curriculum ? String(curriculum) : "");
    formData.append("description", description);
    formData.append("created_at", created_at);
    formData.append("organization", readingMaterial.organization);

    if (attachment) {
      formData.append("attachment", attachment);
    }

    axios
      .patch(
        `api/classrooms/study-materials/${readingMaterial.id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        toast.success("Reading Material updated successfully.");

        if (response.data.fileAttachment) {
          setFileAttachment(response.data.fileAttachment);
        }
      })
      .catch((error) => {
        toast.error(
          "Failed to update Reading Material. Please try again later."
        );
        console.error(
          "API Error:",
          error.response ? error.response.data : error.message
        );
      });
  };

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-[#827CCC]">
          Edit Reading Material
        </h2>
      </div>

      <form
        onSubmit={handleFormSubmit}
        className="grid grid-cols-4 gap-y-6 gap-x-1 mb-4"
      >
        <label className="text-base col-span-1 font-medium text-gray-700 mt-2">
          Topic
        </label>
        <input
          type="text"
          className="col-span-3 p-2 border border-gray-300 rounded-md"
          placeholder="Topic"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <label className="text-base col-span-1 font-medium text-gray-700 mt-2">
          Description
        </label>
        <textarea
          className="col-span-3 p-2 border border-gray-300 rounded-md"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <label className="text-base col-span-1 font-medium text-gray-700 mt-2">
          Target
        </label>
        <select
          className="col-span-3 p-2 border border-gray-300 rounded-md"
          value={target}
          onChange={(e) => setTarget(e.target.value)}
        >
          {targets.map((t) => (
            <option key={t.name} value={t.name}>
              {t.name}
            </option>
          ))}
        </select>

        {target === "CURRICULUM_SPECIFIC" && (
          <>
            <label className="text-base col-span-1 font-medium text-gray-700 mt-2">
              Curriculum
            </label>
            <select
              className="col-span-3 p-2 border border-gray-300 rounded-md"
              value={curriculum}
              onChange={(e) => setCurriculum(Number(e.target.value))} // Ensure value is a number
            >
              {curriculums.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
          </>
        )}

        {target === "CLASSROOM_SPECIFIC" && (
          <>
            <label className="text-base col-span-1 font-medium text-gray-700 mt-2">
              Class
            </label>
            <select
              className="col-span-3 p-2 border border-gray-300 rounded-md"
              value={classroom}
              onChange={(e) => setClassroom(e.target.value)}
            >
              {classrooms.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
          </>
        )}

        {target === "SUBJECT_SPECIFIC" && (
          <>
            <label className="text-base col-span-1 font-medium text-gray-700 mt-2">
              Subject
            </label>
            <select
              className="col-span-3 p-2 border border-gray-300 rounded-md"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            >
              {subjects.map((s) => (
                <option key={s.id} value={s.id}>
                  {s.name}
                </option>
              ))}
            </select>
          </>
        )}

        <label className="text-base col-span-1 font-medium text-gray-700 mt-2">
          Upload Date
        </label>
        <input
          type="date"
          className="col-span-3 p-2 border border-gray-300 rounded-md"
          value={created_at}
          onChange={(e) => setUploadDate(e.target.value)}
        />

        <label className="text-left text-base font-medium text-gray-700 mt-2">
          Assignment File
        </label>
        <div className="flex items-center col-span-3 justify-between">
          <div className="flex items-center border border-gray-300 rounded-md p-2 w-4/5">
            <div className="flex text-base text-gray-600 items-center">
              <MdAttachFile />
              {fileAttachment ? (
                <span>{fileAttachment}</span>
              ) : (
                <span>Attach assignment file here</span>
              )}
              <input
                type="file"
                id="fileAttachment"
                className="hidden"
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    setFileAttachment(e.target.files[0].name);
                    setAttachment(e.target.files[0]);
                  }
                }}
              />
            </div>
            {fileAttachment && (
              <MdDeleteOutline
                className="text-base cursor-pointer"
                onClick={() => {
                  setFileAttachment("");
                  setAttachment(null);
                  document.getElementById("fileAttachment").value = null;
                }}
              />
            )}
          </div>
          <button
            type="button"
            className="ml-5 px-4 py-2 border border-[#5964DB] text-[#4D45B5] text-base rounded-md"
            onClick={() => document.getElementById("fileAttachment").click()}
          >
            Add File
          </button>
        </div>

        <div className="col-span-4 flex justify-end mt-4">
          <button
            type="submit"
            className="px-6 py-2 bg-[#5964DB] text-white rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddReadingMaterial;
